import React from "react";
import BusinessHero from "./BusinessHero";
import Pricing from "./Pricing";

const IndexBuisness = () => {
  return (
    <>
      <BusinessHero />
      {/* <section className="section"><Pricing /></section> */}
    </>
  );
};

export default IndexBuisness;
