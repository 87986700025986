import React, { useState, useEffect } from "react";
//import { Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";

import * as Yup from "yup";
import { Helmet } from "react-helmet";

import {
  Container,
  Row,
  Col,
  Alert,
  //Input,
  Label,
  Card,
  CardBody,
} from "reactstrap";

// Captcha library
import ReCAPTCHA from "react-google-recaptcha";

//Import Icons
//import FeatherIcon from "feather-icons-react";

// import images
import contactImg from "../../assets/images/contact-detail.jpg";

// import Axios
import axios from "axios";

// import react toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/css/Style.css";
const IndexContact = () => {
  const [contactVisible, setContactVisible] = useState(false);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  // Event Handler for Formik Submission
  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    if (isCaptchaVerified) {
      setSubmitting(false);
    }
    const leadRequestData = {
      lead: {
        first_name: values.name,
        last_name: values.last_name,
        email_id: values.email,
        contact_no: values.phone,
        company_name: values.company,
        description: values.description,
        source: "TalentIQ website",
      },
    };

    // Post request to the Api using Axios
    let response;
    try {
      response = await axios.post(
        "https://i8e43d5anj.execute-api.ap-south-1.amazonaws.com/latest/v1/lead",
        leadRequestData,
        {
          headers: {
            service_ref: "02d45aaa",
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      // Handle Error

      toast.error(" Error submitting form. Please try again.", {
        theme: "colored",
        autoClose: 3000,
        style: { background: "#ff4d4d" },
      });
    } finally {
      // If success -> true response
      if (response.data.success) {
        toast.success(" Form submitted successfully! ", {
          theme: "colored",
          autoClose: 3000,
          style: { background: "#79C079" },
        });

        //Reset the Fields after submission
        resetForm();
      }
      // If success -> false response
      else {
        toast.error(
          " Error submitting form. Company or Description are required. Please try again.",
          {
            theme: "colored",
            autoClose: 3000,
            style: { background: "#ff4d4d" },
          }
        );
      }
      // Set submitting to false after completion
      setSubmitting(false);
    }
  };

  const onChange = (value) => {
    setIsCaptchaVerified(!!value);
  };
  const scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add("nav-sticky");
      } else {
        navBar.classList.remove("nav-sticky");
      }
    }
  };

  useEffect(() => {
    document.body.classList = "";

    window.addEventListener("scroll", scrollNavigation, true);

    return () => {
      window.removeEventListener("scroll", scrollNavigation, true);
    };
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Invalid characters")
      .required("Required"),
    last_name: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Invalid characters")
      .required("Required"),
    email: Yup.string().email("Invalid email address").required("Required"),
    phone: Yup.string()
      .matches(/^[0-9]+$/, "Invalid characters")
      .required("Required"),
    company: Yup.string(),
    description: Yup.string(),
  });
  return (
    <>
      <Helmet>
        <title>Contact Us - Talentiq.app</title>
        <meta
          name="description"
          content="Connect with Talentiq.app for expert online assessments. Streamline your hiring process with our advanced tools. Contact us for tailored solutions "
        />
      </Helmet>

      <section
        className="bg-half-100 comanbackground d-table w-100"
        style={{ background: `url(${contactImg}) center center` }}
      >
        <div className="bg-overlay bg-overlay-white-color-contact"></div>
        <Container>
          <ToastContainer position="top-center" />
          <Row className="mt-5 justify-content-center">
            <Col lg={12} className="text-center">
              <div className="pages-heading">
                <h1 className="title mb-0">Contact Us</h1>
              </div>
            </Col>
          </Row>

          {/* <div className="position-breadcrumb">
            <nav aria-label="breadcrumb" className="d-inline-block">
              <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>{" "}
                <li className="breadcrumb-item active" aria-current="page">
                  Contact
                </li>
              </ul>
            </nav>
          </div> */}
        </Container>
      </section>

      <div className="form-icon position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <Container
        className="mt-5 "
        style={{ paddingLeft: "20px", paddingRight: "20px" }}
      >
        <Row>
          <Col md={4}>
            <Card className="border-0 text-center features feature-primary feature-clean">
              <div className="icons text-center mx-auto">
                <i className="uil uil-phone d-block rounded h3 mb-0"></i>
              </div>
              <div className="content mt-4">
                <h5 className="fw-bold">Phone</h5>
                <p className="text-muted">
                  Connect with TalentIQ for next-level hiring and assessments.
                </p>
                {/* <Link to="#" onClick={callNumber} className="read-more">
                  +91-8371010101
                </Link> */}
                <a href="tel:+918371010101">+91-8371010101</a>
              </div>
            </Card>
          </Col>

          <Col md={4} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
            <Card className="border-0 text-center features feature-primary feature-clean">
              <div className="icons text-center mx-auto">
                <i className="uil uil-envelope d-block rounded h3 mb-0"></i>
              </div>
              <div className="content mt-4">
                <h5 className="fw-bold">Email</h5>
                <p className="text-muted">
                  Drop us an email to explore innovative recruitment solutions
                  with TalentIQ.
                </p>
                {/* <Link to="#" onClick={sendMail} className="read-more">
                  hello@companybench.com
                </Link> */}
                <a href="mailto:hello@companybench.com">
                  hello@companybench.com
                </a>
              </div>
            </Card>
          </Col>

          <Col md={4} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
            <Card className="border-0 text-center features feature-primary feature-clean">
              <div className="icons text-center mx-auto">
                <i className="uil uil-map-marker d-block rounded h3 mb-0"></i>
              </div>
              <div className="content mt-4">
                <h5 className="fw-bold">Location</h5>
                <p className="text-muted">
                  402, Summer Court, Magarpatta City, Pune, Maharashtra 411013.
                </p>
                {/* <Link
                  to={googleMapsUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="video-play-icon read-more lightbox"
                >
                  View on Google map
                </Link> */}
                <a
                  href="https://maps.app.goo.gl/krihNDVYRebeC8F88"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="video-play-icon read-more lightbox"
                >
                  View on Google Maps
                </a>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
      <section className="section pt-3 pb-5">
        <Container>
          <Row style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            <Col lg={6} md={6} className="mt-4 pt-2">
              <Card className="shadow rounded border-0">
                <CardBody className="py-5">
                  {/* <h4 className="card-title">Get In Touch !</h4> */}
                  <h4 className="card-title">
                    Sign up for a free 30-minute live demo
                  </h4>
                  <p className="text-small">
                    Intended for companies only. Candidate{" "}
                    <a
                      href="https://talentiq.app"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Click Here
                    </a>
                  </p>
                  <div className="custom-form mt-3">
                    <div id="message"></div>
                    <Alert
                      color="info"
                      isOpen={contactVisible}
                      toggle={() => {
                        setContactVisible(!contactVisible);
                      }}
                    >
                      Contact details send successfully.
                    </Alert>
                    <Formik
                      initialValues={{
                        name: "",
                        last_name: "",
                        email: "",
                        phone: "",
                        company: "",
                        description: "",
                      }}
                      validationSchema={validationSchema}
                      //onSubmit={handleSubmit}
                      onSubmit={(values, { resetForm, setSubmitting }) => {
                        handleSubmit(values, { resetForm, setSubmitting });
                      }}
                      //disabled={!formik || !isCaptchaVerified}
                    >
                      {/* <Form
                      method="post"
                      onSubmit={handleSubmit}
                      name="contact-form"
                      id="contact-form"
                    > */}
                      <Form>
                        <Row>
                          <Col md={6}>
                            <div className="mb-3 form-group">
                              <Label className="form-label">
                                First Name{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <div className="form-icon position-relative">
                                {/* <i>
                                <FeatherIcon
                                  icon="user"
                                  className="fea icon-sm icons"
                                />
                              </i> */}
                              </div>
                              {/* <Input
                                name="name"
                                id="name"
                                type="text"
                                className="form-control"
                                placeholder="First Name :"
                                required
                              /> */}
                              <Field
                                name="name"
                                id="name"
                                type="text"
                                className="form-control"
                                placeholder="First Name :"
                                required
                              />
                              <ErrorMessage
                                name="name"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Last Name <span className="text-danger">*</span>
                              </Label>
                              <div className="form-icon position-relative">
                                {/* <i>
                                <FeatherIcon
                                  icon="mail"
                                  className="fea icon-sm icons"
                                />
                              </i> */}
                              </div>
                              {/* <Input
                                name="last_name"
                                id="last_name"
                                type="text"
                                className="form-control"
                                placeholder="Last Name :"
                                required
                              /> */}
                              <Field
                                name="last_name"
                                id="last_name"
                                type="text"
                                className="form-control"
                                placeholder="Last Name :"
                                required
                              />
                              <ErrorMessage
                                name="last_name"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </Col>

                          {/* Email Field */}
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Email <span className="text-danger">*</span>
                              </Label>
                              <div className="form-icon position-relative">
                                {/* <i>
                                <FeatherIcon
                                  icon="mail"
                                  className="fea icon-sm icons"
                                />
                              </i> */}
                              </div>
                              {/* <Input
                                name="email"
                                id="email"
                                type="email"
                                className="form-control"
                                placeholder="Email :"
                                required
                              /> */}
                              <Field
                                name="email"
                                id="email"
                                type="email"
                                className="form-control"
                                placeholder="Email :"
                                required
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Mobile <span className="text-danger">*</span>
                              </Label>

                              {/* <Input
                                name="phone"
                                id="phone"
                                type="number"
                                className="form-control"
                                placeholder="Mobile :"
                                required
                              /> */}
                              <Field
                                name="phone"
                                id="phone"
                                type="tel"
                                className="form-control"
                                placeholder="Mobile :"
                                required
                              />
                              <ErrorMessage
                                name="phone"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </Col>
                          {/* </Row> */}
                          <Col md={12}>
                            <div className="mb-3">
                              <Label className="form-label">Company </Label>
                              <div className="form-icon position-relative">
                                {/* <i>
                                <FeatherIcon
                                  icon="book"
                                  className="fea icon-sm icons"
                                />
                              </i> */}
                              </div>
                              {/* <Input
                                name="company"
                                id="company"
                                type="text"
                                className="form-control"
                                placeholder="Company :"
                                required
                              /> */}
                              <Field
                                name="company"
                                id="company"
                                type="text"
                                className="form-control"
                                placeholder="Company :"
                                //required
                              />
                              <ErrorMessage
                                name="company"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label className="form-label">Description</Label>
                              <div className="form-icon position-relative">
                                {/* <i>
                                <FeatherIcon
                                  icon="message-circle"
                                  className="fea icon-sm icons"
                                />
                              </i> */}
                              </div>
                              {/* <textarea
                                name="description"
                                id="desc"
                                rows="4"
                                className="form-control"
                                placeholder="Description :"
                              ></textarea> */}
                              <Field
                                as="textarea"
                                name="description"
                                id="desc"
                                rows="4"
                                className="form-control"
                                placeholder="Description :"
                              ></Field>
                              <ErrorMessage
                                name="description"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="mb-3">
                              <ReCAPTCHA
                                sitekey={process.env.REACT_APP_SITE_KEY}
                                onChange={onChange}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={12} className="text-center">
                            <div className="d-grid">
                              <input
                                type="submit"
                                id="submit"
                                name="send"
                                className="submitBnt btn btn-primary btn-block"
                                value="Submit"
                                disabled={!isCaptchaVerified}
                              />
                            </div>
                            <div id="simple-msg"></div>
                          </Col>
                        </Row>
                      </Form>
                    </Formik>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={6} md={6} className="ps-md-3 pe-md-3 mt-4 pt-2">
              <Card className="map map-height-two rounded map-gray border-0">
                <CardBody className="p-0">
                  {/* <iframe
                        title="test"
                        src="//www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                        style={{ border: 0 }}
                        className="rounded"
                        allowFullScreen=""
                    ></iframe> */}
                  <iframe
                    title="test"
                    // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.2243344682893!2d73.92653517494271!3d18.518761869230026!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2a3a549e6ae9f%3A0xed364b14a52b50eb!2sTechila%20Global%20Services%20(TGS)!5e0!3m2!1sen!2sin!4v1700401851076!5m2!1sen!2sin"
                    src="https://maps.google.com/maps?q=Talentiq%20Recruittech%20Private%20Limited%20(TRPL)&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
                    style={{ border: 0 }}
                    className="rounded"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default IndexContact;
