import React, { Component } from "react";
import { Link } from "react-router-dom";
// import classnames from "classnames";
import { Squash as Hamburger } from "hamburger-react";
import { withRouter } from "react-router";

import {
  Container,
  // Form,
  Modal,
  ModalBody,
  // Dropdown,
  // DropdownMenu,
  // DropdownToggle,
  // OffcanvasHeader,
  Offcanvas,
  OffcanvasBody,
} from "reactstrap";

//Import Icons
// import FeatherIcon from "feather-icons-react";
import { FaBars, FaTimes } from "react-icons/fa"; // Import icons from a suitable library
//Import images
import logodark from "../../assets/images/talentiq-logo.png";
import logolight from "../../assets/images/talentiq-logo.png";
// import shop1 from "../../assets/images/shop/product/s-1.jpg";
// import shop2 from "../../assets/images/shop/product/s-2.jpg";
// import shop3 from "../../assets/images/shop/product/s-3.jpg";

//import NavbarButtons from "../Shared/NavbarButtons";
// import appStore from "../../assets/images/app/app-store.png";
// import playStore from "../../assets/images/app/play-store.png";

import SignupSidebar from "../Shared/SignupSidebar";
// import RightSidebar from "../Shared/RightSidebar";

import {
  Navbar,
  //NavbarBrand,
  NavbarToggler,
  Collapse,
  //Nav,
  //NavItem,
  //NavLink,
} from "reactstrap";

// menu
// import { navLinks } from './menu';
// import { MenuItem } from './NavBarComponents';
import { useContext } from "react";
import AppContext from "../../contexts/AppContext";

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.navBarRef = React.createRef();

    this.state = {
      isOpen: false,
      dropdownOpenShop: false,
      wishlistModal: false,
      dropdownIsOpen: false,
      open: false,
      position: "end",
      dropdownOpen: false,
      landing: false,
      about: false,
      job: false,
      business: false,
      contact: false,
      components: false,
      demo: false,
      doc: false,
      pages: false,
      company: false,
      account: false,
      email: false,
      blog: false,
      case: false,
      auth: false,
      login: false,
      signup: false,
      reset: false,
      utility: false,
      special: false,
      multi: false,
      level2: false,
      isOffcanvasOpen: false,
    };
    this.toggleLine = this.toggleLine.bind(this);
    this.toggleDropdownShop.bind(this);
    this.toggleWishlistModal.bind(this);
    this.toggleDropdownIsOpen.bind(this);
    this.toggleRightDrawer = this.toggleRightDrawer.bind(this);
    this.onDrawerClose = this.onDrawerClose.bind(this);
    this.toggleDropdown.bind(this);
    this.togglemodal.bind(this);
  }

  /**
   * Right sidebar drawer
   **/

  toggleResumeCanvasOpen = () => {
    this.setState({ isOffcanvasOpen: true });
  };

  toggleOffcanvas = () => {
    this.setState((prevState) => ({
      isOffcanvasOpen: !prevState.isOffcanvasOpen,
    }));
  };

  closeOffcanvas = () => {
    this.setState({ isOffcanvasOpen: false });
  };

  toggleRightDrawer = () => {
    this.setState({ position: "right" });
    this.setState({ open: !this.state.open });
  };
  onDrawerClose = () => {
    this.setState({ open: false });
  };

  toggleWishlistModal = () => {
    this.setState((prevState) => ({
      wishlistModal: !prevState.wishlistModal,
    }));
  };

  toggleDropdownShop = () => {
    this.setState({
      dropdownOpenShop: !this.state.dropdownOpenShop,
    });
  };
  toggleDropdownIsOpen = () => {
    this.setState({
      dropdownIsOpen: !this.state.dropdownIsOpen,
    });
  };

  toggleDropdown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };
  togglemodal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  toggleLine() {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }
  initMenu() {
    this.activateParentDropdown();
  }

  handleMenuItemClick = () => {
    this.setState({ isOpen: false });
  };

  isLinkActive = (path) => {
    return this.props.location.pathname === path;
  };

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  activateParentDropdown() {
    var menuItems = document.getElementsByClassName("sub-menu-item");

    if (menuItems) {
      var matchingMenuItem = null;
      for (var idx = 0; idx < menuItems.length; idx++) {
        if (menuItems[idx].href === window.location.href) {
          matchingMenuItem = menuItems[idx];
        }
      }

      if (matchingMenuItem) {
        matchingMenuItem.classList.add("active");

        const immediateParent = matchingMenuItem.closest("li");
        if (immediateParent) {
          immediateParent.classList.add("active");
        }
        const parent = immediateParent.closest(".parent-menu-item");

        if (parent) {
          parent.classList.add("active");

          var parentMenuitem = parent.querySelector("a");
          if (parentMenuitem) {
            parentMenuitem.classList.add("active");
          }
          var parentOfParent = parent.closest(".parent-parent-menu-item");
          if (parentOfParent) {
            parentOfParent.classList.add("active");
          }
        } else {
          parentOfParent = matchingMenuItem.closest(".parent-parent-menu-item");
          if (parentOfParent) {
            parentOfParent.classList.add("active");
          }
        }
      }
      return false;
    }
    return false;
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.initMenu();
  }
  // const {resumeResponse, setResumeResponse} = useContext()
  // updateIsOpen = (value) => {
  //   this.setState({ isOpen: value });
  // };
  // static contextType = AppContext;
  static contextType = AppContext;
  render() {
    const {
      isOpen,
      // isOffcanvasOpen,
      // demo,
      // job,
      // business,
      // contact,
      // pages,
    } = this.state;
    const { isOffcanvasOpen } = this.state;
    const { resumeResponse, setResumeResponse } = this.context;
    // const { isOffcanvasOpen, toggleOffcanvas } = this.props;

    // console.log(resumeResponse);

    // if (resumeResponse === false) {

    // }

    // console.log(isOffcanvasOpen);
    return (
      <React.Fragment>
        <header id="topnav" className="defaultscroll sticky">
          <Container>
            <div>
              {/* {this.props.hasDarkTopBar ? ( */}
              <Link className="logo" to="/">
                <img
                  src={logodark}
                  height="44"
                  className="logo-light-mode"
                  alt=""
                />
                <img
                  src={logolight}
                  height="44"
                  className="logo-dark-mode"
                  alt=""
                />
              </Link>
            </div>
            <div>
              {(() => {
                return (
                  <ul className="buy-button list-inline mb-0">
                    <li className="list-inline-item mb-0">
                      <Link
                        to="#"
                        onClick={this.toggleOffcanvas}
                        disabled={this.state.open}
                      >
                        <div id="buyButton" className="btn btn-primary">
                          Signup
                        </div>
                      </Link>
                    </li>
                  </ul>
                );
              })()}
              <div
                id="navigation"
                style={{ display: this.state.isOpen ? "block" : "none" }}
              >
                <ul className="navigation-menu nav-dark" id="top-menu">
                  <li
                    className={`sub-menu-item ${
                      this.isLinkActive("/") ? "active" : ""
                    }`}
                  >
                    <Link
                      to="/"
                      // className="sub-menu-item"
                      onClick={() => {
                        this.handleMenuItemClick();
                      }}
                    >
                      Home
                    </Link>
                  </li>
                  {/* <li className="has-submenu parent-parent-menu-item"> */}
                  <li
                    className={`has-submenu parent-parent-menu-item ${
                      this.isLinkActive("/about") ? "active" : ""
                    }`}
                  >
                    <Link
                      to="/about"
                      // onClick={() => this.setState({ demo: !this.state.demo })}
                      onClick={() => {
                        this.handleMenuItemClick();
                      }}
                    >
                      About
                    </Link>
                  </li>
                  {/* <li className="has-submenu parent-parent-menu-item"> */}
                  <li
                    className={`has-submenu parent-parent-menu-item ${
                      this.isLinkActive("/jobs") ? "active" : ""
                    }`}
                  >
                    <Link
                      to="/jobs"
                      // onClick={() => this.setState({ job: !this.state.job })}
                      onClick={() => {
                        this.handleMenuItemClick();
                      }}
                    >
                      Jobs
                    </Link>
                  </li>

                  {/* <li className="has-submenu parent-parent-menu-item"> */}
                  <li
                    className={`has-submenu parent-parent-menu-item ${
                      this.isLinkActive("/business") ? "active" : ""
                    }`}
                  >
                    <Link
                      to="/business"
                      // onClick={() =>
                      //   this.setState({ pages: !this.state.business })
                      // }
                      onClick={() => {
                        this.handleMenuItemClick();
                      }}
                    >
                      For Business
                    </Link>
                  </li>

                  {/* <li className="has-submenu parent-parent-menu-item"> */}
                  <li
                    className={`has-submenu parent-parent-menu-item ${
                      this.isLinkActive("/contact") ? "active" : ""
                    }`}
                  >
                    <Link
                      to="/contact"
                      // onClick={() =>
                      //   this.setState({ pages: !this.state.contact })
                      // }
                      onClick={() => {
                        this.handleMenuItemClick();
                      }}
                    >
                      Contact
                    </Link>
                  </li>
                </ul>

                {/* <ul className="navigation-menu" id="top-menu">
                {(navLinks || []).map((item, key) => {
                  const hasChildren = item.child && item.child.length;
                  return (
                    <MenuItem
                      item={item}
                      key={key}
                      itemClassName={hasChildren ? 'parent-parent-menu-item' : ''}
                      arrowClassName="menu-arrow"
                    />
                  );
                })}
              </ul> */}
                {/* menu end */}
                {/* <div className="buy-menu-btn d-none">
                <Link
                  to="//1.envato.market/landrickreactjs"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-primary"
                >
                  Buy Now
                </Link>
              </div> */}
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "inherit",
                  alignItems: "end",
                  justifyContent: "end",
                }}
              >
                {/* <Hamburger onClick={this.toggleLine} >
                    
                 </Hamburger> */}
                <Navbar
                  light
                  expand="md"
                  className=" d-lg-none"
                  style={{
                    marginTop: "5px",
                  }}
                >
                  <Hamburger
                    toggled={isOpen}
                    toggle={this.toggleLine}
                  ></Hamburger>
                  {/* <NavbarToggler
                    style={{
                      marginTop: "12px",
                      outline: "none !important",
                    }}
                    onClick={this.toggleLine}
                  >
                    {isOpen ? (
                      <FaTimes style={{ fontSize: "1.5rem" }} />
                    ) : (
                      <FaBars style={{ fontSize: "1.5rem" }} />
                    )}
                  </NavbarToggler>
                  <Collapse isOpen={isOpen} navbar></Collapse> */}
                </Navbar>
              </div>
              {/* <div
                id="navigation"
                style={{ display: this.state.isOpen ? "block" : "none" }}
              >
                <ul className="navigation-menu nav-dark" id="top-menu">
                  <li>
                    <Link to="/" className="sub-menu-item">
                      Home
                    </Link>
                  </li>
                  <li className="has-submenu parent-parent-menu-item">
                    <Link
                      to="/about"
                      onClick={() => this.setState({ demo: !this.state.demo })}
                    >
                      About
                    </Link>
                  </li>
                  <li className="has-submenu parent-parent-menu-item">
                    <Link
                      to="/jobs"
                      onClick={() => this.setState({ job: !this.state.job })}
                    >
                      Jobs
                    </Link>
                  </li>

                  <li className="has-submenu parent-parent-menu-item">
                    <Link
                      to="/business"
                      onClick={() =>
                        this.setState({ pages: !this.state.business })
                      }
                    >
                      For Business
                    </Link>
                  </li>

                  <li className="has-submenu parent-parent-menu-item">
                    <Link
                      to="/contact"
                      onClick={() =>
                        this.setState({ pages: !this.state.contact })
                      }
                    >
                      Contact
                    </Link>
                  </li>
                </ul>
              </div> */}
              {/* </div> */}
            </div>
          </Container>
        </header>

        <Modal
          isOpen={this.state.wishlistModal}
          tabIndex="-1"
          centered
          contentClassName="rounded shadow-lg border-0 overflow-hidden"
          toggle={this.toggleWishlistModal}
        >
          <ModalBody className="py-5">
            <div className="text-center">
              <div
                className="icon d-flex align-items-center justify-content-center bg-soft-danger rounded-circle mx-auto"
                style={{ height: "95px", width: "95px" }}
              >
                <h1 className="mb-0">
                  <i className="uil uil-heart-break align-middle"></i>
                </h1>
              </div>
              <div className="mt-4">
                <h4>Your wishlist is empty.</h4>
                <p className="text-muted">
                  Create your first wishlist request...
                </p>
                <div className="mt-4">
                  <Link to="#" className="btn btn-outline-primary">
                    + Create new wishlist
                  </Link>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {resumeResponse === false ? (
          <Offcanvas
            fade
            isOpen={!isOffcanvasOpen}
            direction={this.state.position}
            toggle={this.toggleResumeResponse}
            style={{ width: 500 }}
          >
            <OffcanvasBody>
              <SignupSidebar onClose={this.toggleResumeCanvasOpen} />
            </OffcanvasBody>
          </Offcanvas>
        ) : (
          <Offcanvas
            fade
            isOpen={isOffcanvasOpen}
            direction={this.state.position}
            toggle={this.toggleOffcanvas}
            style={{ width: 500 }}
          >
            <OffcanvasBody>
              <SignupSidebar onClose={this.closeOffcanvas} />
            </OffcanvasBody>
          </Offcanvas>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(Topbar);
// export default withAppContext(withRouter(Topbar));
