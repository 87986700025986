import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
// import ThemeSwitcher from '../../../components/Layout/ThemeSwitcher';
//Import Icons
import FeatherIcon from "feather-icons-react";
import ApartmentIcon from "@mui/icons-material/Apartment";
//Import Images
import jobBg from "../../assets/images/job/job_detail.png";
// import bg1 from '../../assets/images/hosting/cloud.png';
// import bg1 from '../../assets/images/hosting/pages.png';

// import NavBar from './NavBar';
// import BackToTop from '../../../components/Layout/backToTop';
import { ep } from "../../config/dev";
import { api_get } from "../../services/service";
import { time_since } from "../../helpers/date";
import { Helmet } from "react-helmet";
import indimg from "../../assets/icons/img/it.png";

const JobDetails = () => {
  window.scrollTo(0, 0);
  const [job, setJob] = useState({});
  const { jobId } = useParams();
  const { state } = useLocation();

  // console.log(typeof indimg);
  const scrollNavigation = useCallback(() => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add("nav-sticky");
      } else {
        navBar.classList.remove("nav-sticky");
      }
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.classList = "";
    window.addEventListener("scroll", scrollNavigation, true);

    return () => {
      window.removeEventListener("scroll", scrollNavigation, true);
    };
  }, [scrollNavigation]);

  console.log('jobbb  : ',job);
  console.log('statejobbb  : ',state);
  useEffect(() => {
    setJob(state?.job);
    if (!state?.job) {
      api_get(
        null,
        ep.crawler + "/v1/joblisting/" + jobId,
        (_assessmentsesserror, _assessmentsessdata) => {
          if (!_assessmentsesserror) {
            setJob(_assessmentsessdata.joblisting);
          }
        }
      );
    }
    // componentDidMount logic
    document.body.classList = "";
    document.querySelectorAll("#buyButton").forEach((navLink) => {
      navLink.classList.add("btn-soft-primary");
      // navLink.classList.remove("btn-soft-primary");
    });
    window.addEventListener("scroll", scrollNavigation, true);

    // componentWillUnmount logic
    return () => {
      window.removeEventListener("scroll", scrollNavigation, true);
    };
  }, []); // Empty dependency array means this effect runs once after the initial render

  // const scrollNavigation = () => {
  //   var doc = document.documentElement;
  //   var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
  //   if (top > 80) {
  //     // document.querySelector(".shoppingbtn").classList.remove("btn-light");
  //     // document.querySelector(".settingbtn").classList.remove("btn-light");
  //     // document.querySelector(".shoppingbtn").classList.add("btn-primary");
  //     // document.querySelector(".settingbtn").classList.add("btn-soft-primary");
  //     document.getElementById("topnav").classList.add("nav-sticky");
  //   } else {
  //     // document.querySelector(".shoppingbtn").classList.remove("btn-primary");
  //     // document.querySelector(".settingbtn").classList.remove("btn-soft-primary");
  //     // document.querySelector(".shoppingbtn").classList.add("btn-light");
  //     // document.querySelector(".settingbtn").classList.add("btn-light");
  //     document.getElementById("topnav").classList.remove("nav-sticky");
  //   }
  // };

  const jobInfo = [
    {
      title: "Company",
      caption: job?.company || " -",
      // icon: "users",
      icon: "c",
    },
    {
      title: "Industry",
      caption: job?.industry || " -",
      icon: indimg,
    },

    {
      title: "Employee Type",
      caption: job?.employment_type || " -",
      icon: "user-check",
    },
    {
      title: "Location",
      caption: job?.location || " -",
      icon: "map-pin",
    },
    // {
    //   title: "Job Type",
    //   caption: job?.title || " -",
    //   icon: "monitor",
    // },
    {
      title: "Experience",
      caption: job?.overall_experience ? `${job?.overall_experience} Year` : job?.overall_experience === 0 ? 'Fresher' : '-',
      icon: "briefcase",
    },
    {
      title: "Qualifications",
      caption: " -",
      icon: "book",
    },
    {
      title: "Salary",
      caption: job?.salary_range || " -",
      icon: "dollar-sign",
    },
    {
      title: "Date posted",
      caption: `${time_since(job?.list_date?.[0])} Ago` || " -",
      icon: "clock",
    },
  ];

  const dutyList = [
    { id: 1, line: "Participate in requirements analysis" },
    {
      id: 2,
      line: "Write clean, scalable code using C# and .NET frameworks",
    },
    { id: 3, line: "Test and deploy applications and systems" },
    { id: 4, line: "Revise, update, refactor and debug code" },
    { id: 5, line: "Improve existing software" },
    {
      id: 6,
      line: "Develop documentation throughout the software development life cycle (SDLC)",
    },
    {
      id: 7,
      line: "Serve as an expert on applications and provide technical support",
    },
  ];

  const skillsList = [
    {
      id: 1,
      line: "Proven experience as a .NET Developer or Application Developer",
    },
    {
      id: 2,
      line: "Good understanding of SQL and Relational Databases, specifically Microsoft SQL Server.",
    },
    {
      id: 3,
      line: "Experience designing, developing and creating RESTful web services and APIs",
    },
    { id: 4, line: "Basic know how of Agile process and practices" },
    { id: 5, line: "Good understanding of object-oriented programming." },
    { id: 6, line: "Good understanding of concurrent programming." },
    {
      id: 7,
      line: "Sound knowledge of application architecture and design",
    },
    { id: 8, line: "Excellent problem solving and analytical skills" },
  ];

  if (indimg) {
  }
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      {/* <NavBar /> */}
      {/* breadcrumb */}
      <section
        className="bg-half-100 d-table w-100"
        style={{ backgroundImage: `url(${jobBg})` }}
      >
        <div className="bg-overlay"></div>
        <Container>
          <Row className="justify-content-center">
            <Col lg={12} className="text-center">
              <div className="pages-heading">
                {job?.source_thumbnail_url ? (
                  <img
                    // src={gradle}
                    src={
                      // process.env.PUBLIC_URL + "/assets/icons/jobs/servicenow.png"
                      job?.source_thumbnail_url
                    }
                    className="avatar avatar-md-md rounded-pill bg-white p-3"
                    alt=""
                  />
                ) : (
                  <img
                    // src={gradle}
                    src="https://tiq-public-347545.s3.ap-south-1.amazonaws.com/assets/img/tiq-logo-square.png"
                    className="avatar avatar-md-md rounded-pill bg-white p-3"
                    alt=""
                  />
                )}
                <h4 className="title title-dark mt-4 mb-3"> {job?.title} </h4>
                <p className="para-desc mx-auto text-black-50 ">
                  {job?.summary ? job.summary.split(".")[0] : ""}
                </p>
                <ul className="list-unstyled mb-0">
                  <li className="list-inline-item text-black-50 me-3">
                    <i className="mdi mdi-map-marker text-warning me-2"></i>
                    {job?.location}
                  </li>{" "}
                  <li className="list-inline-item text-black-50">
                    <i className="mdi mdi-office-building text-warning me-2"></i>
                    {job?.skills &&
                    job.skills?.length > 0
                      ? job.skills
                          .slice(0, 5)
                          .join(', ')
                      : " -"}
                    {/* {job?.skill_requirements && job.skill_requirements[0] ? job.skill_requirements[0] : ' -'} */}
                    {/* {job?.skill_requirements[0]} */}
                  </li>
                </ul>
              </div>
            </Col>
          </Row>

          {/* <div className="position-breadcrumb">
              <nav aria-label="breadcrumb" className="d-inline-block">
                <ul className="breadcrumb bg-white rounded shadow mb-0 px-4 py-2">
                  <li className="breadcrumb-item">
                    <Link to="/">Landrick</Link>
                  </li>{" "}
                  <li className="breadcrumb-item">
                    <Link to="/index-job">Careers</Link>
                  </li>{" "}
                  <li className="breadcrumb-item active" aria-current="page">
                    Job Details
                  </li>
                </ul>
              </nav>
            </div> */}
        </Container>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="section">
        <Container>
          <Row>
            <Col lg="4" md="5" xs="12">
              <Card className="sidebar bg-overlay-white-color  sticky-bar rounded shadow-md border-0">
                <CardBody className="widget border-bottom">
                  <h5 className="mb-0">Job Information</h5>
                </CardBody>

                <CardBody>
                  {jobInfo.map((item, key) => (
                    <div
                      key={key}
                      className="d-flex widget align-items-center mb-3"
                    >
                      {" "}
                      {key === 1 ? (
                        <img
                          src={indimg}
                          alt=""
                          className=" fea icon-ex-md me-3"
                        />
                      ) : item.icon === "c" ? (
                        <ApartmentIcon className="icon-ex-md me-3" />
                      ) : (
                        <FeatherIcon
                          icon={item.icon}
                          className="fea icon-ex-md me-3"
                        />
                      )}
                      <div className="flex-1">
                        <h6 className="widget-title mb-0">{item.title}:</h6>
                        <small className="text-primary mb-0">
                          {item.caption}
                        </small>
                      </div>
                    </div>
                  ))}
                </CardBody>
              </Card>
            </Col>

            <Col lg="8" md="7" xs="12" className="mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="ms-lg-4">
                {/* <h5>Job Description: </h5>
                <p className="text-muted">{job?.raw_text}</p> */}
                {/* <p className="text-muted">
                    This means that Lorem Ipsum cannot accurately represent, for
                    example, German, in which all nouns are capitalized. Thus,
                    Lorem Ipsum has only limited suitability as a visual filler
                    for German texts. If the fill text is intended to illustrate
                    the characteristics of different typefaces.
                  </p>
                  <p className="text-muted">
                    It sometimes makes sense to select texts containing the
                    various letters and symbols specific to the output language.
                  </p> */}
                  {job?.skills &&
                  job?.skills.length !== 0 && (
                    <h5 className="mt-4">
                      Skills:{" "}
                    </h5>
                  )}
                {/* <p className="text-muted">
                    It sometimes makes sense to select texts containing the
                    various letters and symbols specific to the output language.
                  </p> */}
                <ul className="list-unstyled">
                  {job?.skills &&
                    job.skills.map((item, key) => (
                      <li key={key} className="text-muted">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm text-primary me-2"
                          />
                        </i>
                        {item}
                      </li>
                    ))}
                </ul>
                <h5 className="mt-4">Job Description: </h5>
                {/* <p className="text-muted">
                    It sometimes makes sense to select texts containing the
                    various letters and symbols specific to the output language.
                  </p> */}
                <ul className="list-unstyled">
                  {job?.raw_text &&
                    job.raw_text.map((item, key) => (
                      <li key={key} className="text-muted">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm text-primary me-2"
                          />
                        </i>
                        {item}
                      </li>
                    ))}
                </ul>

                {/* {job?.skill_requirements &&
                  job?.skill_requirements.length !== 0 && (
                    <h5 className="mt-4">
                      Required Experience, Skills and Qualifications:{" "}
                    </h5>
                  )}
                <p className="text-muted">
                    It sometimes makes sense to select texts containing the
                    various letters and symbols specific to the output language.
                  </p>
                <ul className="list-unstyled">
                  {job?.skill_requirements &&
                    job.skill_requirements.map((item, key) => (
                      <li key={key} className="text-muted">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm text-primary me-2"
                          />
                        </i>
                        {item}
                      </li>
                    ))}
                </ul> */}

                <div className="mt-4 d-block">
                  <button onClick={()=>{window.location.href = job.link}}  className="btn btn-outline-primary">
                    Apply Now <i className="mdi mdi-send"></i>
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <BackToTop /> */}
    </>
  );
};

export default JobDetails;
