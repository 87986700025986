import React, { useRef, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { FaPlay } from "react-icons/fa";
import "./css/demo.css";

function Video() {
  const videoRef = useRef(null);
  const [showPlayButton, setShowPlayButton] = useState(true);

  const playVideo = () => {
    if (videoRef.current) {
      videoRef.current.contentWindow.postMessage(
        '{"event":"command","func":"playVideo","args":""}',
        "*"
      );
    }
    setShowPlayButton(false);
  };
  return (
    <div className="section Featuredjobsbg pt-4" style={{ padding: "25px" }}>
      <Container>
        <div className="row justify-content-center">
          <Col lg={12} md={12} className="text-center">
            <div
              className="video-solution-cta position-relative"
              style={{ zIndex: "1" }}
            >
              <div className="position-relative">
                <div
                  className="youtube"
                  title="TalenIQ"
                  style={{
                    aspectRatio: "1.7699115044247788",
                    paddingTop: "10px",
                    overflow: "hidden",
                  }}
                >
                  {/* Circular Play Button */}
                  {showPlayButton && (
                    <button
                      onClick={playVideo}
                      className="custom-play-button"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        cursor: "pointer",
                        borderRadius: "50%",
                        backgroundColor: "#fff",
                        padding: "15px", // Adjust the size as needed
                      }}
                    >
                      <FaPlay size={20} color="#000" />
                    </button>
                  )}
                  <iframe
                    src="https://www.youtube.com/embed/uhHIjgN9ozw?autoplay=1&rel=0"
                    ref={playVideo}
                    title="TalentIQ"
                    allow="picture-in-picture; clipboard-write"
                    width="100%"
                    height="100%"
                    className="iframe-video"
                  ></iframe>
                </div>
              </div>
              <div
                className="content mt-md-2 pt-md-1"
                style={{ paddingLeft: "10px", paddingRight: "10px" }}
              >
                <div className="row justify-content-center">
                  <Col lg={12} className="text-center">
                    <div className="row align-items-center">
                      <Col md={6} className="mt-4 pt-2">
                        <div className="section-title text-md-start">
                          <h4 className="title text-white title-dark mb-0">
                            Why settle for less?
                          </h4>
                        </div>
                      </Col>
                      <Col lg={6} md={6} className="mt-4 pt-md-2">
                        <div className="section-title text-md-start">
                          <p
                            className="dd-white para-desc"
                            align="justify"
                            style={{ color: "white" }}
                          >
                            You deserve a chance to shine, to be noticed by top
                            employers who value what you bring to the table.
                            That's why TalenIQ offers a unique, data-driven
                            approach that goes beyond the surface. Our IT
                            assessment process delves deep into your skills,
                            knowledge, and behaviors, presenting a holistic view
                            of your abilities that employers can't ignore.
                          </p>
                          <div className="mt-4 mb-4">
                            <a href="/" className="text-white title-dark">
                              Open Doors to Premium Jobs
                            </a>
                          </div>
                        </div>
                      </Col>
                    </div>
                  </Col>
                </div>
              </div>
            </div>
          </Col>
        </div>
        <div class="feature-posts-placeholder bg-primary bg-gradient"></div>
      </Container>
    </div>
  );
}

export default Video;
