import React from 'react';
import { Col, PaginationItem, PaginationLink } from 'reactstrap';

const Pagination = ({ itemsPerPage, totalItems, currentPage, paginate }) => {
  const pageNumbers = [];
  const maxPageNumbersToShow = 5; // Adjust this value as needed

  for (let i = Math.max(1, currentPage - 2); i <= Math.min(currentPage + 2, Math.ceil(totalItems / itemsPerPage)); i++) {
    pageNumbers.push(i);
  }

  const renderDots = (start, end) => (
    <PaginationItem disabled key={`dots${start}-${end}`}>
      <PaginationLink to="#" onClick={() => {}}>
        ...
      </PaginationLink>
    </PaginationItem>
  );

  const renderPageNumbers = () => {
    const result = [];
    let lastPage = 0;

    if (pageNumbers.length > 0 && pageNumbers[0] > 1) {
      result.push(renderDots(1, pageNumbers[0] - 1));
    }

    pageNumbers.forEach((number) => {
      if (lastPage + 1 !== number) {
        result.push(renderDots(lastPage, number - 1));
      }

      result.push(
        <PaginationItem key={number} className={currentPage === number ? 'active' : ''}>
          <PaginationLink to="#" onClick={() => paginate(number)}>
            {number}
          </PaginationLink>
        </PaginationItem>
      );

      lastPage = number;
    });

    if (pageNumbers.length > 0 && pageNumbers[pageNumbers.length - 1] < Math.ceil(totalItems / itemsPerPage)) {
      result.push(renderDots(lastPage, Math.ceil(totalItems / itemsPerPage)));
    }

    return result;
  };

  return (
    <Col xs={12} className="mt-4 pt-2">
      <ul className="pagination justify-content-center mb-0">
        <PaginationItem disabled={currentPage === 1}>
          <PaginationLink to="#" onClick={() => paginate(currentPage - 1)}>
            Previous
          </PaginationLink>
        </PaginationItem>
        {renderPageNumbers()}
        <PaginationItem disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}>
          <PaginationLink to="#" onClick={() => paginate(currentPage + 1)}>
            Next
          </PaginationLink>
        </PaginationItem>
      </ul>
    </Col>
  );
};

export default Pagination;
