import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import freelancer from "../../assets/images/home/freelancer.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropzone from "../../components/Shared/Dropzone";
import { Helmet } from "react-helmet";
import "./css/demo.css";
import {
  faAngular,
  faHtml5,
  faNodeJs,
  faPhp,
  faPython,
  faReact,
  faSwift,
  faVuejs,
} from "@fortawesome/free-brands-svg-icons";

class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>IT Assessment - Online Assessment - IT Assessment Test</title>
          <meta
            name="description"
            content="Elevate Your IT Assessment through our specialized AI Powered Skills Assessment Test, Online Assessment and Platform Join the Future of IT Assessment Test."
          />
        </Helmet>
        {/* <section className="bg-half-170 pb-0 bg-light d-table w-100 overflow-hidden" style={{ background: 'url("assets/images/shapes/shape2.png") top', zIndex: 0 }}> */}

        <section
          className="bg-half-100 pb-0 d-table w-100 herobackgroungimg"
          style={{ paddingLeft: "10px", paddingRight: "10px" }}
          id="home"
        >
          <Container>
            <Row className="mt-2 align-items-center">
              <Col lg={6} md={6}>
                <div className="title-heading me-lg-4">
                  <h1 className="heading mb-3">
                    Get TalentIQ Certified,{" "}
                    <span className="text-primary">Get Noticed!</span>{" "}
                  </h1>
                  <p className="para-desc text-muted">
                    Are you tired of sending out countless job applications,
                    only to be met with silence? Grab Your Coveted Job
                  </p>
                  {/* <div className="mt-4">
                    <Filezone />
                  </div> */}
                  <div className="mt-4">
                    <Dropzone />
                    {/* <Link
                      to="#"
                      //   className="btn btn-primary mt-2 me-2"
                      className="btn btn-primary btn-lg"
                      style={{ padding: "30px 90px" }}
                    >
                      <i className="uil uil-upload"></i> Upload your Resume
                    </Link>{" "} */}
                  </div>
                  <div
                    className="icon-line symbols mt-4 d-flex justify-content-between align-items-center "
                    style={{
                      width: "70%",
                      margin: "0 auto",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPython}
                      color="rgb(204, 204, 204)"
                      size="2x"
                      className="icon"
                      style={{ marginRight: "10px" }}
                    />
                    <FontAwesomeIcon
                      icon={faHtml5}
                      color="rgb(204, 204, 204)"
                      size="2x"
                      className="icon"
                      style={{ marginRight: "10px" }}
                    />
                    <FontAwesomeIcon
                      icon={faPhp}
                      color="rgb(204, 204, 204)"
                      size="2x"
                      className="icon"
                      style={{ marginRight: "10px" }}
                    />
                    <FontAwesomeIcon
                      icon={faSwift}
                      color="rgb(204, 204, 204)"
                      size="2x"
                      className="icon"
                      style={{ marginRight: "10px" }}
                    />
                    <FontAwesomeIcon
                      icon={faAngular}
                      color="rgb(204, 204, 204)"
                      size="2x"
                      className="icon"
                      style={{ marginRight: "10px" }}
                    />
                    <FontAwesomeIcon
                      icon={faReact}
                      color="rgb(204, 204, 204)"
                      size="2x"
                      className="icon"
                      style={{ marginRight: "10px" }}
                    />
                    <FontAwesomeIcon
                      icon={faNodeJs}
                      color="rgb(204, 204, 204)"
                      size="2x"
                      className="icon"
                      style={{ marginRight: "10px" }}
                    />
                    <FontAwesomeIcon
                      icon={faVuejs}
                      color="rgb(204, 204, 204)"
                      size="2x"
                      className="icon"
                      style={{ marginRight: "10px" }}
                    />
                  </div>
                </div>
              </Col>
              <Col lg={6} md={6} className="mt-10 pt-10 mt-sm-0 pt-sm-0">
                <div className="freelance-hero position-relative">
                  <div className=" position-relative">
                    <img
                      src={freelancer}
                      alt="IT Assessment"
                      title="IT Assessment"
                      className="mx-auto d-block img-fluid"
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
