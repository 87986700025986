import React from "react";
import { Container, Row, Col } from "reactstrap";
import talentIQScore from "../../assets/images/client/TalenIQ-Score.png";
import "./css/demo.css";

const Score = ({ onButtonClick }) => {
  const handleButtonClick = () => {
    // Notify the parent component (About) about the button click
    onButtonClick();
  };
  return (
    <div
      class="section pt-0 pb-0 mb-4"
      style={{ paddingLeft: "10px", paddingRight: "10px" }}
    >
      <Container className="mt-2">
        <Row className="align-items-center">
          <Col lg={5} md={5} className="col-12">
            <img
              src={talentIQScore}
              class="img-fluid mx-auto d-block"
              alt="Online Assessment Platform"
              title="Online Assessment Platform"
            />
          </Col>{" "}
          <Col lg={7} md={7} className="col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div className="section-title">
              <div
                className="alert alert-light alert-pills text-dark"
                role="alert"
              >
                <span className="content ">
                  A Badge of Excellence{" "}
                  <i className="fa fa-certificate" aria-hidden="true"></i>
                </span>
              </div>{" "}
              <h4 className="title mb-4">Your TalenIQ Score</h4>{" "}
              <p className="text-muted para-desc mb-0" align="justify">
                Upon completing the IT assessment, you'll receive your TalenIQ
                score – your badge of excellence that speaks volumes to
                employers. This score showcases your skills, knowledge, and fit
                in a language they understand. It's not just a score; it's your
                ticket to showing the world what you're truly capable of.
              </p>{" "}
              <div className="my-4 score-btn-container">
                <button
                  onClick={handleButtonClick}
                  className="btn btn-primary mt-2 me-2"
                >
                  Take the Assessment
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Score;
