import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import Business from "../../assets/images/talentiq/Business.png";
import calendar from "../../assets/images/talentiq/calendar.svg";
import Enhanced from "../../assets/images/talentiq/Enhanced Hiring Decisions-3.jpg";
import Improved from "../../assets/images/talentiq/Improved Success Rate-2.jpg";
import TimeSaving from "../../assets/images/talentiq/Time-Saving AI Assistant-3.jpg";
import TalentIQBadge from "../../assets/images/talentiq/TalentIQ Badge of Expertise-2.jpg";
import ctabg from "../../assets/images/talentiq/cta-bg.jpeg";
import modern01 from "../../assets/images/modern01.jpg";
import mcq from "../../assets/images/talentiq/mcq.png";
import coding from "../../assets/images/talentiq/coding.png";
import video from "../../assets/images/talentiq/video-based-assessment.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Helmet } from "react-helmet";
import Testimonial from "../../components/Layout/Testimonial";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import "../../assets/css/Style.css";

import image1 from "../../assets/images/client/01.jpg";
import image2 from "../../assets/images/client/02.jpg";
import image3 from "../../assets/images/client/03.jpg";
import image4 from "../../assets/images/client/04.jpg";
import image5 from "../../assets/images/client/05.jpg";
import image6 from "../../assets/images/client/06.jpg";

function BusinessHero() {
  window.scrollTo(0, 0);
  const scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add("nav-sticky");
      } else {
        navBar.classList.remove("nav-sticky");
        document.getElementById("top-menu").classList.remove("nav-light");
        //   document.querySelector(".shoppingbtn").classList.add("btn-primary");
        //   document.querySelector(".shoppingbtn").classList.remove("btn-light");
        //   document.querySelector(".settingbtn").classList.add("btn-soft-primary");
      }
    }
  };
  useEffect(() => {
    document.body.classList = "";
    //   document.querySelector(".shoppingbtn").classList.add("btn-primary");
    window.addEventListener("scroll", scrollNavigation, true);

    return () => {
      window.removeEventListener("scroll", scrollNavigation, true);
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>Business Assessment | Assessment Process - Talentiq.app</title>
        <meta
          name="description"
          content="Hiring Decisions Assessment process, blending business Assessment  and technical assessments for an improved success, Save time with our efficient AI Assistant. "
        />
      </Helmet>

      <section
        className="bg-half-100 d-table w-100"
        style={{
          background: `url(${modern01}) center center`,
        }}
      >
        ""
        <div className="bg-overlay bg-overlay-white-color"></div>
        <Container>
          <Row className="mt-3 justify-content-center">
            <Col lg={12} className="text-center">
              <div className="pages-heading">
                <h2 className="title mb-0">Business</h2>
              </div>
            </Col>
          </Row>

          {/* <div className="position-breadcrumb">
            <nav aria-label="breadcrumb" className="d-inline-block">
              <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>{" "}
                <li className="breadcrumb-item active" aria-current="page">
                  Contact
                </li>
              </ul>
            </nav>
          </div> */}
        </Container>
      </section>
      <div className="form-icon position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="bg-half-100 pt-5 pb-0 bg-white d-table w-100 overflow-hidden">
        <div
          className="container"
          style={{ paddingLeft: "20px", paddingRight: "20px" }}
        >
          <div className="row text-center">
            <div className="col-12 ">
              <h1>Business Assessment</h1>
              <p className="px-5">Business assessment is a systematic process that involves evaluating various aspects of an organization,  This comprehensive analysis aims to identify strengths, weaknesses, opportunities, and threats, enabling informed decision-making and strategic planning for sustainable growth.</p>
            </div>
          </div>
          <div className="row align-items-center mt-5 mt-sm-0">
            <div className="col-md-6">
              <div className="title-heading text-center text-md-start">
                <div className="mg-b-15">Top Talent Assessment Platform</div>
                <h2 className="heading mb-3 mt-2">
                  Where CVs Blur, TalentIQ Sharpens.
                </h2>
                <p
                  className="text-muted mb-0 para-dark para-desc mx-auto ms-md-auto"
                  align="justify"
                >
                  Simplify Hiring with TalentIQ - Top Talent Assessment
                  Platform. Get accurate insights fast and make unbiased
                  decisions as we redefine the way you hire top talents.
                </p>
                <div className="mt-4">
                  {" "}
                  <a href="/contact" className="btn btn-primary">
                    Book Demo{" "}
                    <img className="ht-40" src={calendar} height="20px" />
                  </a>
                  <div className="icon-line mt-4">
                    {" "}
                    <i className="fab fa-python"></i>{" "}
                    <i className="fab fa-html5"></i>{" "}
                    <i className="fab fa-css3-alt"></i>{" "}
                    <i className="fab fa-php"></i>{" "}
                    <i className="fab fa-ruby"></i>{" "}
                    <i className="fab fa-swift"></i>{" "}
                    <i className="fab fa-angular"></i>{" "}
                    <i className="fab fa-react"></i>{" "}
                    <i className="fab fa-node-js"></i>{" "}
                    <i className="fab fa-vuejs"></i>{" "}
                  </div>
                </div>
              </div>
            </div>
            {/* !--end col-- */}

            <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div class="freelance-hero position-relative">
                <div class="bg-shape position-relative">
                  {" "}
                  <img
                    src={Business}
                    class="mx-auto d-block img-fluid"
                    alt="Business Assessment"
                    title="Business Assessment"
                  />{" "}
                </div>
              </div>
            </div>
            {/* <!--end col-->  */}
          </div>
          {/* <!--end row-->  */}
        </div>
        {/* <!--end container-->  */}
      </section>
      {/* <!--end section-->  */}
      {/* <!-- End Hero --> */}

      <section class="mb-3 pt-5 overflow-hidden">
        <div
          class="container mt-4"
          style={{ paddingLeft: "20px", paddingRight: "20px" }}
        >
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
              <div class="modern-app-bg-shape position-relative">
                <div class="px-4 mb-4">
                  <img
                    src={Enhanced}
                    class="img-fluid mover mx-auto d-block rounded-md shadow"
                    alt=""
                  />
                </div>

                <div
                  class="modern-saas-absolute-right wow animate__animated animate__fadeInUp"
                  data-wow-delay=".5s"
                ></div>
              </div>
            </div>
            {/* <!--end col--> */}

            <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div class="section-title ms-lg-5">
                <h4 class="title mb-4">Enhanced Hiring Decisions</h4>
                <p class="text-muted">
                  {/* <p class="text-muted" style="text-align: justify"> */}
                  With TalentIQ's comprehensive insights, you'll have the upper
                  hand in identifying the ideal candidate. Our thorough
                  evaluation of{" "}
                  <a href="https://www.talentiq.app/about">skills Assessment</a>
                  , knowledge, and behaviors ensures you're equipped with the
                  information you need to confidently select candidates who
                  resonate with the role and enrich your company culture, all
                  backed by data-driven precision.
                </p>
              </div>
            </div>
            {/* <!--end col-->  */}
          </div>
          {/* <!--end row-->  */}
        </div>
        {/* <!--end container--> */}

        <div class="container mt-5">
          <div
            class="row align-items-center"
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
          >
            <div class="col-lg-6 col-md-6 order-lg-2">
              {/* <!-- This column will be first on large screens --> */}
              <div class="modern-app-bg-shape position-relative">
                <div class="px-4 mb-4">
                  <img
                    src={Improved}
                    class="img-fluid mover mx-auto d-block rounded-md shadow"
                    alt=""
                  />
                </div>

                <div
                  class="modern-saas-absolute-right wow animate__animated animate__fadeInUp"
                  data-wow-delay=".5s"
                ></div>
              </div>
            </div>
            {/* <!--end col--> */}

            <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-lg-1">
              {/* <!--  This column will be second on large screens --> */}
              <div class="section-title me-lg-5">
                <h4 class="title mb-4">Improved Success Rate</h4>
                <p class="text-muted">
                  {/* <p class="text-muted" style="text-align: justify"> */}
                  With a robust talent assessment process, TalentIQ enhances the
                  company's overall recruitment success rate. By identifying
                  candidates with the right technical and soft skills, the
                  chances of hiring top talent increase significantly, leading
                  to more successful and productive hires.
                </p>
              </div>
            </div>
            {/* <!--end col-->  */}
          </div>
          {/* <!--end row-->  */}
        </div>
        {/* <!--end container--> */}

        <div class="container mt-5 ">
          <div
            class="row align-items-center"
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
          >
            <div class="col-lg-6 col-md-6">
              <div class="modern-app-bg-shape position-relative">
                <div class="px-4 mb-4">
                  <img
                    src={TimeSaving}
                    class="img-fluid mover mx-auto d-block rounded-md shadow"
                    alt=""
                  />
                </div>

                <div
                  class="modern-saas-absolute-right wow animate__animated animate__fadeInUp"
                  data-wow-delay=".5s"
                ></div>
              </div>
            </div>
            {/* <!--end col--> */}

            <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div class="section-title ms-lg-5">
                <h4 class="title mb-4">Time-Saving AI Assistant</h4>
                <p class="text-muted">
                  {/* <p class="text-muted" style="text-align: justify"> */}
                  TalentIQ serves as an AI assistant for candidate screening,
                  automating the talent assessment process. By generating
                  tailored questions and evaluating responses, the software
                  reduces the need for manual screening and shortlisting. As a
                  result, companies save valuable time and resources, enabling
                  HR teams to focus on other critical aspects of the hiring
                  process.
                </p>
              </div>
            </div>
            {/* <!--end col-->  */}
          </div>
          {/* <!--end row-->  */}
        </div>
        {/* <!--end container--> */}

        <div class="container mt-5">
          <div
            class="row align-items-center"
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
          >
            <div class="col-lg-6 col-md-6 order-lg-2">
              {/* <!-- This column will be first on large screens --> */}
              <div class="modern-app-bg-shape position-relative">
                <div class="px-4 mb-4">
                  <img
                    src={TalentIQBadge}
                    class="img-fluid mover mx-auto d-block rounded-md shadow"
                    alt=""
                  />
                </div>

                <div
                  class="modern-saas-absolute-right wow animate__animated animate__fadeInUp"
                  data-wow-delay=".5s"
                ></div>
              </div>
            </div>
            {/* <!--end col--> */}

            <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-lg-1">
              {/* <!-- This column will be second on large screens --> */}
              <div class="section-title me-lg-5">
                <h4 class="title mb-4">TalentIQ Badge of Expertise</h4>
                <p class="text-muted">
                  {/* <p class="text-muted" style="text-align: justify"> */}
                  Upon completing the talent assessment process, candidates
                  receive a TalentIQ score. This score acts as a badge of
                  expertise, showcasing the candidate's skills and knowledge.
                  Companies can easily interpret these scores, allowing for
                  quick comparisons among different candidates and simplifying
                  the selection process.
                </p>
              </div>
            </div>
            {/* <!--end col-->  */}
          </div>
          {/* <!--end row-->  */}
        </div>
        {/* <!--end container--> */}
      </section>

      {/* <!-- CTA Start --> */}
      <section
        class="section mt-4 bg-cta jarallax overflow-hidden"
        data-jarallax
        data-speed="0.5"
        // style={ctabg}
        // style="background: url('assets/talentiq/cta-bg.jpeg'); margin-top: 50px"
        id="cta"
      >
        <div class="bg-overlay1"></div>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 text-center">
              <div class="section-title">
                <h4 class="title title-dark text-white mb-4">
                  Elevate Your Hiring Process with
                  {/* <br> </br> */}
                  TalentIQ's AI-Powered Talent Assessment Platform
                </h4>
                <a href="/contact" class="btn btn-light mt-2 me-2">
                  Get Started
                </a>
              </div>
            </div>
            {/* <!--end col-->  */}
          </div>
          {/* <!--end row-->  */}
        </div>
        {/* <!--end container-->  */}
      </section>
      {/* <!--end section-->  */}
      {/* <!-- CTA End --> */}

      <section class="section pt-5 pb-5" overflow-hidden>
        <div class="container">
          <div class="section-title pb-2 text-center">
            <h4 class="title mb-4">TalentIQ - Talent Assessment Platform</h4>
            <h4 class="title mb-4">3-Step Assessment Process</h4>
          </div>
          <div class="row">
            <div class="col-md-4 mt-4 pt-2">
              <div class="card features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
                <div class="icons text-center mx-auto">
                  <img
                    src={mcq}
                    alt="Custom Image"
                    class="rounded h3 mb-0"
                    height="80px"
                  />
                </div>
                <div class="card-body">
                  <h5 class="text-dark">MCQ Assessment</h5>
                  <p class="text-muted mb-0" align="justify">
                    The first step in the TalentIQ talent assessment process
                    involves multiple-choice questions (MCQs) tailored to each
                    candidate's resume. The questions are generated based on the
                    skills and expertise mentioned in the candidate's resume.
                    This step allows the software to evaluate the candidate's
                    theoretical knowledge and grasp of relevant concepts.
                  </p>
                </div>
              </div>
            </div>
            {/* <!--end col--> */}

            <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
              <div class="card features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
                <div class="icons text-center mx-auto">
                  <img
                    src={coding}
                    alt="Custom Image"
                    class="rounded h3 mb-0"
                    height="80px"
                  />
                </div>
                <div class="card-body">
                  <h5 class="text-dark">Coding Test</h5>
                  <p class="text-muted mb-0" align="justify">
                    In the second phase, candidates are given a coding test that
                    assesses their practical coding skills. The coding
                    challenges are designed to match the specific technical
                    requirements of the job position and are influenced by the
                    candidate's past project experiences. This ensures a more
                    comprehensive evaluation of the candidate's abilities.
                  </p>
                </div>
              </div>
            </div>
            {/* <!--end col--> */}

            <div class="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2">
              <div class="card features feature-primary feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
                <div class="icons text-center mx-auto">
                  <img
                    src={video}
                    alt="Custom Image"
                    class="rounded h3 mb-0"
                    height="80px"
                  />
                </div>
                <div class="card-body">
                  <h5 class="text-dark">Video Behavioural Assessment</h5>
                  <p class="text-muted mb-0" align="justify">
                    The final step involves a video-based behavioural
                    assessment. Candidates are presented with scenarios and are
                    required to respond, providing insights into their soft
                    skills, communication abilities, and overall suitability for
                    the company's work culture. This step is essential for
                    assessing the candidate's compatibility with the team and
                    the organization.
                  </p>
                </div>
              </div>
            </div>
            {/* <!--end col-->  */}
          </div>
          {/* <!--end row-->  */}
        </div>
        {/* <!--end container-->  */}
      </section>

      {/* <Carousel/> */}
      {/* <!-- Start --> */}
      {/* <!--  */}
      <div
        class="section bg-light pb-2"
        // style="background: url('assets/images/shapes/shape2.png') center center;"
        // style="background: url('assets/images/shapes/shape2.png') center center;"
        style={{ paddingTop: "10px" }}
      >
        <div class="container">
          <section
            class=" bg-light"
            style={{
              background: "../../assets/images/shapes/shape2.png",
            }}
          >
            <div class="container">
              {/* <div class="row justify-content-center">
                <div class="col-8">
                  <div class="section-title pb-2 text-center">
                    <div class="row justify-content-center">
                      <div class="col-12">
                        <div class="section-title pb-2 text-center">
                          <h4 class="title mb-4">
                            Redefining Candidate Experience
                          </h4>
                          <p class="text-muted para-desc mx-auto mb-0">
                            At{" "}
                            <span class="text-primary fw-bold">TalentIQ</span>,
                            candidate experience is paramount. Our platform
                            ensures that candidates face a seamless and positive
                            journey, enabling them to showcase their talents
                            confidently.
                          </p>
                        </div>
                      </div>
                     
                    </div> */}
              {/* <SectionTitle
            className="col-lg-4 text-center"
            title="Redefining Candidate Experience"
            desc="At TalentIQ, candidate experience is paramount. Our platform ensures that candidates face a seamless and positive journey, enabling them to showcase their talents confidently using our AI enabled skills assessment test"
            /> */}
              {/* <h4 class="title mb-4">Redefining Candidate Experience</h4>
              <p class="text-muted mx-auto mb-0 text-center" align="justify">
                At TalentIQ, candidate experience is paramount. Our platform
                ensures that candidates face a seamless and positive journey,
                enabling them to showcase their talents confidently using our AI
                enabled skills assessment test.
              </p> */}
              {/* </div>
                </div>
              </div> */}
              <div class="row justify-content-center active">
                {/* <div class="mt-4"> */}
                <div class="tiny-three-item d-flex">
                  <Testimonial />
                  {/* <Swiper
                      slidesPerView={1}
                      breakpoints={{
                        576: {
                          slidesPerView: 2,
                        },
                        1020: {
                          slidesPerView: 3,
                        },
                      }}
                      //navigation={true}
                      // pagination={true}
                      className="mySwiper"
                      modules={[Autoplay, Pagination, Navigation]}
                      autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                      }}
                      // speed={1000}
                      loop={true}
                      // pagination={{
                      //   clickable: true,
                      // }}
                    > */}

                  {/* <SwiperSlide>
                        <div class="tiny-slide ">
                          <div class="d-flex client-testi m-1">
                            {" "}
                            <img
                              src={image1}
                              class="avatar avatar-small client-image rounded shadow"
                              alt=""
                            />
                            <div class="card flex-1 content p-3 shadow rounded position-relative">
                              <ul class="list-unstyled mb-0">
                                <li class="list-inline-item">
                                  <i class="mdi mdi-star text-warning"></i>
                                </li>
                                <li class="list-inline-item">
                                  <i class="mdi mdi-star text-warning"></i>
                                </li>
                                <li class="list-inline-item">
                                  <i class="mdi mdi-star text-warning"></i>
                                </li>
                                <li class="list-inline-item">
                                  <i class="mdi mdi-star text-warning"></i>
                                </li>
                                <li class="list-inline-item">
                                  <i class="mdi mdi-star text-warning"></i>
                                </li>
                              </ul>
                              <p class="text-muted mt-2">
                                "TalentIQ has transformed the way we approach
                                hiring. The insights and data-driven approach
                                they provide have helped us find the perfect
                                candidates faster than ever. Their platform is a
                                game-changer for anyone serious about building a
                                skilled and cohesive team."
                              </p>
                              <br />
                              <h6 class="text-primary">
                                - John D. <br />
                                <small class="text-muted">
                                  Frontend Engineer
                                </small>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div class="tiny-slide ">
                          <div class="d-flex client-testi m-1">
                            {" "}
                            <img
                              src={image2}
                              class="avatar avatar-small client-image rounded shadow"
                              alt=""
                            />
                            <div class="card flex-1 content p-3 shadow rounded position-relative">
                              <ul class="list-unstyled mb-0">
                                <li class="list-inline-item">
                                  <i class="mdi mdi-star text-warning"></i>
                                </li>
                                <li class="list-inline-item">
                                  <i class="mdi mdi-star text-warning"></i>
                                </li>
                                <li class="list-inline-item">
                                  <i class="mdi mdi-star text-warning"></i>
                                </li>
                                <li class="list-inline-item">
                                  <i class="mdi mdi-star text-warning"></i>
                                </li>
                                <li class="list-inline-item">
                                  <i class="mdi mdi-star-half text-warning"></i>
                                </li>
                              </ul>
                              <p class="text-muted mt-2">
                                "As an HR professional, I'm always looking for
                                innovative ways to streamline our hiring
                                process. TalentIQ's AI-powered assessments have
                                given us a deeper understanding of candidates'
                                potential, enabling us to make informed
                                decisions. It's an essential tool in our
                                recruitment toolkit."
                              </p>
                              <br />
                              <h6 class="text-primary">
                                - Barbara McIntosh <br />
                                <small class="text-muted">HR Manager</small>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div class="tiny-slide ">
                          <div class="d-flex client-testi m-1">
                            {" "}
                            <img
                              src={image3}
                              class="avatar avatar-small client-image rounded shadow"
                              alt=""
                            />
                            <div class="card flex-1 content p-3 shadow rounded position-relative">
                              <ul class="list-unstyled mb-0">
                                <li class="list-inline-item">
                                  <i class="mdi mdi-star text-warning"></i>
                                </li>
                                <li class="list-inline-item">
                                  <i class="mdi mdi-star text-warning"></i>
                                </li>
                                <li class="list-inline-item">
                                  <i class="mdi mdi-star text-warning"></i>
                                </li>
                                <li class="list-inline-item">
                                  <i class="mdi mdi-star text-warning"></i>
                                </li>
                                <li class="list-inline-item">
                                  <i class="mdi mdi-star text-warning"></i>
                                </li>
                              </ul>
                              <p class="text-muted mt-2">
                                "TalentIQ has raised the bar when it comes to
                                technical assessments. Their coding challenges
                                and algorithm evaluations are top-notch. The
                                platform's ability to accurately gauge a
                                candidate's coding prowess is impressive and has
                                helped us identify hidden talents."
                              </p>
                              <br />
                              <h6 class="text-primary">
                                - Michael R <br />
                                <small class="text-muted">Tech Lead</small>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div class="tiny-slide ">
                          <div class="d-flex client-testi m-1">
                            {" "}
                            <img
                              src={image4}
                              class="avatar avatar-small client-image rounded shadow"
                              alt=""
                            />
                            <div class="card flex-1 content p-3 shadow rounded position-relative">
                              <ul class="list-unstyled mb-0">
                                <li class="list-inline-item">
                                  <i class="mdi mdi-star text-warning"></i>
                                </li>
                                <li class="list-inline-item">
                                  <i class="mdi mdi-star text-warning"></i>
                                </li>
                                <li class="list-inline-item">
                                  <i class="mdi mdi-star text-warning"></i>
                                </li>
                                <li class="list-inline-item">
                                  <i class="mdi mdi-star text-warning"></i>
                                </li>
                                <li class="list-inline-item">
                                  <i class="mdi mdi-star text-warning"></i>
                                </li>
                              </ul>
                              <p class="text-muted mt-2">
                                "In the competitive world of talent acquisition,
                                TalentIQ has been our secret weapon. Their
                                platform not only provides insightful
                                assessments but also helps us match candidates
                                with roles that truly fit their skills. It's a
                                win-win for candidates and companies alike."
                              </p>
                              <br />
                              <h6 class="text-primary">
                                - Emily S <br />
                                <small class="text-muted">
                                  Talent Acquisition Specialist:
                                </small>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div class="tiny-slide ">
                          <div class="d-flex client-testi m-1">
                            {" "}
                            <img
                              src={image5}
                              class="avatar avatar-small client-image rounded shadow"
                              alt=""
                            />
                            <div class="card flex-1 content p-3 shadow rounded position-relative">
                              <ul class="list-unstyled mb-0">
                                <li class="list-inline-item">
                                  <i class="mdi mdi-star text-warning"></i>
                                </li>
                                <li class="list-inline-item">
                                  <i class="mdi mdi-star text-warning"></i>
                                </li>
                                <li class="list-inline-item">
                                  <i class="mdi mdi-star text-warning"></i>
                                </li>
                                <li class="list-inline-item">
                                  <i class="mdi mdi-star text-warning"></i>
                                </li>
                                <li class="list-inline-item">
                                  <i class="mdi mdi-star text-warning"></i>
                                </li>
                              </ul>
                              <p class="text-muted mt-2">
                                " Finding candidates who are not only skilled
                                but also aligned with our company's values is
                                crucial. TalentIQ's holistic approach to skills
                                assessment has allowed us to build a stronger
                                team with members who are not just competent,
                                but also great cultural fits. "
                              </p>
                              <br />
                              <h6 class="text-primary">
                                - Sophia M <br />
                                <small class="text-muted">
                                  Candidate Experience Lead
                                </small>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div class="tiny-slide ">
                          <div class="d-flex client-testi m-1">
                            {" "}
                            <img
                              src={image6}
                              class="avatar avatar-small client-image rounded shadow"
                              alt=""
                            />
                            <div class="card flex-1 content p-3 shadow rounded position-relative">
                              <ul class="list-unstyled mb-0">
                                <li class="list-inline-item">
                                  <i class="mdi mdi-star text-warning"></i>
                                </li>
                                <li class="list-inline-item">
                                  <i class="mdi mdi-star text-warning"></i>
                                </li>
                                <li class="list-inline-item">
                                  <i class="mdi mdi-star text-warning"></i>
                                </li>
                                <li class="list-inline-item">
                                  <i class="mdi mdi-star text-warning"></i>
                                </li>
                                <li class="list-inline-item">
                                  <i class="mdi mdi-star text-warning"></i>
                                </li>
                              </ul>
                              <p class="text-muted mt-2">
                                "TalentIQ's platform has taken the stress out of
                                technical assessments for our candidates. The
                                feedback we've received from candidates about
                                their experience has been overwhelmingly
                                positive. It's refreshing to see a platform that
                                truly values both candidates and employers."
                              </p>
                              <br />
                              <h6 class="text-primary">
                                - David H <br />
                                <small class="text-muted">
                                  Engineering Manager
                                </small>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide> */}
                  {/* </Swiper> */}
                </div>
                {/* </div> */}
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default BusinessHero;
