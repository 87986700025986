import React from "react";
import { Redirect } from "react-router-dom";

//Main Index
import Main from "../pages/Home/indexMain";
//About
import About from "../pages/About/About";
// Jobs
import JobMain from "../pages/Jobs/IndexJob";
import JobDetails from "../pages/Jobs/JobDetails";
// Business
import IndexBuisness from "../pages/Business/IndexBuisness";
//Contact
import IndexContact from "../pages/Contact/IndexContact";
import Terms from "../pages/TermsAndCondi/Terms";
import Privacy from "../pages/Privacy/Privacy";

import PageNotFound from "../pages/PageNotFound/PageNotFound";

const routes = [
  //Index Main
  { path: "/", exact: true, component: Main },
  // { path: "/home", component: Main },
  { path: "/about", component: About },
  { path: "/jobs", exact: true, component: JobMain },
  { path: "/jobs/:jobId", exact: true, component: JobDetails },
  { path: "/business", component: IndexBuisness },
  { path: "/contact", component: IndexContact },
  { path: "/terms", component: Terms },
  { path: "/privacy", component: Privacy },
];

export default routes;
