import axios from "axios";

import { log_debug } from "../helpers/logger";
// import { usersession } from "../store/usersession";
// import { assessmentsession } from "../store/assessmentsession";
// import { token } from "../store/token";
// import { toast } from "../store/toast";
import { ep, common, unhandled_error } from "../config/dev";

let bind_account_tokens_credit = 0;
let curr_usersession;
// usersession.subscribe((_usersession) => {
//  log_debug("Service", "usersession.subscribe", "_usersession :: " + JSON.stringify(_usersession));
//  curr_usersession = _usersession;
// });

// let curr_assessmentsession;
// assessmentsession.subscribe((_assessmentsession) => {
//  log_debug("Service", "assessmentsession.subscribe", "_assessmentsession :: " + JSON.stringify(_assessmentsession));
//  curr_assessmentsession = _assessmentsession;
// });

// let curr_token;
// token.subscribe(_token => {
// 	log_debug('Service','token.subscribe','_token :: ' + JSON.stringify(_token))
// 	curr_token = _token;
// });

export const api_post = async function (min_token_balance, api_ep, req_body, callback) {
 let transaction_guid = generate_guid();
 log_debug("Service", "api_post", "api_ep :: " + api_ep + " req_body :: " + JSON.stringify(req_body));
 let startAt = new Date().getTime();
 axios
  .post(api_ep, req_body, {
   headers: {
    "Content-Type": "application/json",
    authorizationToken: common.auth_token,
    service_ref: curr_usersession && curr_usersession.service_ref ? curr_usersession.service_ref : common.service_ref,
    transaction_guid: transaction_guid,
    session_guid: curr_usersession ? curr_usersession.session_id : "",
    user_guid: curr_usersession && curr_usersession.user ? curr_usersession.user.guid : "",
   },
  })
  .then((_post_res) => {
   let res_json = _post_res.data;
   if (res_json && res_json.success) {
    log_debug(transaction_guid, "[Service JS] api_post()", "data :: " + JSON.stringify(res_json.data));
    let exectime = new Date().getTime() - startAt;
    log_debug("Service", "api_post", "api_ep :: " + api_ep + " exectime :: " + exectime);
    // event_api_post(transaction_guid, {method: 'POST', transaction_guid: transaction_guid, ep: api_ep, body:req_body, exectime: exectime}, (err,res)=>{
    //   // TODO
    // })
    callback(null, res_json.data);
   } else {
    // toast.set({ error: { code: res_json.error.code, message: res_json.error.message } });
    callback(
     {
      error: {
       code: res_json.error.code,
       message: res_json.error.message,
      },
     },
     null
    );
   }
  })
  .catch((_post_err) => {
  //  toast.set({ error: { code: unhandled_error.server_error.code, message: unhandled_error.server_error.message } });
   callback(
    {
     error: { 
      code: unhandled_error.server_error.code,
      message: unhandled_error.server_error.message,
     },
    },
    null
   );
  });
};

export const api_get = async function (min_token_balance, api_ep, callback) {
 let transaction_guid = generate_guid();
 let startAt = new Date().getTime();
 axios
  .get(api_ep, {
   headers: {
    "Content-Type": "application/json",
    authorizationToken: common.auth_token,
    service_ref: curr_usersession && curr_usersession.service_ref ? curr_usersession.service_ref : common.service_ref,
    transaction_guid: transaction_guid,
    session_guid: curr_usersession ? curr_usersession.session_id : "",
    user_guid: curr_usersession && curr_usersession.user ? curr_usersession.user.guid : "",
   },
  })
  .then((_get_res) => {
   let res_json = _get_res.data;
   if (res_json && res_json.success) {
    log_debug(transaction_guid, "[Service JS] api_get()", "data :: " + JSON.stringify(res_json.data));
    let exectime = new Date().getTime() - startAt;
    log_debug("Service", ">> api_get", "api_ep :: " + api_ep + " exectime :: " + exectime);
    // event_api_post(transaction_guid, {method:'GET', transaction_guid: transaction_guid, ep: api_ep, params:[], exectime: exectime}, (err,res)=>{
    //   // TODO
    // })
    callback(null, res_json.data);
   } else {
    // toast.set({ error: { code: res_json.error.code, message: res_json.error.message } });
    callback(
     {
      error: {
       code: res_json.error.code,
       message: res_json.error.message,
      },
     },
     null
    );
   }
  })
  .catch((_get_err) => {
   // toast.set({ error: { code: unhandled_error.server_error.code, message: unhandled_error.server_error.message } });
   callback(
    {
     error: {
      code: unhandled_error.server_error.code,
      message: unhandled_error.server_error.message,
     },
    },
    null
   );
  });
};

const event_api_post = async function (transaction_guid, event_body, callback) {
 log_debug("Service", "event_api_post", "event_body :: " + JSON.stringify(event_body));
 const res = await fetch(ep.event + "/v1/apievent", {
  method: "POST",
  mode: "cors",
  body: JSON.stringify(event_body),
  headers: {
   "Content-Type": "application/json",
   authorizationToken: common.auth_token,
   service_ref: curr_usersession && curr_usersession.service_ref ? curr_usersession.service_ref : common.service_ref,
   transaction_guid: transaction_guid,
   session_guid: curr_usersession ? curr_usersession.session_id : "",
   user_guid: curr_usersession && curr_usersession.user ? curr_usersession.user.guid : "",
  },
  json: true,
 });
 const res_json = await res.json();
 callback(null, res_json);
};

// export const generate_mcq_api_post = async function (min_token_balance, _body, callback) {
//  log_debug("Service", "generate_mcq_api_post", "_body :: " + JSON.stringify(_body));
//  api_post(min_token_balance, ep.assessment + '/v1/assessment', _body, (_mcq_err,_mcq_res)=>{
//   // TODO handle ERROR
//   assessmentsession.set({
//     guid: curr_assessmentsession.guid,
//     candidate_guid: curr_assessmentsession.guid,
//     role: curr_assessmentsession.role,
//     primary_skills: curr_assessmentsession.primary_skills,
//     secondary_skills: curr_assessmentsession.secondary_skills,
//     mcq_questions:(curr_assessmentsession.mcq_questions || []).concat(..._mcq_res.questions),
//     coding_questions:(curr_assessmentsession.coding_questions || [])
//    });
//    callback(_mcq_err,_mcq_res)
//  })
//  };

//  export const generate_coding_api_post = async function (min_token_balance, _body, callback) {
//   log_debug("Service", "generate_coding_api_post", "_body :: " + JSON.stringify(_body));
//   api_post(min_token_balance, ep.assessment + '/v1/assessment', _body, (_coding_err,_coding_res)=>{
//   log_debug("Service", "generate_coding_api_post", "_coding_res :: " + JSON.stringify(_coding_res));
//   // TODO handle ERROR
//    assessmentsession.set({
//      guid: curr_assessmentsession.guid,
//      candidate_guid: curr_assessmentsession.guid,
//      role: curr_assessmentsession.role,
//      primary_skills: curr_assessmentsession.primary_skills,
//      secondary_skills: curr_assessmentsession.secondary_skills,
//      mcq_questions:(curr_assessmentsession.mcq_questions || []),
//      coding_questions:(curr_assessmentsession.coding_questions || []).concat(..._coding_res.questions)
//     });
//     callback(_coding_err,_coding_res)
//   })
//   };

export const generate_guid = function () {
 return common.guid_format.replace(/[xy]/g, function (c) {
  var r = (Math.random() * 16) | 0,
   v = c === "x" ? r : (r & 0x3) | 0x8;
  return v.toString(16);
 });
};

export const unique = (arr) => {
 var u = {},
  a = [];
 for (var i = 0, l = arr.length; i < l; ++i) {
  if (!u.hasOwnProperty(arr[i])) {
   a.push(arr[i]);
   u[arr[i]] = 1;
  }
 }
 return a;
};
