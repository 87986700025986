import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, Input, Label } from "reactstrap";
import { FaRedditAlien } from "react-icons/fa";
//Import Icons
import FeatherIcon from "feather-icons-react";
import RedditIcon from "@mui/icons-material/Reddit";

// import images
import americanEx from "../../assets/images/payments/american-ex.png";
import discover from "../../assets/images/payments/discover.png";
import masterCard from "../../assets/images/payments/master-card.png";
import paypal from "../../assets/images/payments/paypal.png";
import visa from "../../assets/images/payments/visa.png";

//Import Images
import logolight from "../../assets/images/talentiq-b.png";
import logodark from "../../assets/images/talentiq-a.png";

import india from "../../assets/images/India-flag.png";
import usa from "../../assets/images/USA-flag.png";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      grid1: [
        { title: "About us", link: "/about" },
        { title: "For Business", link: "/business" },
        { title: "Contact", link: "/contact" },
        // { title: "Team", link: "#" },
        // { title: "Pricing", link: "#" },
        // { title: "Project", link: "#" },
        // { title: "Careers", link: "#" },
        // { title: "Blog", link: "#" },
        // { title: "Login", link: "#" },
      ],
      grid2: [
        { title: "Terms of Services", link: "/terms" },
        { title: "Privacy Policy", link: "/privacy" },
        // { title: "Documentation", link: "#" },
        // { title: "Changelog", link: "#" },
        // { title: "Components", link: "#" },
      ],
      paymentCardData: [
        {
          img: americanEx,
          title: "American Express",
        },
        {
          img: discover,
          title: "Discover",
        },
        {
          img: masterCard,
          title: "Master Card",
        },
        {
          img: paypal,
          title: "Paypal",
        },
        {
          img: visa,
          title: "Visa",
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <footer className="footer p-2">
          <Container>
            <Row>
              <Col className="col-12">
                <div
                  className={
                    this.props.isBorderLine
                      ? "footer-py-30 footer-border"
                      : "footer-py-30"
                  }
                  style={{ paddingTop: "40px", paddingBottom: "15px" }}
                >
                  <Row>
                    <Col
                      lg={4}
                      className="col-lg-4 col-12 mb-0  mb-md-4 pb-0 pb-md-2"
                    >
                      <Link to="/" className="logo-footer">
                        <img
                          src={this.props.isLight ? logodark : logolight}
                          // color={this.props.isLight ? "black" : "white"}
                          height="44"
                          alt=""
                        />
                      </Link>
                      <p
                        className={
                          this.props.isLight ? "mt-4 text-muted" : "mt-4"
                        }
                      >
                        Discover a new era of recruitment with TalentIQ. <br />
                        Your trusted ally in innovative assessments and
                        streamlined hiring solutions.
                      </p>
                      {/* <ul
                        className={
                          this.props.isLight
                            ? "list-unstyled social-icon social mb-0 m t-4"
                            : "list-unstyled social-icon foot-social-icon mb-0 mt-4"
                        }
                      >
                        <li className="list-inline-item me-1">
                          <Link to="#" className="rounded">
                            <FeatherIcon
                              icon="facebook"
                              className="fea icon-sm fea-social"
                            />
                          </Link>
                        </li>
                        <li className="list-inline-item me-1">
                          <Link to="#" className="rounded">
                            <FeatherIcon
                              icon="instagram"
                              className="fea icon-sm fea-social"
                            />
                          </Link>
                        </li>
                        <li className="list-inline-item me-1">
                          <Link to="#" className="rounded">
                            <FeatherIcon
                              icon="twitter"
                              className="fea icon-sm fea-social"
                            />
                          </Link>
                        </li>
                        <li className="list-inline-item me-1">
                          <Link to="#" className="rounded">
                            <FeatherIcon
                              icon="linkedin"
                              className="fea icon-sm fea-social"
                            />
                          </Link>
                        </li>
                      </ul> */}
                      <ul
                        className={
                          this.props.isLight
                            ? "list-unstyled social-icon social mb-0 m t-4"
                            : "list-unstyled social-icon foot-social-icon mb-0 mt-4"
                        }
                      >
                        <li className="list-inline-item me-1">
                          <a
                            href="https://www.facebook.com/Talentiqapp/"
                            target="_blank"
                            rel="noreferrer"
                            className="rounded"
                          >
                            <FeatherIcon
                              icon="facebook"
                              className="fea icon-sm fea-social"
                            />
                          </a>
                        </li>
                        <li className="list-inline-item me-1">
                          <a
                            href="https://www.instagram.com/talentiq_recruittech/"
                            target="_blank"
                            rel="noreferrer"
                            className="rounded"
                          >
                            <FeatherIcon
                              icon="instagram"
                              className="fea icon-sm fea-social"
                            />
                          </a>
                        </li>
                        <li className="list-inline-item me-1">
                          <a
                            href="https://twitter.com/talentiq_app"
                            target="_blank"
                            rel="noreferrer"
                            className="rounded"
                          >
                            <FeatherIcon
                              icon="twitter"
                              className="fea icon-sm fea-social"
                            />
                          </a>
                        </li>
                        <li className="list-inline-item me-1">
                          <a
                            href="https://www.linkedin.com/company/talentiq-recruittech/"
                            target="_blank"
                            rel="noreferrer"
                            className="rounded"
                          >
                            <FeatherIcon
                              icon="linkedin"
                              className="fea icon-sm fea-social"
                            />
                          </a>
                        </li>
                        <li className="list-inline-item me-1">
                          <a
                            href="https://www.reddit.com/user/talentiq_recruittech/"
                            target="_blank"
                            rel="noreferrer"
                            className="rounded"
                          >
                            <FaRedditAlien />
                            {/* <FeatherIcon
                              icon="reddit"
                              className="fea icon-sm fea-social"
                            /> */}
                            {/* <RedditIcon className="fea icon-sm fea-social" /> */}
                          </a>
                        </li>
                      </ul>
                    </Col>

                    <Col
                      lg={2}
                      md={4}
                      className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
                      style={{ paddingLeft: "15px" }}
                    >
                      <h5
                        className={
                          this.props.isLight
                            ? "text-dark footer-head"
                            : "text-light footer-head"
                        }
                      >
                        Company
                      </h5>
                      <ul className="list-unstyled footer-list mt-4">
                        {this.state.grid1.map((grid, key) => (
                          <li key={key}>
                            <a
                              href={grid.link}
                              className={
                                this.props.isLight ? "text-muted" : "text-foot"
                              }
                            >
                              {/* <i className="uil uil-angle-right-b me-1"></i>{" "} */}
                              {grid.title}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </Col>

                    <Col
                      lg={3}
                      md={4}
                      className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
                    >
                      <h5
                        className={
                          this.props.isLight
                            ? "text-dark footer-head"
                            : "text-light footer-head"
                        }
                      >
                        Usefull Links
                      </h5>
                      <ul className="list-unstyled footer-list mt-4">
                        {this.state.grid2.map((grid, key) => (
                          <li key={key}>
                            <Link
                              to={grid.link}
                              className={
                                this.props.isLight ? "text-muted" : "text-foot"
                              }
                            >
                              {/* <i className="uil uil-angle-right-b me-1"></i>{" "} */}
                              {grid.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </Col>

                    <Col
                      lg={3}
                      md={4}
                      className="col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
                    >
                      <h5
                        className={
                          this.props.isLight
                            ? "text-dark footer-head"
                            : "text-light footer-head"
                        }
                      >
                        Address
                      </h5>
                      <ul className="list-unstyled footer-list mt-2 pt-3">
                        <li className=" uil uil-envelope list-inline-item">
                          <a
                            href="mailto:hello@companybench.com"
                            style={{
                              color: "rgb(173, 181, 189)",
                              marginBottom: "1px",
                            }}
                            aria-label="Email us at hello@companybench.com"
                          >
                            {" "}
                            hello@companybench.com
                          </a>
                        </li>
                        <br />

                        <li className="list-inline-item">
                          <a
                            href="tel:918371010101"
                            style={{ color: "rgb(173, 181, 189)" }}
                          >
                            <img
                              src={india}
                              alt="India"
                              height={20}
                              weight={15}
                            />{" "}
                            +91-8371010101
                          </a>
                        </li>
                        <br />
                        <li className="list-inline-item">
                          <a
                            href="tel:+15612200044"
                            style={{ color: "rgb(173, 181, 189)" }}
                          >
                            <img src={usa} height={18} weight={14} alt="USA" />{" "}
                            +1-5612200044
                          </a>
                        </li>

                        <li className="list-inline-item">
                          <p className="uil uil-map-marker" title="Address">
                            {" "}
                            402, Summer Court, Magarpatta City, Pune,
                            Maharashtra 411013.
                          </p>
                        </li>
                      </ul>
                    </Col>

                    {/* <Col
                      lg={3}
                      md={4}
                      className="col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
                    >
                      <h5
                        className={
                          this.props.isLight
                            ? "text-dark footer-head"
                            : "text-light footer-head"
                        }
                      >
                        Newsletter
                      </h5>
                      <p className="mt-4">
                        Sign up and receive the latest tips via email.
                      </p>
                      <Form>
                        <Row>
                          <Col lg={12}>
                            <div
                              className={
                                this.props.isLight
                                  ? "foot-subscribe mb-3 foot-white"
                                  : "foot-subscribe mb-3"
                              }
                            >
                              <Label
                                className={
                                  this.props.isLight
                                    ? "form-label text-muted"
                                    : "form-label"
                                }
                              >
                                Write your email{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <div className="form-icon position-relative">
                                <FeatherIcon
                                  icon="mail"
                                  className="fea icon-sm icons"
                                />
                                <Input
                                  type="email"
                                  name="email"
                                  id="emailsubscribe"
                                  className={
                                    this.props.isLight
                                      ? "form-control ps-5 rounded bg-light border"
                                      : "form-control ps-5 rounded"
                                  }
                                  placeholder="Your email : "
                                  required
                                />
                              </div>
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="d-grid">
                              <input
                                type="submit"
                                id="submitsubscribe"
                                name="send"
                                className={
                                  this.props.isLight
                                    ? "btn btn-primary"
                                    : "btn btn-soft-primary"
                                }
                                value="Subscribe"
                              />
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </Col> */}
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>

          <div className="footer-py-30 footer-bar">
            <Container className="text-center">
              <Row className="align-items-center">
                {/* <Col sm={6}>
                  <div className="text-sm-start">
                    <p className="mb-0">© {(new Date().getFullYear())} Landrick. Design with {" "}
                      <i className="mdi mdi-heart text-danger"></i> by {" "}
                      <Link
                        to="https://themesbrand.in/"
                        target="_blank"
                        className="text-reset"
                        rel="noopener noreferrer"
                      >
                        Themesbrand
                      </Link>.</p>
                  </div>
                </Col> */}
                <Col sm="6">
                  <div className="text-sm-start">
                    <p className="mb-0">
                      © {new Date().getFullYear()} TalentIQ.
                      {/* <i className="mdi mdi-heart text-danger"></i> by{" "} */}
                      {/* <Link
                      to="https://companybench.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-reset" 
                    >
                       &nbsp; A product of CompanyBench.
                    </Link> */}
                      <a
                        href="https://companybench.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-reset"
                      >
                        {" "}
                        &nbsp;A product of CompanyBench.
                      </a>
                    </p>
                  </div>
                </Col>

                {/* <Col sm={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <ul className="list-unstyled text-sm-end mb-0 d-flex gap-1 flex-wrap justify-content-sm-end">
                    {this.state.paymentCardData.map((item, key) => (
                      <li className="list-inline-item" key={key}>
                        <Link to="#">
                          <img
                            src={item.img}
                            className="avatar avatar-ex-sm"
                            title={item.title}
                            alt=""
                          />
                        </Link>
                      </li>
                    ))}
                  </ul>
                </Col> */}
              </Row>
            </Container>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default Footer;
