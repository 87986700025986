import React from "react";
import { useCallback, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import "./Privacy.css";
function Privacy() {
  window.scrollTo(0, 0);
  const scrollNavigation = useCallback(() => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add("nav-sticky");
      } else {
        navBar.classList.remove("nav-sticky");
      }
    }
  }, []);

  useEffect(() => {
    document.body.classList = "";
    window.addEventListener("scroll", scrollNavigation, true);

    return () => {
      window.removeEventListener("scroll", scrollNavigation, true);
    };
  }, [scrollNavigation]);

  return (
    <>
      <div>
        <section className="bg-half-100 pb-0 d-table w-100">
          <Container>
            <Row className="mt-2 align-items-center">
              <Col md={12}>
                <h1
                  style={{
                    fontFamily: "IBM Plex Sans, sans-serif",
                    fontWeight: "600",
                    fontSize: "36px",
                    color: "rgb(33, 37, 41)",
                  }}
                >
                  Privacy Policy
                </h1>
                <b
                  style={{
                    fontFamily: "IBM Plex Sans, sans-serif",
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "rgb(33, 37, 41)",
                    lineHeight: "24px",
                  }}
                >
                  Last updated: July 3, 2023
                </b>
                <h2 className="privacy-h2">Introduction</h2>
                <p className="privacy-p">
                  Welcome to the privacy policy (the “Policy”) of Company bench
                  Enterprises Inc. (“we”, “us” and/or “our”). This Policy has
                  been created by Company bench Enterprises (“Company bench”),
                  Inc. to provide information about Company bench Enterprises
                  Inc. time tracker software and related services (the
                  "Service") to our users ("you" ,"your")
                </p>
                <p className="privacy-p">
                  This Privacy Policy sets forth Company bench Enterprises
                  Inc.’s policy with respect to information, including
                  personally identifiable data (“Personal Data”), and other
                  information that is collected from users of the Service.
                </p>
                <p className="privacy-p">
                  Your access to and use of the Service is subject to Company
                  bench Enterprises Inc.’s Terms and Conditions.
                </p>
                <h2 className="privacy-h2">Consent</h2>
                <p className="privacy-p">
                  By using our services, you agree to the terms of this Privacy
                  Policy. Please read the following carefully to understand our
                  practices regarding your personal data and how we will treat
                  it.
                </p>
                <p className="privacy-p">
                  1. <strong> Information Collection:</strong>You acknowledge
                  and agree that Company bench Enterprises Inc. collects and
                  processes your personal data as described in this Privacy
                  Policy.
                </p>
                <p className="privacy-p">
                  2. <strong>Use of Personal Data:</strong> You consent to the
                  use of your personal data for the purposes outlined in this
                  Privacy Policy, including but not limited to providing and
                  improving our services, responding to your inquiries, and
                  marketing purposes as described herein.
                </p>
                <p className="privacy-p">
                  3. <strong>Sharing of Personal Data:</strong> You understand
                  and agree that Company bench Enterprises Inc. may share your
                  personal data with third parties as described in this Privacy
                  Policy, such as business transfers, related companies, agents,
                  consultants, and legal requirements.
                </p>
                <p className="privacy-p">
                  4. <strong>Data Transfers:</strong> If you are located outside
                  the United States, you consent to the transfer of your
                  personal data to the United States as described in this
                  Privacy Policy, with appropriate safeguards in place.
                </p>
                <p className="privacy-p">
                  5. <strong>Data Retention:</strong>You acknowledge that your
                  personal data will be retained for the periods described in
                  this Privacy Policy and in accordance with applicable laws.
                </p>
                <p className="privacy-p">
                  6. <strong>Your Rights:</strong> You have the rights outlined
                  in this Privacy Policy, including the right to access,
                  rectify, erase, restrict processing, data portability, object,
                  and withdraw consent.
                </p>
                <p className="privacy-p">
                  7. <strong>Changes to Privacy Policy: </strong> You agree that
                  Company bench Enterprises Inc. may update or modify this
                  Privacy Policy, and your continued use of the service after
                  any changes indicates your agreement with the revised terms.
                </p>
                <p className="privacy-p">
                  8. <strong> Contact:</strong> If you have any questions about
                  this Privacy Policy or our information practices, please
                  contact us as indicated in the "Contacting Company bench
                  Enterprises Inc" section.
                </p>
                <p className="privacy-p">
                  This Privacy Policy sets forth Company bench Enterprises
                  Inc.’s policy with respect to information, including
                  personally identifiable data (“Personal Data”), and other
                  information that is collected from users of the Service.
                </p>
                <p className="privacy-p">
                  Your access to and use of the Service is subject to Company
                  bench Enterprises Inc.’s Terms and Conditions.
                </p>
                <h2 className="privacy-h2">Scope</h2>
                <p className="privacy-p">
                  This Privacy Policy sets out how Company bench Enterprises
                  Inc. collects, retains, and uses information, including
                  personal data (“Personal Data”) and non-identifiable data,
                  about Service users. This Privacy Policy also covers data that
                  company bench Enterprises Inc. collects in-person, for
                  instance at business conferences and trade shows, or through
                  client support.
                </p>
                <p className="privacy-p">
                  When you interact with us through the Service, we may collect
                  Personal Data and other information from you, as further
                  described below.
                </p>
                <h2 className="privacy-h2">Service Users</h2>
                <p className="privacy-p">Information We Collect</p>
                <p className="privacy-p">
                  We collect Personal Data that is entered on our Service or
                  sent to us electronically, for example when you complete a web
                  form to give Personal Data to us directly (such as contact our
                  support), when you voluntarily provide such information such
                  as when you register for access to the Company
                  benchEnterprises Inc. Service (account level data including
                  your name, email, and password), contact us with inquiries or
                  respond to one of our surveys.
                </p>
                <p className="privacy-p">
                  Wherever Company bench Enterprises Inc. collects Personal Data
                  we make an effort to provide a link to this Privacy Policy.
                </p>
                <p className="privacy-p">
                  When you interact with Company bench Enterprises Inc. through
                  the services, we collect automatically certain information,
                  including via cookies.
                </p>
                <p className="privacy-p">
                  As a user of our Service, our servers record information (“log
                  data”), including information that your computer or browser
                  automatically sends whenever you use the Service. This log
                  data includes your Internet Protocol (“IP”) address (from
                  which we understand the country you are connecting from at the
                  time you visit the Service), browser type and settings, the
                  date and time of your request.
                </p>
                <p className="privacy-p">
                  Where the information that we collect automatically on our
                  Service is Personal Data, our legal basis for the use of this
                  information is that it is necessary for the purposes of our
                  legitimate interests in maintaining the safe operation of our
                  Service as well as in learning how Service users interact with
                  our Service to improve your use of it.
                </p>
                How we use that information
                <p className="privacy-p">
                  To Provide The Services And Respond To Requests
                </p>
                <p className="privacy-p">
                  Company bench Enterprises Inc. uses the Personal Data you
                  provide in a manner that is consistent with this Privacy
                  Policy. If you provide Personal Data for a certain reason, we
                  may use the Personal Data in connection with the reason for
                  which it was provided.
                </p>
                <p>
                  For instance, if you contact us by email, we will use the
                  Personal Data you provide to answer your question or resolve
                  your problem. Also, if you provide Personal Data in order to
                  obtain access to the Service, we will use your Personal Data
                  to provide you with access to the Service, maintain your
                  account, contact you regarding your use of the Service or to
                  notify you of important changes to the Service, and to monitor
                  your use of such services. For individuals in the EU, such use
                  is necessary to respond to or implement your request and for
                  the performance of the contract between you and us.
                </p>
                <p className="privacy-p">For Marketing Purposes</p>
                <p className="privacy-p">
                  We may use your contact details to tell you about services we
                  believe will be of interest to you, upcoming events or other
                  promotions. If we do so, each marketing communication we send
                  you will contain instructions permitting you to "opt out" of
                  receiving future marketing communications. Note however that
                  as user of our services you cannot opt out of some
                  administrative communications that are reasonably necessary to
                  the services, such as service notifications. In addition, if
                  at any time you wish not to receive any future marketing
                  communications or you wish to have your name deleted from our
                  mailing lists, please contact us as indicated below.
                </p>
                <p className="privacy-p">
                  Where required by applicable law (for example, if you are an
                  individual in the EU), we will only send you marketing
                  information by email if you consent to us doing so at the time
                  you provide us with your Personal Data. When you provide us
                  with your consent to be contacted for marketing purposes, you
                  have the right to withdraw your consent at any time by
                  following the instructions to “opt-out” of receiving marketing
                  communication in each marketing email we send you or by
                  contacting us as indicated below.
                </p>
                <p className="privacy-p">
                  As Necessary For Certain Legitimate Interests
                </p>
                We use your Personal Data for the legitimate interests described
                below:
                <p className="privacy-p">
                  <p className="privacy-p">
                    To send administrative information to you, for example,
                    information regarding the Service and changes to our terms,
                    conditions, and policies.
                  </p>
                  <p className="privacy-p">
                    To respond to your inquiries and fulfill your requests, such
                    as to send you requested materials and newsletters, as well
                    as information and materials regarding our products and
                    services.
                  </p>
                  <p className="privacy-p">
                    To conduct analytics on how our Service is being used by you
                    for our internal purposes (namely for providing,
                    maintaining, benchmarking and improving our offerings,
                    identifying usage trends and determining the effectiveness
                    of our promotional campaigns) and to inform our marketing
                    strategy and personalize our communications with you
                    (including providing information on our features and other
                    marketing and service-related announcements relevant to the
                    content and features you engage with).
                  </p>
                  To supplement the information that we collected from you with
                  information obtained from third parties (described above) in
                  order to update, expand and analyze our records, and provide
                  products and services that may be of interest to you.
                  <p className="privacy-p">
                    To prevent fraud or criminal activity, misuses of our
                    products or services, and ensure the security of our IT
                    systems, architecture and networks.
                  </p>
                  <p className="privacy-p">
                    To (a) comply with legal obligations and legal process, (b)
                    respond to requests from public and government authorities
                    including public and government authorities outside your
                    country of residence; (c) enforce our terms and conditions;
                    (d) protect our operations; (e) protect our rights, privacy,
                    safety or property, and/or that of you or others; and (f)
                    allow us to pursue available remedies or limit the damages
                    that we may sustain.
                  </p>
                  <p className="privacy-p">
                    If you ask us to delete your data and we are required to
                    fulfil your request, to keep basic data to identify you and
                    prevent further unwanted process
                  </p>
                  For individuals in the EU, please see the “EU Individuals”
                  section below for information on our legitimate interests and
                  your rights. If Company bench Enterprises Inc. intends on
                  using any Personal Data in any manner that is not consistent
                  with this Privacy Policy, you will be informed of such
                  anticipated use prior to or at the time at which the Personal
                  Data is collected and pursuant to the applicable law.
                </p>
                <h2 className="privacy-h2">
                  Non-Identifiable or Aggregated Data
                </h2>
                <p className="privacy-p">
                  When you interact with Company bench Enterprises Inc. through
                  the Service, we receive and store certain personally
                  non-identifiable information. Such information, which is
                  collected passively using various technologies, cannot
                  presently be used to specifically identify you. Company
                  benchEnterprises Inc. may store such information itself or
                  such information may be included in databases owned and
                  maintained by Company benchEnterprises Inc. affiliates, agents
                  or service providers. This Service may use such information
                  and pool it with other information to track, for example, the
                  total number of users of our Service, the number of hours
                  worked using our Service, the location of our users, and how
                  our users use and interact with the Service. Also, in an
                  ongoing effort to better understand and serve the users of the
                  Service, Company benchEnterprises Inc. often conducts research
                  on its customer demographics, interests and behavior based on
                  the Personal Data and other information provided to us. This
                  research may be compiled and analyzed on an aggregate basis.
                  Company benchEnterprises Inc. may share this non-identifiable
                  and aggregate data with its affiliates, agents and business
                  partners, but this type of non-identifiable and aggregate
                  information does not identify you personally. Company
                  benchEnterprises Inc. may also disclose aggregated user
                  statistics in order to describe our Service to current and
                  prospective business partners, and to other third parties for
                  other lawful purposes.
                </p>
                <h2 className="privacy-h2">Information Collected</h2>
                <p className="privacy-p">
                  Wherever Company bench Enterprises Inc. collects Personal Data
                  we make an effort to provide a link to this Privacy Policy. In
                  addition, and without limiting the disclosures herein, Company
                  benchEnterprises Inc. may receive, store or otherwise use your
                  Personal Data, including your activity usage as an individual
                  contributor to a project on the Service, and provide reports
                  containing such Personal Data to the designated project
                  managers of the projects within the Service upon which you
                  work.
                </p>
                <p className="privacy-p">
                  BY VOLUNTARILY PROVIDING US WITH PERSONAL DATA, YOU ARE
                  CONSENTING TO OUR USE OF IT IN ACCORDANCE WITH THIS PRIVACY
                  POLICY. IF YOU PROVIDE PERSONAL DATA, YOU ACKNOWLEDGE AND
                  AGREE THAT SUCH PERSONAL DATA MAY BE TRANSFERRED FROM YOUR
                  CURRENT LOCATION TO THE OFFICES AND SERVERS OF Company
                  benchEnterprises Inc. AND THE AUTHORIZED THIRD PARTIES
                  REFERRED TO HEREIN LOCATED IN THE UNITED STATES.
                </p>
                <h2 className="privacy-h2">
                  Our Disclosure of Your Personal Data and Other Information
                </h2>
                <p className="privacy-p">
                  Company bench Enterprises Inc. is not in the business of
                  selling your information. We consider this information to be a
                  vital part of our relationship with you. There are, however,
                  certain circumstances in which we may share your Personal Data
                  with certain third parties without further notice to you, as
                  set forth below:
                </p>
                <p className="privacy-p">Business Transfers</p>
                <p className="privacy-p">
                  As we develop our business, we might sell or buy businesses or
                  assets. In the event of a corporate sale, merger,
                  reorganization, dissolution or similar event, Personal Data
                  may be part of the transferred assets.
                </p>
                <p className="privacy-p">Related Companies</p>
                <p className="privacy-p">
                  We may also share your Personal Data with our affiliates and
                  related companies for purposes consistent with this Privacy
                  Policy.
                </p>
                <p className="privacy-p">
                  Agents, Consultants and Related Third Parties
                </p>
                <p className="privacy-p">
                  Company bench Enterprises Inc., like many businesses,
                  sometimes hires other companies to perform certain
                  business-related functions. Examples of such functions include
                  mailing information, maintaining databases, billing and
                  processing payments. When we employ another company to perform
                  a function of this nature, we only provide them with the
                  information that they need to perform their specific function.
                </p>
                <p className="privacy-p">Legal Requirements</p>
                <p className="privacy-p">
                  Company bench Enterprises Inc. may disclose your Personal Data
                  if required to do so by law or in the good faith belief that
                  such action is necessary to (i) comply with a legal
                  obligation, (ii) protect and defend the rights or property of
                  Company bench, (iii) act in urgent circumstances to protect
                  the personal safety of users of the Service or the public, or
                  (iv) protect against legal liability.
                </p>
                <h2 className="privacy-h2">EU Individuals</h2>
                <p className="privacy-p">Scope</p>
                <p className="privacy-p">
                  This section applies solely to individuals in the EU (for
                  these purposes, reference to the EU also includes the European
                  Economic Area countries of Iceland, Liechtenstein and Norway
                  and, where applicable, Switzerland). Our Privacy Policy
                  describes why and how Company benchEnterprises Inc. collects,
                  uses and stores your Personal Data, the lawful basis on which
                  your Personal Data is processed, and what your rights and our
                  obligations are in relation to such processing (please see
                  “Your Rights” section below).
                </p>
                <p className="privacy-p">Data Controller</p>
                <p className="privacy-p">
                  Company bench Enterprises Inc. is the data controller for
                  processing your Personal Data. The data controller is
                  responsible for deciding how Personal Data about you is used.
                  Please see the “Contacting Company bench” section below to
                  find out how to contact us, which also provides the contact
                  details of our representative in the EU for purposes of the
                  General Data Protection Regulation.
                </p>
                <p className="privacy-p">Your Rights</p>
                <p className="privacy-p">
                  Subject to applicable EU law, you have the following rights in
                  relation to your Personal Data: Right of access. If you ask
                  us, we will confirm whether we are processing your Personal
                  Data and, if so, provide you with a copy of that Personal Data
                  along with certain other details. If you require additional
                  copies, we may need to charge a reasonable fee.
                </p>
                <p className="privacy-p">
                  Right to rectification. If your Personal Data is inaccurate or
                  incomplete, you are entitled to ask that we correct or
                  complete it. If we shared your Personal Data with others, we
                  will tell them about the correction where possible. If you ask
                  us, and where possible and lawful to do so, we will also tell
                  you with whom we shared your Personal Data so you can contact
                  them directly.
                </p>
                <p className="privacy-p">
                  Right to erasure. You may ask us to delete or remove your
                  Personal Data, such as where you withdraw your consent. If we
                  shared your data with others, we will tell them about the
                  erasure where possible. If you ask us, and where possible and
                  lawful to do so, we will also tell you with whom we shared
                  your Personal Data with so you can contact them directly.
                </p>
                <p className="privacy-p">
                  Right to restrict processing. You may ask us to restrict or
                  ‘block’ the processing of your Personal Data in certain
                  circumstances, such as where you contest the accuracy of the
                  data or object to us processing it. We will tell you before we
                  lift any restriction on processing. If we shared your Personal
                  Data with others, we will tell them about the restriction
                  where possible. If you ask us, and where possible and lawful
                  to do so, we will also tell you with whom we shared your
                  Personal Data so you can contact them directly.
                </p>
                <p className="privacy-p">
                  Right to data portability. You have the right to obtain your
                  Personal Data from us that you consented to give us or that
                  was provided to us as necessary in connection with our
                  contract with you. We will give you your Personal Data in a
                  structured, commonly used and machine-readable format. You may
                  reuse it elsewhere.
                </p>
                <p className="privacy-p">
                  Right to object. You may ask us at any time to stop processing
                  your Personal Data, and we will do so: If we are relying on a
                  legitimate interest to process your Personal Data -- unless we
                  demonstrate compelling
                </p>
                <p className="privacy-p">
                  If we are processing your Personal Data for direct marketing.
                </p>
                <p className="privacy-p">
                  Rights in relation to automated decision-making and profiling.
                  You have the right to be free from decisions based solely on
                  automated processing of your Personal Data, including
                  profiling, which produce a significant legal effect on you,
                  unless such profiling is necessary for entering into, or the
                  performance of, a contract between you and us, or with your
                  explicit consent. We are not currently processing your
                  Personal Data for such type of automated decision-making,
                  including profiling, but if we elect to do so in the future we
                  will provide you with notice and choice, in accordance with EU
                  data protection law.
                </p>
                <p className="privacy-p">
                  Right to withdraw consent. If we rely on your consent to
                  process your Personal Data, you have the right to withdraw
                  that consent at any time, but this will not affect any
                  processing of your data that has already taken place.
                </p>
                <p className="privacy-p">
                  Right to lodge a complaint with the data protection authority.
                  If you have a concern about our privacy practices, including
                  the way we handled your Personal Data, you can report it to
                  the data protection authority that is authorized to hear those
                  concerns.
                </p>
                <h2 className="privacy-h2">
                  You may exercise your rights by contacting us as indicated
                  under “Contacting Company bench” section below
                </h2>
                <p className="privacy-p">Legitimate Interest “</p>
                <p className="privacy-p">
                  Legitimate interests” means our interests in conducting and
                  managing our organization and delivering the best Services to
                  you. This Privacy Policy describes when we process your
                  Personal Data for our legitimate interests, what these
                  interests are and your rights. We will not use your Personal
                  Data for activities where the impact on you overrides our
                  interests, unless we have your consent or those activities are
                  otherwise required or permitted by law. You have the right to
                  object to processing that is based on our legitimate
                  interests. For more information on your rights, please see
                  “Your Rights” section above.
                </p>
                <p className="privacy-p">Data Transfers</p>
                <p className="privacy-p">
                  Company bench is based in the United States. When you apply as
                  an Engineer or use our services as a Client, or otherwise use
                  our Service, your Personal Data will be transmitted to servers
                  in the United States as necessary to provide you with the
                  services that you requested, administer our contract with you
                  or to respond to your requests as described in this Privacy
                  Policy, and the data may be transmitted to our service
                  providers supporting our business operations (described
                  above). The United States may have data protection laws less
                  stringent than or otherwise different from the laws in effect
                  in the country in which you are located. Where we transfer
                  your Personal Data out of the European Economic Area (EEA) we
                  will take steps to ensure that your Personal Data receives an
                  adequate level of protection where it is processed and your
                  rights continue to be protected.
                </p>
                <p className="privacy-p">Data Retention</p>
                <p className="privacy-p">
                  Our policy is to keep your Personal Data only for as long as
                  is reasonably necessary to fulfil the purposes for which it
                  was collected and processed, including for the purposes of
                  satisfying any legal, regulatory, accounting or reporting
                  requirements. If you have elected to receive marketing
                  communications from us, we retain information about your
                  marketing preferences until you opt out of receiving these
                  communications and in accordance with our policies. To
                  determine the appropriate retention period for your Personal
                  Data, we will consider the amount, nature, and sensitivity of
                  the Personal Data, the potential risk of harm from
                  unauthorized use or disclosure of your Personal Data, the
                  purposes for which we use your Personal Data and whether we
                  can achieve those purposes through other means, and the
                  applicable legal requirements. In some circumstances we may
                  anonymize your Personal Data so that it can no longer be
                  associated with you, in which case it is no longer Personal
                  Data.
                </p>
                <h2 className="privacy-h2">Your Choices</h2>
                <p className="privacy-p">
                  You can use the Service without providing any Personal Data.
                  If you choose not to provide any Personal Data, you may not be
                  able to use certain features of the Service.
                </p>
                <h2 className="privacy-h2">Exclusions</h2>
                <p className="privacy-p">
                  This Privacy Policy does not apply to any Personal Data
                  collected by Company benchEnterprises Inc. other than Personal
                  Data collected through the Service. This Privacy Policy shall
                  not apply to any unsolicited information you provide to
                  Company benchthrough this Service or through any other means.
                  This includes, but is not limited to any ideas for new
                  products or modifications to existing products, and other
                  unsolicited submissions (collectively, “Unsolicited
                  Information”). All Unsolicited Information shall be deemed to
                  be non-confidential and Company benchEnterprises Inc. shall be
                  free to reproduce, use, disclose, distribute and exploit such
                  Unsolicited Information without limitation or attribution.
                </p>
                <h2 className="privacy-h2">Children</h2>
                <p className="privacy-p">
                  Company bench Enterprises Inc. does not knowingly collect
                  Personal Data from children under the age of 13. If you are
                  under the age of 13, please do not submit any Personal Data
                  through the Service. We encourage parents and legal guardians
                  to monitor their children’s Internet usage and to help enforce
                  our Privacy Policy by instructing their children never to
                  provide Personal Data on this Service without their
                  permission. If you have reason to believe that a child under
                  the age of 13 has provided Personal Data to Company
                  benchEnterprises Inc. through the Service, please contact us,
                  and we will endeavor to delete that information from our
                  databases.
                </p>
                <h2 className="privacy-h2">
                  Company benchGDPR Notice for Non-developer Job Applicants
                </h2>
                <p className="privacy-p">
                  This notice applies to applicants from the European Union.
                  When you apply to a job with Company bench Enterprises, Inc.
                  on this site, the personal data contained in your application,
                  and any other personal data that you provide in connection
                  with your application, will be collected by Company
                  benchEnterprises Inc. (“Controller”), which is located at 1900
                  Embarcadero Road Palo Alto, CA, 94303 USA. The Controller can
                  be contacted by emailing privacy@Company bench.com. Your
                  personal data will be processed for the purposes of managing
                  Controller’s recruitment related activities, which include
                  communicating with you, setting up and conducting interviews,
                  assessments, and tests for applicants, evaluating and
                  assessing the results thereto, and as is otherwise needed in
                  the recruitment and hiring processes. Such processing is
                  legally permissible under Art. 6(1)(f) of Regulation (EU)
                  2016/679 (GDPR) as necessary for the purposes of the
                  legitimate interests pursued by the Controller, which are the
                  solicitation, evaluation, and selection of applicants for
                  employment.
                </p>
                <p className="privacy-p">
                  Your personal data will be shared with Greenhouse Software,
                  Inc., a cloud services provider located in the United States
                  of America and engaged by Controller to help manage its
                  recruitment and hiring process on Controller’s behalf.
                  Accordingly, if you are located outside of the United States,
                  your personal data will be transferred to the United States
                  once you submit it through this site. Because the European
                  Union Commission has determined that United States data
                  privacy laws do not ensure an adequate level of protection for
                  personal data collected from EU data subjects, the transfer
                  will be subject to appropriate additional safeguards under the
                  standard contractual clauses. You can obtain a copy of the
                  standard contractual clauses by contacting us at
                  privacy@Company bench.com.
                </p>
                <p className="privacy-p">
                  Your personal data will be retained by Controller for a
                  maximum of two years (unless you are hired by Controller, in
                  which case it will be retained for the duration of your
                  employment and thereafter in accordance with applicable law).
                  Under the GDPR, you have the right to request access to your
                  personal data, to request that your personal data be rectified
                  or erased, and to request that processing of your personal
                  data be restricted. You also have to right to data
                  portability. In addition, you may lodge a complaint with an EU
                  supervisory authority. Please note that if you do not provide
                  your personal data to us, we will not be able to evaluate your
                  application.
                </p>
                <p className="privacy-p">
                  If you have any questions or comments about this notice,
                  please contact us at privacy@Company bench.com.
                </p>
                <h2 className="privacy-h2">
                  Company benchGDPR Notice for Non-developer Job Applicants
                </h2>
                <p className="privacy-p">
                  This notice applies to applicants from the European Union.
                  When you apply to a job with Company bench Enterprises, Inc.
                  on this site, the personal data contained in your application,
                  and any other personal data that you provide in connection
                  with your application, will be collected by Company
                  benchEnterprises Inc. (“Controller”), which is located at 1900
                  Embarcadero Road Palo Alto, CA, 94303 USA. The Controller can
                  be contacted by emailing privacy@Company bench.com. Your
                  personal data will be processed for the purposes of managing
                  Controller’s recruitment related activities, which include
                  communicating with you, setting up and conducting interviews,
                  assessments, and tests for applicants, evaluating and
                  assessing the results thereto, and as is otherwise needed in
                  the recruitment and hiring processes. Such processing is
                  legally permissible under Art. 6(1)(f) of Regulation (EU)
                  2016/679 (GDPR) as necessary for the purposes of the
                  legitimate interests pursued by the Controller, which are the
                  solicitation, evaluation, and selection of applicants for
                  employment.
                </p>
                <p className="privacy-p">
                  Your personal data will be shared with Greenhouse Software,
                  Inc., a cloud services provider located in the United States
                  of America and engaged by Controller to help manage its
                  recruitment and hiring process on Controller’s behalf.
                  Accordingly, if you are located outside of the United States,
                  your personal data will be transferred to the United States
                  once you submit it through this site. Because the European
                  Union Commission has determined that United States data
                  privacy laws do not ensure an adequate level of protection for
                  personal data collected from EU data subjects, the transfer
                  will be subject to appropriate additional safeguards under the
                  standard contractual clauses. You can obtain a copy of the
                  standard contractual clauses by contacting us at
                  privacy@Company bench.com.
                </p>
                <p className="privacy-p">
                  Your personal data will be retained by Controller for a
                  maximum of two years (unless you are hired by Controller, in
                  which case it will be retained for the duration of your
                  employment and thereafter in accordance with applicable law).
                  Under the GDPR, you have the right to request access to your
                  personal data, to request that your personal data be rectified
                  or erased, and to request that processing of your personal
                  data be restricted. You also have to right to data
                  portability. In addition, you may lodge a complaint with an EU
                  supervisory authority. Please note that if you do not provide
                  your personal data to us, we will not be able to evaluate your
                  application.
                </p>
                <p className="privacy-p">
                  If you have any questions or comments about this notice,
                  please contact us at privacy@Company bench.com.
                </p>
                <h2 className="privacy-h2">Security</h2>
                <p className="privacy-p">
                  Company bench Enterprises Inc. takes reasonable steps to
                  protect the Personal Data provided via the Service from loss,
                  misuse, and unauthorized access, disclosure, alteration, or
                  destruction. However, no Internet, email or other electronic
                  transmission is ever fully secure or error free, so you should
                  take special care in deciding what information you send to us
                  in this way.
                </p>
                <h2 className="privacy-h2">
                  Changes to Company bench Enterprises Inc.’s Privacy Policy
                </h2>
                <p className="privacy-p">
                  The Service and our business may change from time to time. As
                  a result, at times it may be necessary for Company bench
                  Enterprises Inc. to make changes to this Privacy Policy.
                  Company benchEnterprises Inc. reserves the right to update or
                  modify this Privacy Policy at any time and from time to time
                  without prior notice. Please review this policy periodically,
                  and especially before you provide any Personal Data. This
                  Privacy Policy was last updated on the date indicated above.
                  Your continued use of the Service after any changes or
                  revisions to this Privacy Policy shall indicate your agreement
                  with the terms of such revised Privacy Policy.
                </p>
                <h2 className="privacy-h2">
                  Contacting Company bench Enterprises Inc
                </h2>
                <p className="privacy-p">
                  Please contact us if you have any questions about Company
                  bench Enterprises Inc.’s Privacy Policy or the information
                  practices of this Service. <br />
                  {/* </p> */}
                  {/* <p className="privacy-p"> */}
                  You may contact us as follows: by email at
                  partnerships@Company bench.com, or by post at:
                  {/* </p> */}
                  {/* <p className="privacy-p"> */}
                  Company benchEnterprises, Inc. Attention: Company bench
                  Enterprises Inc. Privacy Policy Query 1900 Embarcadero Rd.
                  #104 Palo Alto, CA 94303.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
}

export default Privacy;
