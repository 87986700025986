import { Category } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Col } from "reactstrap";

const FilterSidebar = ({
  onSearchChange,
  onDateChange,
  onExperienceChange,
  onCategoriesChange,
  onMultiSelectChange,
}) => {
  const [localSearchValue, setLocalSearchValue] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [overallExperience, setOverallExperience] = useState("");
  const [categories, setCategories] = useState("");
  const [multiSelect, setMultiSelect] = useState([]);

  const loc = useLocation();
  const selected_Category = loc.state ? loc.state.category.job_function : null;

  // console.log(selected_Category);
  // Local Search bar handler
  const handleSearchInputChange = (e) => {
    // console.log(e);
    setLocalSearchValue(e.target.value);
    // console.log(localSearchValue);
  };

  // Effect for above search handler
  useEffect(() => {
    // console.log(localSearchValue);
    onSearchChange(localSearchValue);
  }, [localSearchValue]);

  // Job Posted Date Handler
  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);

    // Log the selectedDate before calling the callback
    // Note IMP: This console prints the previous selectedDate
    // console.log("Selected Date in FilterSidebar:", selectedDate);
  };

  // Effect for above Date Handler
  useEffect(() => {
    // This is console prints new selectedDate
    // console.log(selectedDate);
    onDateChange(selectedDate);
  }, [selectedDate]);

  // Experience Date Handler
  const handleOverallExperienceChange = (e) => {
    // console.log(e);
    setOverallExperience(e.target.value);

    // console.log("Overall Experience in FilterSidebar:", overallExperience);
  };

  // Effect for above Experience Handler
  useEffect(() => {
    // This is console prints new overallExperience
    // console.log(overallExperience);
    onExperienceChange(overallExperience);
  }, [overallExperience]);

  // Categories Handler
  const handleCategoriesChange = (e) => {
    // console.log("Category in FilterSidebar:", e.target.value);

    setCategories(e.target.value);
  };

  useEffect(() => {
    // console.log(categories);
    onCategoriesChange(categories);
  }, [categories]);

  // Multi Select Handler
  const handleMultiSelectChange = (e) => {
    console.log("Job Type (MultiSelect) in FilterSidebar:", e.target.value);
    const multiSelectedValue = e.target.value;
    setMultiSelect((prevMultiSelect) => {
      if (prevMultiSelect.includes(multiSelectedValue)) {
        return prevMultiSelect.filter((item) => item !== multiSelectedValue);
      } else {
        return [...prevMultiSelect, multiSelectedValue];
      }
    });
  };

  useEffect(() => {
    // console.log(multiSelect);
    onMultiSelectChange(multiSelect);
  }, [multiSelect]);

  const postDate = [
    { id: 1, date: "Last Hour", label: "lastHour" },
    { id: 2, date: "Last 24 hours", label: "last24Hours" },
    { id: 3, date: "Last 7 days", label: "last7Days" },
    { id: 4, date: "Last 14 days", label: "last14Days" },
    { id: 5, date: "Last 30 days", label: "last30Days" },
  ];

  const JobType = [
    { id: 1, jobType: "Contract Base", label: "Contract Base" },
    { id: 2, jobType: "Freelancer", label: "Freelancer" },
    { id: 3, jobType: "Full-time", label: "Full Time" },
    { id: 4, jobType: "Part-time", label: "Part Time" },
    { id: 5, jobType: "Remote", label: "Remote" },
    { id: 6, jobType: "Intership", label: "Internship" },
  ];
  const experience = [
    { id: 1, reqexperiance: "Fresher", label: "fresher" },
    { id: 2, reqexperiance: "1+ Year", label: 1 },
    { id: 3, reqexperiance: "2+ Year", label: 2 },
    { id: 4, reqexperiance: "3+ Year", label: 3 },
    { id: 5, reqexperiance: "4+ Year", label: 4 },
  ];
  return (
    <React.Fragment>
      <Col lg={4} md={6} xs={12} className="">
        <div className="sidebar bg-overlay-white-color p-4 rounded shadow-md">
          <div className="widget">
            <form role="search" method="get">
              <div className="input-group mb-3 border rounded">
                <input
                  type="text"
                  id="s"
                  name="s"
                  className="form-control border-0"
                  placeholder="Search Jobs..."
                  onChange={handleSearchInputChange}
                  value={localSearchValue}
                />
                {/* <button
                      type="submit"
                      className="input-group-text bg-transparent border-0"
                      id="searchsubmit"
                    >
                      <i className="uil uil-search"></i>
                    </button> */}
              </div>
            </form>
          </div>

          <div className="widget mb-4 pb-4 border-bottom">
            <h5 className="widget-title">Categories</h5>
            <div className="mt-4 mb-0">
              <select
                className="form-select form-control"
                aria-label="Default select example"
                onChange={handleCategoriesChange}
              >
                <option defaultValue>All Jobs</option>
                <option value="Software Engineer">Software Engineer</option>
                <option value="Salesforce Administrator">
                  Salesforce Administrator
                </option>
                <option value="Software Development">
                  Software Development
                </option>
                <option value="Salesforce Developer">
                  Salesforce Developer
                </option>
                <option value="IT">IT</option>
                <option value="Salesforce Architect">
                  Salesforce Architect
                </option>
                <option value="Sales">Sales</option>
                <option value="Business Analyst">Business Analyst</option>
                <option value="Salesforce Consultant">
                  Salesforce Consultant
                </option>
                <option value="Information Technology">
                  Information Technology
                </option>
                <option value="Software Engineering">
                  Software Engineering
                </option>
                <option value=">Marketing">Marketing</option>
                <option value="Product Management">Product Management</option>
                <option value="Engineering">Engineering</option>
                <option value="Application Developer">
                  Application Developer
                </option>
                <option value="Salesforce Business Analyst">
                  Salesforce Business Analyst
                </option>
                <option value="Solution Architect">Solution Architect</option>
                <option value="Data Analyst">Data Analyst</option>
                <option value="Business Systems Analyst">
                  Business Systems Analyst
                </option>
                <option value="Technical Architect">Technical Architect</option>
              </select>
            </div>
          </div>

          <div className="widget mb-4 pb-4 border-bottom">
            <h5 className="widget-title">Date Posted</h5>
            <div className="mt-4">
              {postDate.map((item, key) => (
                <div key={key} className="form-check mb-0">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id={item.label}
                    onChange={handleDateChange}
                    // checked={selectedDate === item.label}
                    value={item.label} // Add this line
                  />
                  <label className="form-check-label" htmlFor={item.label}>
                    {item.date}
                  </label>
                </div>
              ))}
            </div>
          </div>

          <div className="widget mb-4 pb-4 border-bottom">
            <h5 className="widget-title">Job Type</h5>
            <div className="mt-4">
              {JobType.map((item, key) => (
                <div key={key} className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    // value=""
                    value={item.jobType}
                    id={item.label}
                    onChange={handleMultiSelectChange}
                  />
                  <label className="form-check-label" htmlFor={item.label}>
                    {item.label}
                  </label>
                </div>
              ))}
            </div>
          </div>

          <div className="widget">
            <h5 className="widget-title">Experience</h5>
            <div className="mt-4">
              {experience.map((item, key) => (
                <div key={key} className="form-check mb-0">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault2"
                    id={item.label}
                    onChange={handleOverallExperienceChange}
                    value={item.label}
                  />
                  <label className="form-check-label" htmlFor={item.label}>
                    {item.reqexperiance}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default FilterSidebar;
