import React, { useState, useEffect } from "react";
import { Container, Row } from "reactstrap";

//Import Components
import SectionTitle from "../../components/Shared/SectionTile";
import CategoryBox from "./CategoryBox";

//Import Images
import work1 from "../../assets/images/work/software-engineer-stock-2.jpg";
import work2 from "../../assets/images/work/salesforce-administrator-stock.jpg";
import work3 from "../../assets/images/work/software-development-stock.jpg";
import work4 from "../../assets/images/work/salesforce-developer-stock.png";
import work5 from "../../assets/images/work/it-stock.jpg";
import work6 from "../../assets/images/work/salesforce-architect-stock.jpg";
import work7 from "../../assets/images/work/business-analyst-stock.jpg";
import work8 from "../../assets/images/work/salesforce-consultant-stock.png";

import { api_get } from "../../services/service";
import { ep } from "../../config/dev";
const Categories = () => {
  const [jobFunctionCounts, setJobFunctionCounts] = useState([]);
  // useEffect(() => {
  //   api_get(
  //     null,
  //     ep.crawler + "/v1/joblisting",
  //     (_assessmentsesserror, _assessmentsessdata) => {
  //       if (!_assessmentsesserror) {
  //         const jobFunctionCount = [];

  //         _assessmentsessdata.joblistings.forEach((job) => {
  //           const jobFunction = job.job_function;
  //           if (jobFunction && jobFunction.length > 0) {
  //             const existingIndex = jobFunctionCount.findIndex(
  //               (obj) => obj.job_function === jobFunction
  //             );
  //             if (existingIndex !== -1) {
  //               // If the job function already exists in the array, increment the count
  //               jobFunctionCount[existingIndex].job_count++;
  //             } else {
  //               // If the job function doesn't exist, add a new object with count 1
  //               jobFunctionCount.push({
  //                 job_function: jobFunction,
  //                 job_count: 1,
  //               });
  //             }
  //           }
  //         });
  //         let jobSorted = jobFunctionCount.sort(
  //           (a, b) => b.job_count - a.job_count
  //         );
  //         setJobFunctionCounts(jobSorted);
  //       }
  //     }
  //   );

  //   // Output the job function counts
  // }, []);

  const jobCategories = [
    {
      id: 1,
      image: work1,
      title: "Software Engineering",
      job_function: "Software Engineer",
      jobs: 150,
      link: "",
      alt: "software engineering",
    },
    {
      id: 2,
      image: work2,
      title: "Salesforce Administrator",
      job_function: "Salesforce Administrator",
      jobs: 120,
      link: "",
      alt: "salesforce administrator",
    },
    {
      id: 3,
      image: work3,
      title: "Software Development",
      job_function: "Salesforce Administrator",
      jobs: 110,
      link: "",
      alt: "software development",
    },
    {
      id: 4,
      image: work4,
      title: "Salesforce Developer",
      job_function: "Salesforce Developer",
      jobs: 100,
      link: "",
      alt: "salesforce developer",
    },
    {
      id: 5,
      image: work5,
      title: "Information Technology",
      job_function: "Information Technology",
      jobs: 90,
      link: "",
      alt: "information technology",
    },
    {
      id: 6,
      image: work6,
      title: "Salesforce Architect",
      job_function: "Salesforce Architect",
      jobs: 75,
      link: "",
      alt: "salesforce architect",
    },
    {
      id: 7,
      image: work7,
      title: "Business Analyst",
      job_function: "Business Analyst",
      jobs: 60,
      link: "",
      alt: "business analyst",
    },
    {
      id: 8,
      image: work8,
      title: "Salesforce Consultant",
      job_function: "Salesforce Consultant",
      jobs: 50,
      link: "",
      alt: "salesforce consultant",
    },
  ];

  return (
    // <React.Fragment>
    //   <Container className="mb-5">
    //     {/* Section Title */}
    //     <Row className="justify-content-center pt-5 ">
    //       <SectionTitle
    //         className="col-6 text-center px-5"
    //         title="Popular Categories"
    //         desc=" that can provide everything you need to generate awareness, drive traffic, connect"
    //       />
    //     </Row>
    //     <Row>
    //       <CategoryBox jobCategories={jobFunctionCounts} />
    //     </Row>
    //   </Container>
    // </React.Fragment>

    <React.Fragment>
      <section className="bg-light">
        <Container
          className="pb-5 "
          style={{ paddingLeft: "20px", paddingRight: "20px" }}
        >
          {/* Section title */}
          {/* Before it was pt-5 */}
          <Row
            className="mb-0 justify-content-center"
            style={{ paddingTop: "30px" }}
          >
            <SectionTitle
              className="col-8 text-center"
              title="Popular Catagories"
              // style={{ marginBottom: "15px", paddingBottom: "5px" }}
              // desc=" that can provide everything you need to generate awareness, drive traffic, connect"
            />
          </Row>
          <Row style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            {/* render category box */}
            {/* <CategoryBox jobCategories={jobFunctionCounts} /> */}
            <CategoryBox jobCategories={jobCategories} />
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Categories;
