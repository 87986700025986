import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";

const JobSearchSection = ({ onKeywordChange }) => {
  const [searchValue, setSearchValue] = useState("");

  const handleBigSearch = (e) => {
    e.preventDefault();

    // console.log(e);
    // console.log("In Search Section:", searchValue);
    // onKeywordChange(e.target.value);
    // setSearchValue(e.target.value);
    onKeywordChange(searchValue);
  };

  return (
    <>
      <Container>
        <Row>
          <Col xs={12}>
            <div className="features-absolute">
              <form
                className="rounded p-4 mt-4 bg-white shadow"
                onSubmit={handleBigSearch}
              >
                <Row className="text-start">
                  <Col lg={10} md={10}>
                    <div className="mb-0">
                      <input
                        type="text"
                        className="form-control"
                        required
                        placeholder="Keywords"
                        // value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                      />
                    </div>
                  </Col>

                  <Col lg={2} md={2}>
                    <div className="col-md-4 mt-4 mt-sm-0">
                      <div className="d-grid">
                        <input
                          type="submit"
                          id="search"
                          name="search"
                          className="searchbtn btn btn-primary btn-lg"
                          // value="Search"
                        />
                      </div>
                    </div>
                    {/* <Row className="align-items-center">
                                        <Col md={4} className="mt-4 mt-sm-0">
                                            <div className="mb-0">
                                                <select className="form-control form-select">
                                                    <option defaultValue="">Location</option>
                                                    <option value="AF">Afghanistan</option>
                                                    <option value="AX">&Aring;land Islands</option>
                                                    <option value="AL">Albania</option>
                                                    <option value="DZ">Algeria</option>
                                                    <option value="AS">American Samoa</option>
                                                    <option value="AD">Andorra</option>
                                                    <option value="AO">Angola</option>
                                                    <option value="AI">Anguilla</option>
                                                    <option value="AQ">Antarctica</option>
                                                </select>
                                            </div>
                                        </Col>

                                    <div className="col-md-4 mt-4 mt-sm-0">
                                        <div className="mb-0">
                                            <select className="form-control form-select">
                                                <option defaultValue="">Job Types</option>
                                                <option value="4">Accounting</option>
                                                <option value="1">IT & Software</option>
                                                <option value="3">Marketing</option>
                                                <option value="5">Banking</option>
                                            </select>
                                        </div>
                                    </div>

                                </Row> */}
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default JobSearchSection;
