// React Basic and Bootstrap
import React, { Component } from "react";

// Import Generic Components
import Section from "./section";
import Partners from "./Partners";

import Faq from "./Faq";
import FeaturedJob2 from "./FeaturedJob2";
import FeaturedJobs from "./FeaturedJobs";
import Categories from "./Categories";
//import Topbar from "../../components/Layout/Topbar";
// import NavBar from "../../components/Layout/NavBar";
import Testimonial from "../../components/Layout/Testimonial";
import Video from "./Video";
import Stages from "./Stages";
import Journey from "./Journey";
import Score from "./Score";
// import AssessmentProcess from "./AssessmentProcess";
// import classnames from "classnames";
// import Popup from '../../components/Layout/popup';

class IndexMain extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.scrollNavigation = this.scrollNavigation.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.classList = "";
    // document.querySelector(".shoppingbtn").classList.add("btn-primary");
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, false);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add("nav-sticky");
      } else {
        navBar.classList.remove("nav-sticky");
        // document.querySelector(".shoppingbtn").classList.add("btn-primary");
        // document.querySelector(".shoppingbtn").classList.remove("btn-light");
        // document.querySelector(".settingbtn").classList.add("btn-soft-primary");
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* Navbar Section */}
        {/* <Topbar /> */}
        {/* <NavBar /> */}
        {/* Home Section */}
        <Section />

        {/* Partner */}

        {/* HowItWorks */}
        <Categories />
        {/* Testi */}
        {/* <FeaturedJobs /> */}
        <FeaturedJob2 />

        {/* <div className="position-relative">
          <div className="shape overflow-hidden text-light">
            <svg
              viewBox="0 0 2880 250"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M720 125L2160 0H2880V250H0V125H720Z"
                fill="currentColor"
              ></path>
            </svg>
            </div>
          </div> */}
        {/* Faq */}
        {/* <AssessmentProcess /> */}
        {/* <Faq /> */}
        <Video />
        <Stages />
        {/* <Journey />
        <Score /> */}
        <Partners />

        <section className="border-top">
          <Testimonial />
        </section>

        {/* <div className="position-relative">
          <div className="shape overflow-hidden text-footer">
          <svg
          viewBox="0 0 2880 250"
          fill="none"
          xmlns="http://www.w3.org/2000/svg" 
          >
          <path
          d="M720 125L2160 0H2880V250H0V125H720Z"
          fill="currentColor"
          ></path> 
          </svg>
          </div> 
        </div> */}

        {/* <Popup/> */}
      </React.Fragment>
    );
  }
}

export default IndexMain;
