import React, { useEffect, useState, useCallback } from "react";
// import NavBar from '../../components/Layout/NavBar';
import { Container, Row } from "reactstrap";

import JobHero from "./JobHero";
import FilterSidebar from "./FilterSidebar";
// import Sidebar from './Sidebar';
import JobList from "./JobList";
// import ListPage from './ListPage';
import JobSearchSection from "./JobSearchSection";
import { Helmet } from "react-helmet";
import JobList2 from "./JobList2";
// import SearchSection from './SearchSection';
// import BackToTop from '../../../../components/Layout/backToTop';

const Job = () => {
  const [searchValue, setSearchValue] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [overallExperience, setOverallExperience] = useState("");
  const [localSearchValue, setLocalSearchValue] = useState("");
  const [categories, setCategories] = useState("");
  const [multiSelect, setMultiSelect] = useState("");

  const scrollNavigation = useCallback(() => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add("nav-sticky");
      } else {
        navBar.classList.remove("nav-sticky");
        // document.querySelector(".shoppingbtn").classList.add("btn-primary");
        // document.querySelector(".shoppingbtn").classList.remove("btn-light");
        // document.querySelector(".settingbtn").classList.add("btn-soft-primary");
      }
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.classList = "";
    // document.querySelector(".settingbtn").classList.add("btn-light");
    window.addEventListener("scroll", scrollNavigation, true);

    return () => {
      window.removeEventListener("scroll", scrollNavigation, true);
    };
  }, [scrollNavigation]);

  // Big Search Bar
  const handleBigSearch = (newValue) => {
    setSearchValue(newValue);
  };

  //Callback function for Posted Job Date (FilterSidebar)
  const handleDateChange = (selectedDate) => {
    // Log the selectedDate when the callback is triggered
    // console.log("Selected Date in IndexJob:", selectedDate);
    setSelectedDate(selectedDate);
  };

  const handleOverallExperienceChange = (overallExperience) => {
    setOverallExperience(overallExperience);
  };

  const handleSearchInputChange = (localSearchValue) => {
    // console.log("Local Search Value in IndexJob", localSearchValue);
    setLocalSearchValue(localSearchValue);
  };

  const handleCategoriesChange = (categories) => {
    // console.log("Categories in IndexJob", categories);
    setCategories(categories);
  };

  const handleMultiSelectChange = (multiSelect) => {
    setMultiSelect(multiSelect.join(","));
    // console.log("MultiSelect in IndexJob", multiSelect);
  };

  console.log('overallExperience : ',overallExperience);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <JobHero />

      <section className="section">
        <JobSearchSection onKeywordChange={handleBigSearch} />

        <Container className="mt-5 mt-sm-0">
          <Row>
            <FilterSidebar
              // onBigSearchChange={handleSearchChange}
              onDateChange={handleDateChange}
              onExperienceChange={handleOverallExperienceChange}
              onSearchChange={handleSearchInputChange}
              onCategoriesChange={handleCategoriesChange}
              onMultiSelectChange={handleMultiSelectChange}
            />
            <JobList2
              // searchValue={searchValue}
              onBigSearchChange={searchValue}
              datePosted={selectedDate}
              oExperience={overallExperience}
              localSearchValue={localSearchValue}
              onCategoriesChange={categories}
              onMultiSelectChange={multiSelect}
            />
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Job;
