import React, { Component, Suspense, useState } from "react";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  useHistory,
  withRouter,
  Link,
} from "react-router-dom";

import "./assets/css/materialdesignicons.min.css";
import "./Apps.scss";

//import Header from "./common/Header";
import Topbar from "./components/Layout/Topbar";
// import NavBar from "./components/Layout/NavBar";
import Footer from "./components/Layout/Footer";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import routes from "./routes/allRoutes";
import AppContext from "./contexts/AppContext";

function App() {
  const history = useHistory();

  const [resumeResponse, setResumeResponse] = useState(null);
  return (
    <React.Fragment>
      <AppContext.Provider value={{ resumeResponse, setResumeResponse }}>
        <Router>
          {routes.some((route) => history.location.pathname === route.path) && (
            <Topbar />
          )}
          {/* Using Different Navbar for testing and debugging */}
          {/* <NavBar /> */}
          <Switch>
            {routes.map((route, idx) => (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            ))}
            <Route path="*" component={PageNotFound} />
          </Switch>
          {routes.some((route) => history.location.pathname === route.path) && (
            <Footer />
          )}
        </Router>
      </AppContext.Provider>
    </React.Fragment>
  );
}

export default App;
