import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Pagination, Autoplay, Navigation } from "swiper/modules";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

//import Images
import urbanladder from "../../assets/images/client/urban-ladder.png";
import jungleegames from "../../assets/images/client/junglee-games.png";
import landt from "../../assets/images/client/LandT.png";
import wns from "../../assets/images/client/wns.png";
import hdfc from "../../assets/images/client/HDFC.png";
import pine from "../../assets/images/client/Pine.png";

import "./css/demo.css";

//import Background from './css/businessimage.jpg';

// var sectionStyle = {
//   width: "100%",
//   height: "400px",
//   backgroundImage: "url(" + { Background } + ")"
// };

function Partners() {
  const partnersData = [
    {
      id: 1,
      img: urbanladder,
      style: { marginBottom: "40px", height: "150px", width: "150px" },
    },
    {
      id: 2,
      img: jungleegames,
      style: { height: "130px", width: "130px" },
    },
    {
      id: 3,
      img: landt,
      style: { marginTop: "25px", height: "70px", width: "140px" },
    },
    {
      id: 4,
      img: wns,
      style: { marginTop: "15px", height: "110px", width: "170px" },
    },
    {
      id: 5,
      img: hdfc,

      style: { marginTop: "45px", height: "40px", width: "170px" },
    },
    {
      id: 6,
      img: pine,
      style: { marginTop: "18px", height: "100px", width: "180px" },
    },
  ];
  return (
    <React.Fragment>
      <section className="py-3 border-bottom border-top categoryimages">
        <Container>
          <Row className="justify-content-center">
            <Col className="col-6 text-center py-3">
              <h2 className="text-dark"><strong>Our Partners</strong></h2>
            </Col>
          </Row>
          <Row className="justify-content-center pt-1 pb-2">
            <Col lg={12} className="text-center" style={{ height: "150px" }}>
              <Swiper
                // spaceBetween={10}
                // slidesPerView={3} // Adjust the number of logos visible at a time
                // navigation

                slidesPerView={1}
                breakpoints={{
                  750: {
                    slidesPerView: 2,
                  },
                  1020: {
                    slidesPerView: 4,
                  },
                }}
                //navigation={true}
                // pagination={true}
                className="mySwiper"
                modules={[Autoplay, Pagination, Navigation]}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                // speed={1000}
                loop={true}
                // pagination={{
                //   clickable: true,
                // }}
              >
                {/* {partnersData.map((item, key) => (
                <Col key={key} lg={2} md={2} className="col-6 text-center py-4">
                  <img src={item.img} className="avatar avatar-ex-sm" alt="" />
                </Col>
              ))} */}

                {partnersData.map((item, key) => (
                  <SwiperSlide key={key} className="col-6 text-center py-1">
                    <img
                      src={item.img}
                      style={item.style}
                      className="avatar avatar-sm"
                      alt=""
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default Partners;
