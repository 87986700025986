import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
// import { Nav, NavItem, NavLink } from "reactstrap";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";

import uploadResume from "../../assets/icons/img/upload-resume.jpg";
import multipleChoice from "../../assets/icons/img/Multiple-choice.jpg";
import showcaseTalent from "../../assets/icons/img/showcase-talent.jpg";
import assessmentImg from "../../assets/icons/img/video-based-assessment.jpg";

import "./css/demo.css";

const Stages = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 1:
        return (
          <>
            <Row className="align-items-center">
              <Col lg={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div className="section-title me-md-4">
                  <div className="title mb-4" align="left">
                    <strong>Upload Your Resume</strong>
                  </div>
                  <p className="text-muted" align="justify">
                    Your journey begins with showcasing your qualifications.
                    Upload your resume and let TalenIQ analyze your educational
                    background, work experience, and accomplishments. Our
                    advanced algorithm will align your resume with our tailored
                    assessment process, ensuring that your theoretical know-how
                    gets the recognition it deserves.
                  </p>
                </div>
              </Col>
              <Col lg={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                <img
                  src={uploadResume}
                  class="img-fluid mx-auto d-block shadow rounded"
                  alt="assessment process"
                  title="assessment process"
                />
              </Col>
            </Row>
          </>
        );
      case 2:
        return (
          <>
            <Row className="align-items-center">
              <Col lg={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                <img
                  src={multipleChoice}
                  class="img-fluid mx-auto d-block shadow rounded"
                  alt="assessment process"
                  title="assessment process"
                />
              </Col>
              <Col lg={6} className="mt-4 mt-md-4 mt-sm-0 pt-2 pt-sm-0">
                <div className="section-title me-md-4">
                  <div className="title mb-4" align="left">
                    <strong>Prove Your Theoretical Know-how</strong>
                  </div>
                  <p className="text-muted" align="justify">
                    Gone are the days of feeling underestimated. Our tailored
                    multiple-choice questions are designed to assess your
                    theoretical knowledge based on your resume. We believe that
                    what you know matters, and TalenIQ ensures your expertise
                    gets the attention it deserves.
                  </p>
                </div>
              </Col>
            </Row>
          </>
        );
      case 3:
        return (
          <>
            <Row className="align-items-center">
              <Col lg={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div className="section-title me-md-4">
                  <div className="title mb-4" align="left">
                    <strong>Showcase Your Practical Skills</strong>
                  </div>
                  <p className="text-muted" align="justify">
                    Let your skills shine through hands-on challenges that
                    mirror real-world tasks. TalenIQ's coding test is customized
                    to match the technical requirements of the job, giving you
                    the chance to showcase your practical abilities and stand
                    out from the crowd.
                  </p>
                </div>
              </Col>
              <Col lg={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                <img
                  src={showcaseTalent}
                  class="img-fluid mx-auto d-block shadow rounded"
                  alt="assessment process"
                  title="assessment process"
                />
              </Col>
            </Row>
          </>
        );
      case 4:
        return (
          <>
            <Row className="align-items-center">
              <Col lg={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                <img
                  src={assessmentImg}
                  class="img-fluid mx-auto d-block shadow rounded"
                  alt="assessment process"
                  title="assessment process"
                />
              </Col>
              <Col lg={6} className="mt-4 mt-md-4 mt-sm-0 pt-2 pt-sm-0">
                <div className="section-title me-md-4">
                  <div className="title mb-4" align="left">
                    <strong>Unveil Your Personality and Fit</strong>
                  </div>
                  <p className="text-muted" align="justify">
                    We understand that being the right fit for a company goes
                    beyond skills. Through video-based behavioral assessments,
                    TalenIQ lets your personality come alive. Showcasing your
                    communication style and cultural alignment, this step is
                    your opportunity to demonstrate the complete package you
                    bring to the table.
                  </p>
                </div>
              </Col>
            </Row>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div
      className="section pt-0 mt-4 pb-5"
      style={{ paddingLeft: "10px", paddingRight: "10px" }}
    >
      <Container>
        <div className="row justify-content-center">
          <Col lg={12} className="text-center">
            <div className="section-title mb-4 pb-2">
              <h4 className="title mb-4">
                Your Journey to Success Starts Here
              </h4>
              <p className="text-muted mb-0 mx-auto" align="justify">
                Join the ranks of Assessment individuals who've transformed
                their job search experience with TalenIQ skills assessment test.
                Through our <strong>4-step assessment process</strong>, we
                uncover your strengths in ways traditional interviews can't.
              </p>
            </div>
            <div className="row justify-content-center">
              <Col lg={10} md={12} sm={12} className="mt-4 pt-2 text-center">
                <Nav
                  justified
                  pills
                  className="flex-column flex-sm-row"
                  style={{ borderRadius: "5px" }}
                >
                  {/* <NavItem>
                    <NavLink active href="#">
                      <h6 className="mb-0">Step 1: Upload Resume</h6>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#">
                      <h6 className="mb-0">Step 2: Multiple-Choice </h6>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#">
                      <h6 className="mb-0">Step 3: Coding Test</h6>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#">
                      <h6 className="mb-0">Step 4: Video Assessment</h6>
                    </NavLink>
                  </NavItem> */}
                  {[1, 2, 3, 4].map((tabNumber) => (
                    <NavItem key={tabNumber} className="col-sm-12 col-md-12">
                      <NavLink
                        active={activeTab === tabNumber}
                        onClick={() => handleTabClick(tabNumber)}
                        style={{
                          height: "65px", // Adjust the height as per your requirement
                          borderRadius: "5px", // Adjust the border-radius as per your requirement
                          display: "flex", // Flex container
                          alignItems: "center", // Vertical centering
                          justifyContent: "center", // Horizontal centering
                        }}
                      >
                        <h6
                          className="mb-0 "
                          style={{
                            // paddingTop: "10px",
                            fontFamily: "Nunito, Sans-serif",
                            lineHeight: "1.4",
                            fontWeight: "600",
                            fontSize: "16px",
                            color:
                              activeTab === tabNumber ? "white" : "#212529",
                          }}
                        >
                          {`Step ${tabNumber}: ${
                            tabNumber === 1
                              ? "Upload Resume"
                              : tabNumber === 2
                              ? "Multiple-Choice"
                              : tabNumber === 3
                              ? "Coding Test"
                              : "Video Assessment"
                          }`}
                        </h6>
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              </Col>
            </div>
            <Row>
              <Col lg={12} className="mt-2 pt-2">
                <Row className="align-items-center">
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    className="mt-4 mt-sm-0 pt-2 pt-sm-0"
                  >
                    {renderTabContent()}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </div>
      </Container>
    </div>
  );
};

export default Stages;
