export const ep = {
  //  identity: "http://localhost:3004",
  identity: "https://qoyj2nxyal.execute-api.ap-south-1.amazonaws.com/latest",
  entity: "https://l08nloaza1.execute-api.ap-south-1.amazonaws.com/latest",
  assessment: "https://i8e43d5anj.execute-api.ap-south-1.amazonaws.com/latest",
  crawler: "https://l08nloaza1.execute-api.ap-south-1.amazonaws.com/latest",
  resumeparsing: "https://files.talentiq.app",
  webapp: "https://me.talentiq.app",
  testwebapp: "https://tiq-webapp-dev.netlify.app",
};

export const common = {
  app_version: "0.0.1",
  service_ref: account_prop("common.service_ref") || "02d45aaa",
  guid_format: "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx",
  short_guid_format: "xxxxxxxx",
  log_enabled: true,
};

function account_prop(prop_name) {
  let subdomain = window.location.origin.split(".")[0].split("//")[1];
  let acc_props = {};

  acc_props["me"] = { common: { service_ref: "02d45aaa" } };
  acc_props["account1"] = { common: { service_ref: "b8ilcgos" } };

  try {
    return acc_props[subdomain][prop_name.split(".")[0]][
      prop_name.split(".")[1]
    ];
  } catch (_prop_err) {
    return null;
  }
}

export const unhandled_error = {
  server_error: {
    code: "TIQ-UH-001",
    message:
      "There appears to be an error. Please reload your page, or contact customer service if the issue persists.",
  },
  traffic_error: {
    code: "TIQ-TF-001",
    message:
      "We're presently experiencing excessive traffic. Please try again later or contact customer support if the problem persists.",
  },
  insufficient_tokens_error: {
    code: "TIQ-INSFTK-001",
    message:
      "You do not have enough tokens to complete this action. Upgrade or contact customer service for more assistance..",
  },
  insufficient_privileges_error: {
    code: "TIQ-INSFPRV-001",
    message:
      "You do not have enough privileges to complete this action. Please contact your account administrator or reachout to customer service for more assistance..",
  },
};
