import React from "react";
import { Container, Row, Col } from "reactstrap";
import Dropzone from "../../components/Shared/Dropzone";

const Journey = () => {
  return (
    <div
      className="section pt-0 mt-2 pb-2"
      style={{ paddingLeft: "10px", paddingRight: "10px" }}
    >
      <Container>
        <div className="rounded bg-primary bg-gradient p-lg-5 p-4 pb-md-5">
          <Row className="align-items-end">
            <Col md={7}>
              <div class="section-title text-md-start text-center">
                <h4 class="title mb-3 text-white title-dark">
                  Are You Ready to Redefine Your Journey?
                </h4>{" "}
                <p
                  class="dd-white mb-0"
                  align="justify"
                  style={{ color: "white" }}
                >
                  Don't let your potential go unnoticed any longer. Join the
                  league of TalenIQ achievers who have secured their dream jobs
                  and elevated their careers. The time for playing small is
                  over; it's time to let your true talents shine.
                </p>
              </div>
            </Col>
            <Col
              md={5}
              className="mt-4 mt-sm-0"
              style={{
                display: "grid",
                placeItems: "center",
                marginBottom: "20px",
              }}
            >
              {/* <div class="text-md-end text-center">
                <a href="#" class="btn btn-light">
                  Take the Assessment
                </a>
              </div> */}
              <Dropzone />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Journey;
