export const diff_days = (from_date) => {
 // var diff = Math.abs(new Date() - from_date);
 var diff = Math.floor(from_date - new Date());
 return Math.floor(diff / 1000 / 60 / 60 / 24);
};

export const time_since = (date) => {
  var seconds = Math.floor((new Date() - new Date(date)) / 1000);
   
  var interval = seconds / 31536000;
  console.log('interval :: ',interval);
  if (interval > 1) {
   return Math.floor(interval) > 1 ? Math.floor(interval) + " years" : Math.floor(interval) + " year";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
      return Math.floor(interval) > 1 ? Math.floor(interval) + " months" : Math.floor(interval) + " month";
  }
  interval = seconds / 86400;
  if (interval > 1) {
   return Math.floor(interval) > 1 ? Math.floor(interval) + " days" : Math.floor(interval) + " day";
  }
  interval = seconds / 3600;
  if (interval > 1) {
      return Math.floor(interval) > 1 ? Math.floor(interval) + " hours" : Math.floor(interval) + " hour";
  }
  interval = seconds / 60;
  if (interval > 1) {
      return Math.floor(interval) > 1 ? Math.floor(interval) + " minutes" : Math.floor(interval) + " minute";
  }
  return Math.floor(seconds) + " seconds";
};

export const formatDate = (datestring) => {
 var dt = new Date(datestring);
 var month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
 return month[dt.getMonth()] + ", " + dt.getFullYear();
};

export const dateyyyymmdd_tohtml = function (date) {
 return "<span>" + date.split("T")[0] + "</span>";
};

export const validateStartEndDate = (startDate, endDate) => {
    if (startDate > endDate) {
      return false;
    }
    return true;
  };
