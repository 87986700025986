import React from "react";
import { Link } from "react-router-dom";
import { Col, Card, CardBody } from "reactstrap";
import work1 from "../../assets/images/work/1.jpg";

const CategoryBox = ({ jobCategories }) => {
  return (
    // <React.Fragment>
    //   {jobCategories.slice(0,8).map((category, key) => (
    //     <Col lg="3" md="6" xs="12" key={key} className="mt-4 pt-2">
    //       <Card className="categories overflow-hidden rounded shadow border-0">
    //         <img src={work1} className="img-fluid" alt="" />
    //         <CardBody>
    //           <ul className="list-unstyled d-flex justify-content-between mb-0">
    //             <li>
    //               <Link to="#" className="title h6 text-dark">
    //                 {category.job_function}
    //               </Link>
    //             </li>
    //             <li className="h6 mb-0 jobs">{category.job_count} Jobs</li>
    //           </ul>
    //         </CardBody>
    //       </Card>
    //     </Col>
    //   ))}
    // </React.Fragment>

    <React.Fragment>
      {jobCategories.slice(0, 8).map((category, key) => (
        <Col
          lg="3"
          md="6"
          xs="12"
          key={key}
          className="mt-4
         pt-2"
        >
          <Link to={{ pathname: "/jobs", state: { category: category } }}>
            <Card
              className="categories overflow-hidden rounded shadow border-0"
              style={{ height: "230px" }}
            >
              <img
                src={category.image}
                style={{ height: "146px" }}
                className="img-fluid"
                alt={category.alt}
              />
              <CardBody>
                <ul className="list-unstyled d-flex justify-content-between mb-0">
                  <li>
                    {/* <Link to="/jobs" className="title h6 text-dark"> */}
                    {category.title}
                    {/* </Link> */}
                  </li>
                  <li className="h6 mb-0 jobs">{category.jobs} Jobs</li>
                </ul>
              </CardBody>
            </Card>
          </Link>
        </Col>
      ))}
    </React.Fragment>
  );
};

export default CategoryBox;
