import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import CountUp from "react-countup";
import "../../assets/css/Style.css";
import { Helmet } from "react-helmet";
import Journey from "../Home/Journey";
import Score from "../Home/Score";

//Import Images
import aboutus from "../../assets/images/company/aboutus.jpg";
import about2 from "../../assets/images/company/about2.png";
import amazon from "../../assets/images/client/amazon.svg";
import google from "../../assets/images/client/google.svg";
import lenovo from "../../assets/images/client/lenovo.svg";
import paypal from "../../assets/images/client/paypal.svg";
import shopify from "../../assets/images/client/shopify.svg";
import spotify from "../../assets/images/client/spotify.svg";
// import WorkProcess from "../../components/Shared/WorkProcess";
// import ExperienceTeam from "../CorporateBusiness/ExperienceTeam";
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import Partners from "../Home/Partners";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

function About() {
  window.scrollTo(0, 0);
  const [partners] = useState([
    { id: 1, img: amazon },
    { id: 2, img: google },
    { id: 3, img: lenovo },
    { id: 4, img: paypal },
    { id: 5, img: shopify },
    { id: 6, img: spotify },
  ]);

  const scrollToRef = useRef(null);

  useEffect(() => {
    document.body.classList = "";
    document.querySelectorAll("#buyButton").forEach((navLink) => {
      navLink.classList.add("btn-soft-primary");
      // navLink.classList.remove('btn-soft-primary');
      // document.getElementById('top-menu').classList.add('nav-light');
    });

    const scrollNavigation = () => {
      var doc = document.documentElement;
      var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
      if (top > 80) {
        //   document.querySelector('.shoppingbtn').classList.remove('btn-light');
        //   document.querySelector('.shoppingbtn').classList.add('btn-primary');
        document.getElementById("topnav").classList.add("nav-sticky");
      } else {
        //   document.querySelector('.shoppingbtn').classList.remove('btn-primary');
        //   document.querySelector('.shoppingbtn').classList.add('btn-light');
        document.getElementById("topnav").classList.remove("nav-sticky");
      }
    };

    window.addEventListener("scroll", scrollNavigation, true);

    // Cleanup the event listener when the component unmounts
    return () => {
      // document.getElementById('top-menu').classList.remove('nav-light');
      window.removeEventListener("scroll", scrollNavigation, true);
    };
  }, []); // Empty dependency array means this effect runs once after the initial render

  const handleScrollButtonClick = () => {
    const journeyElement = document.getElementById("journeySection");
    const offsetTop = journeyElement.offsetTop + 900;
    console.log(offsetTop);
    window.scrollTo({
      top: offsetTop,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Helmet>
        <title>TalentIQ - Skills Assessment Test and Company Assessment</title>
        <meta
          name="description"
          content="Maximize your team's potential with TalentIQ's cutting Skills Assessment Test and gain strategic insights with our Company Assessment solutions. "
        />
      </Helmet>

      <section
        className="bg-half-100 d-table w-100"
        style={{
          background: `url(${aboutus})`,
          opacity: "0.9",
        }}
      >
        <div className="bg-overlay bg-overlay-white-color"></div>
        <Container>
          <Row className="mt-5 justify-content-center">
            <Col lg={12} className="text-center">
              <div className="pages-heading title-heading">
                <h2 className="title title-dark"> About Us </h2>
                {/* <p className="text-white-50 para-desc mb-0 mx-auto">Start working with Landrick that can provide everything you need to generate awareness, drive traffic, connect.</p> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <section
        className="section pt-4 pb-5"
        style={{ padding: "20px !important" }}
      >
        <Container>
          {/* <Row className="mt-2 mb-4 justify-content-center">
            <Col lg={12} className="text-center">
              <div className="pages-heading title-heading">
                <h1 className="title title-dark">
                  {" "}
                  Skills Assessment Test and Company Assessment{" "}
                </h1>
              </div>
            </Col>
          </Row> */}
          <Row className="mt-1 pt-1 align-items-center" id="counter">
            <Col md={6}>
              <img
                src={about2}
                className="img-fluid"
                alt="Company Assessment"
                title="Company Assessment"
              />
            </Col>

            <Col
              md={6}
              className="mt-4 pt-2 mt-sm-0 pt-sm-0"
              style={{ paddingLeft: "20px", paddingRight: "20px" }}
            >
              <div className="ms-lg-4">
                {/* <div className="d-flex mb-4">
                  <span className="text-primary h1 mb-0">
                    <span className="counter-value display-1 fw-bold">
                      <CountUp start={0} end={15} duration={12} />
                    </span>
                    +
                  </span>
                  <span className="h6 align-self-end ms-2">
                    Years <br /> Experience
                  </span>
                </div> */}
                <div className="section-title">
                  <h1 className="title mb-4">AI Powered Skills Assessment Test</h1>
                  <p className="text-muted" align="justify">
                    {" "}
                    <span className="text-primary fw-bold">TalentIQ</span> that
                    Imagine a world where your skills are not just words on
                    paper, but a powerful testament to your capabilities IT
                    Assessment. At TalenIQ, <b>Company Assessment</b> reshaped
                    the job-seeking landscape, recognizing the hurdles you
                    encounter. We know that showcasing your potential goes
                    beyond a traditional resume. That's why we've crafted a IT
                    Assessment Test, skills Assessment test that brings your
                    true talents to light, giving you the edge, you deserve.
                    <br></br> Imagine a world where your skills are not just
                    words on paper, but this platform is a powerful testament to
                    your capabilities. At TalenIQ, we've revolutionized the
                    job-seeking game by understanding the challenges you face.
                    <br></br>
                    We know that showcasing your potential goes beyond a
                    traditional resume. That's why we've crafted a Online
                    Assessment Test that brings your true talents to light,
                    giving you the edge, you deserve
                  </p>
                  <Link to="/contact" className="btn btn-primary mt-3">
                    Contact us
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        {/* <Container className="mt-4">
          <Row className="justify-content-center">
            <Swiper */}
        {/* // spaceBetween={10}
        // slidesPerView={3} // Adjust the number of logos visible at a time //
        navigation */}
        {/* slidesPerView={1}
              breakpoints={{
                750: {
                  slidesPerView: 2,
                },
                1020: {
                  slidesPerView: 4,
                },
              }} */}
        {/* //navigation={true}
              // pagination={true} */}
        {/* className="mySwiper"
              modules={[Autoplay, Pagination, Navigation]}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              // speed={1000}
              loop={true} */}
        {/* // pagination={{ */}
        {/* //   clickable: true,
              // }} */}
        {/* > */}
        {/* {partners.map((image, key) => (
                <SwiperSlide key={key} className="col-6 text-center py-4"> */}
        {/* <Col
                    lg={2}
                    md={2}
                    xs={6}
                    className="text-center pt-4"
                    key={key}
                  > */}
        {/* <img
                    src={image.img}
                    style={{ fontSize: "40px" }}
                    className="avatar avatar-ex-sm"
                    alt=""
                  /> */}
        {/* </Col> */}
        {/* </SwiperSlide>
              ))}
            </Swiper>
          </Row>
        </Container> */}
      </section>
      <section className="section bg-light pt-4">
        {/* Work process render */}
        <Container style={{ paddingLeft: "20px", paddingRight: "20px" }}>
          {/* <Row className="justify-content-center">
            <Col xs={12}>
              <div className="section-title text-center mb-2 pb-2">
                <h4 className="title mb-4">How do we work ?</h4>
                <p className="text-muted para-desc mx-auto mb-0">
                  Start working with{" "}
                  <span className="text-primary fw-bold">TalentIQ</span> that
                  can provide everything you need to generate awareness, drive
                  traffic, connect.
                </p>
              </div>
            </Col>
          </Row> */}

          {/* <Row>
            <Col md={4} className="mt-4 pt-2">
              <Card className="features feature-clean work-process bg-transparent process-arrow border-0 text-center">
                <div className="icons text-primary text-center mx-auto">
                  <i className="uil uil-presentation-edit d-block rounded h3 mb-0"></i>
                </div>

                <CardBody>
                  <h5 className="text-dark">Discussion</h5>
                  <p className="text-muted mb-0">
                    The most well-known dummy text is the 'Lorem Ipsum', which
                    is said to have originated
                  </p>
                </CardBody>
              </Card>
            </Col>

            <Col md={4} className="mt-md-5 pt-md-3 mt-4 pt-2">
              <Card className="card features feature-clean work-process bg-transparent process-arrow border-0 text-center">
                <div className="icons text-primary text-center mx-auto">
                  <i className="uil uil-airplay d-block rounded h3 mb-0"></i>
                </div>

                <CardBody>
                  <h5 className="text-dark">Strategy & Testing</h5>
                  <p className="text-muted mb-0">
                    Generators convallis odio, vel pharetra quam malesuada vel.
                    Nam porttitor malesuada.
                  </p>
                </CardBody>
              </Card>
            </Col>

            <Col md={4} className="mt-md-5 pt-md-5 mt-4 pt-2">
              <Card className="features feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
                <div className="icons text-primary text-center mx-auto">
                  <i className="uil uil-image-check d-block rounded h3 mb-0"></i>
                </div>

                <CardBody>
                  <h5 className="text-dark">Reporting</h5>
                  <p className="text-muted mb-0">
                    Internet Proin tempus odio, vel pharetra quam malesuada vel.
                    Nam porttitor malesuada.
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
        </Container>

        <Container className="mt-50 mt-md-4">
          <Row className="justify-content-center">
            <Col xs={12} id="journeySection">
              <Journey />
            </Col>
          </Row>
        </Container>

        <Container className="mt-4">
          <Row className="justify-content-center">
            <Col xs={12}>
              <Score onButtonClick={handleScrollButtonClick} />
            </Col>
          </Row>
        </Container>

        <div className=" bg-white pt-0 pb-0">
          <Container className="mt-50 ">
            <Row className="justify-content-center">
              <Col xs={12}>
                <Partners />
              </Col>
            </Row>
          </Container>
        </div>
        <Container
          className="mt-4"
          style={{ paddingLeft: "20px", paddingRight: "20px" }}
        >
          <Row className="align-items-end pb-4">
            <Col md={8}>
              <div className="section-title text-center text-md-start">
                {/* <h6 className="text-primary">Services</h6> */}
                <h4 className="title mb-4">What we do ?</h4>
                <p className="text-muted mb-0 para-desc">Participate in online assessment tests to showcase your skills and enhance your chances of securing featured jobs in your desired field.</p>
              </div>
            </Col>

            <Col md={4} className="mt-4 mt-sm-0">
              <div className="text-center text-md-end">
                <Link to="/business" className="text-primary h6">
                  See More{" "}
                  <i className="uil uil-angle-right-b align-middle"></i>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default About;
