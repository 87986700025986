import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row, Container, CloseButton } from "reactstrap";
import PropTypes from "prop-types";

// import Formik for validation
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

//Import Icons
import FeatherIcon from "feather-icons-react";

//import images
//import logodark from "../../assets/images/logo-dark.png";
import logodark from "../../assets/images/talentiq-logo.png";
import logolight from "../../assets/images/logo-light.png";
import { api_post } from "../../services/service";
import { ep } from "../../config/dev";
import { log_debug } from "../../helpers/logger";

//import contact from "../../assets/images/contact.svg";

//import { Link } from "react-router-dom";
import "../../assets/css/Style.css";

const SignupSidebar = ({ onClose }) => {
  const [candidateGuid, setCandidateGuid] = useState("");
  // const formik = useFormik({
  //   initialValues: {
  //     full_name: '',
  //     email_id: '',
  //     contact_no: '',
  //   },
  //   // Other Formik configuration goes here

  //   // Your custom handleChange function
  //   handleChange: (event) => {
  //     const { name, value } = event.target;

  //     // Update the candidateData object
  //     setCandidateData((prevData) => ({
  //       ...prevData,
  //       [name]: value,
  //     }));

  //     // Call Formik's default handleChange
  //     formik.handleChange(event);
  //   },
  // });

  // // Your candidateData state
  // const [candidateData, setCandidateData] = useState({
  //   full_name: '',
  //   email_id: '',
  //   contact_no: '',
  //   source: 'tiq-web-form',
  // });

  useEffect(() => {
    if (candidateGuid) {
      createResume(candidateGuid);
    }
  }, [candidateGuid]);

  const validationSchema = Yup.object().shape({
    full_name: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Invalid characters")
      .required("Required"),
    email_id: Yup.string().email("Invalid email address").required("Required"),
    contact_no: Yup.string()
      .matches(/^[0-9]+$/, "Invalid characters")
      .required("Required"),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);

    const candidateData = {
      full_name: values.full_name,
      email_id: values.email_id,
      contact_no: values.contact_no,
      source: "tiq-web-form",
    };
    const apiEndpoint = ep.assessment + "/v1/candidate";
    const requestBody = {
      candidate: candidateData,
    };
    api_post(null, apiEndpoint, requestBody, (error, responseData) => {
      if (error) {
        console.error("candidate error::", error);
      } else {
        log_debug(
          "SignupSidebar",
          "handleSubmit",
          "API response data :: " + JSON.stringify(responseData)
        );
        setCandidateGuid(responseData.candidate.guid);
        // createResume(responseData.candidate.guid);
      }
    });
  };

  function createResume() {
    const resumeData = {
      candidate_guid: candidateGuid,
    };
    const requestBody = {
      resume: resumeData,
    };

    api_post(
      null,
      ep.assessment + "/v1/resume",
      requestBody,
      (error, responseData) => {
        if (error) {
          console.error("resume error::", error);
        } else {
          log_debug(
            "SignupSidebar",
            "createResume",
            "API response data :: " + JSON.stringify(responseData)
          );
          window.location.href = `${ep.webapp}/#/assessmentsession-stepuserprofile?candidate_guid=${candidateGuid}`;
        }
      }
    );
  }

  // const onCloseRightBar = () => {
  //   onClose();
  // };

  return (
    <React.Fragment>
      <Container>
        <div className="offcanvas-header border-bottom ">
          <h5 id="offcanvasRightLabel" className="mb-0">
            <img src={logodark} height="45" className="light-version" alt="" />
            <img src={logolight} height="24" className="dark-version" alt="" />
          </h5>

          <CloseButton onClick={onClose} />
        </div>
        <div className="">
          <Row>
            <Col xs={12}>
              {/* <img
                  src={contact}
                  className="img-fluid d-block mx-auto"
                  style={{ maxWidth: "256px" }}
                  alt=""
                /> */}
              <Card className="border-0 mt-5" style={{ zIndex: "1" }}>
                <CardBody className="p-0">
                  {/* <form method="post" action="#" name="myForm"> */}
                  <p id="error-msg" className="mb-0"></p>
                  <div id="simple-msg"></div>

                  <Formik
                    initialValues={{
                      full_name: "",
                      email_id: "",
                      contact_no: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    <Form>
                      <Row>
                        <Col md={12}>
                          <div className="mb-3">
                            <label className="form-label">
                              Your Name <span className="text-danger">*</span>
                            </label>
                            <div className="form-icon position-relative">
                              <FeatherIcon
                                icon="user"
                                className="fea icon-sm icons"
                              />
                              <Field
                                name="full_name"
                                id="full_name"
                                type="text"
                                className="form-control ps-5"
                                placeholder="Name :"
                              />
                              <ErrorMessage
                                name="full_name"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        </Col>

                        <Col md={12}>
                          <div className="mb-3">
                            <label className="form-label">
                              Your Email <span className="text-danger">*</span>
                            </label>
                            <div className="form-icon position-relative">
                              <FeatherIcon
                                icon="mail"
                                className="fea icon-sm icons"
                              />
                              <Field
                                name="email_id"
                                id="email_id"
                                type="email"
                                className="form-control ps-5"
                                placeholder="Email :"
                              />
                              <ErrorMessage
                                name="email_id"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        </Col>

                        {/* Mobile Field */}
                        <Col xs={12}>
                          <div className="mb-3">
                            <label className="form-label">
                              Phone<span className="text-danger">*</span>
                            </label>
                            <div className="form-icon position-relative">
                              <FeatherIcon
                                icon="phone"
                                className="fea icon-sm icons"
                              />
                              <Field
                                name="contact_no"
                                id="contact_no"
                                type="tel"
                                className="form-control ps-5"
                                placeholder="Phone"
                              />
                              <ErrorMessage
                                name="contact_no"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        </Col>

                        <div className="col-lg-12 tx-12 mb-3">
                          By clicking <strong>Submit</strong> below you agree to
                          our <a href="/terms">Terms & Conditions</a> and{" "}
                          <a href="/privacy">Privacy Policy</a>
                        </div>
                        {/* Subject Field */}
                        {/* <Col xs={12}>
                          <div className="mb-3">
                            <label className="form-label">Subject</label>
                            <div className="form-icon position-relative">
                              <FeatherIcon
                                icon="book"
                                className="fea icon-sm icons"
                              />
                              <input
                                name="subject"
                                id="subject"
                                className="form-control ps-5"
                                placeholder="subject :"
                              />
                            </div>
                          </div>
                        </Col> */}

                        {/* Comments Section */}
                        {/* <Col xs={12}>
                          <div className="mb-3">
                            <label className="form-label">
                              Comments <span className="text-danger">*</span>
                            </label>
                            <div className="form-icon position-relative"> */}
                        {/* <FeatherIcon
                                icon="message-circle"
                                className="fea icon-sm icons clearfix"
                              /> */}
                        {/* <textarea
                                name="comments"
                                id="comments"
                                rows="4"
                                className="form-control"
                                placeholder="Message :"
                              ></textarea>
                            </div>
                          </div>
                        </Col> */}
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <div className="d-grid">
                            <button
                              type="text"
                              id="submit"
                              name="send"
                              className="btn btn-primary"
                            >
                              Submit
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </Formik>
                  {/* </form> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

        {/* <div className="offcanvas-footer p-4 border-top text-center mt-auto">
            <ul className="list-unstyled social-icon social mb-0">
              <li className="list-inline-item">
                <Link
                  to="//1.envato.market/landrickreactjs"
                  target="_blank"
                  rel="noreferrer"
                  className="rounded"
                >
                  <i
                    className="uil uil-shopping-cart align-middle"
                    title="Buy Now"
                  ></i>
                </Link>
              </li>{" "}
              <li className="list-inline-item">
                <Link
                  to="//dribbble.com/Themesbrand"
                  target="_blank"
                  rel="noreferrer"
                  className="rounded"
                >
                  <i
                    className="uil uil-dribbble align-middle"
                    title="dribbble"
                  ></i>
                </Link>
              </li>{" "}
              <li className="list-inline-item">
                <Link
                  to="//www.behance.net/Themesbrand"
                  target="_blank"
                  rel="noreferrer"
                  className="rounded"
                >
                  <i
                    className="uil uil-behance align-middle"
                    title="behance"
                  ></i>
                </Link>
              </li>{" "}
              <li className="list-inline-item">
                <Link
                  to="//www.facebook.com/Themesbrand"
                  target="_blank"
                  rel="noreferrer"
                  className="rounded"
                >
                  <i
                    className="uil uil-facebook-f align-middle"
                    title="facebook"
                  ></i>
                </Link>
              </li>{" "}
              <li className="list-inline-item">
                <Link
                  to="//www.instagram.com/Themesbrand/"
                  target="_blank"
                  rel="noreferrer"
                  className="rounded"
                >
                  <i
                    className="uil uil-instagram align-middle"
                    title="instagram"
                  ></i>
                </Link>
              </li>{" "}
              <li className="list-inline-item">
                <Link
                  to="//twitter.com/Themesbrand"
                  target="_blank"
                  rel="noreferrer"
                  className="rounded"
                >
                  <i
                    className="uil uil-twitter align-middle"
                    title="twitter"
                  ></i>
                </Link>
              </li>{" "}
              <li className="list-inline-item">
                <Link to="mailto:support@Themesbrand.in" className="rounded">
                  <i
                    className="uil uil-envelope align-middle"
                    title="email"
                  ></i>
                </Link>
              </li>{" "}
              <li className="list-inline-item">
                <Link
                  to="//Themesbrand.in"
                  target="_blank"
                  rel="noreferrer"
                  className="rounded"
                >
                  <i className="uil uil-globe align-middle" title="website"></i>
                </Link>
              </li>{" "}
            </ul>
          </div> */}
        {/* </div> */}
      </Container>
    </React.Fragment>
  );
};

SignupSidebar.propTypes = {
  onClose: PropTypes.func,
};

export default SignupSidebar;
