import React, { useEffect,useState } from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";
import { analytics } from "../../config/technologies/analytics";
import { cloud_tech } from "../../config/technologies/cloud_tech";
import { databases } from "../../config/technologies/databases";
import { devops } from "../../config/technologies/devops";
import { java_technologies } from "../../config/technologies/java_technologies";
import { ecommerce } from "../../config/technologies/ecommerce";
import { machine_learning } from "../../config/technologies/machine_learning";
import { mobile_tech } from "../../config/technologies/mobile_tech";
import { programming_languages } from "../../config/technologies/programming_languages";
import { salesforce } from "../../config/technologies/salesforce";
import { sap } from "../../config/technologies/sap";
import { web_technologies } from "../../config/technologies/web_technologies";

import { jobs_icon } from "../../config/jobs/jobs_icon";

// import xyz from '../../../public/assests/icons/img/4075935_alarm_alert_report_warning_icon.svg';
import alarmpng from '../../assets/icons/img/4075935_alarm_alert_report_warning_icon.svg';


const bind_all_skills = [];
const allSkills = cloud_tech
.concat(...databases)
.concat(...machine_learning)
.concat(...programming_languages)
.concat(...java_technologies)
.concat(...web_technologies)
.concat(...mobile_tech)
.concat(...analytics)
.concat(...devops)
.concat(...ecommerce)
.concat(...salesforce)
.concat(...sap);

var style = {
  height: 100
}



const FeaturedJobBox = ({feacturedjobsdata, jobs }) => {

  allSkills.forEach((skill) => {
    const isDuplicate = bind_all_skills.some((existingSkill) => existingSkill.title === skill.title);
    if (!isDuplicate) {
      bind_all_skills.push(skill);
    }
   });

   const GetIcon = (title) => {
    let filtered_skill = jobs_icon.filter((_sk) => _sk.title === title);
    if (filtered_skill && filtered_skill.length > 0 && filtered_skill[0].icon && filtered_skill[0].icon.length > 0) {
     return filtered_skill[0].icon;
    } else {
     return 'assets/icons/img/4075935_alarm_alert_report_warning_icon.svg';
    }
  }

  return (
    <>
      {/* {jobs.slice(0,12).map((job, key) => (
        <Col lg="4" md="6" xs="12" key={key} className="mt-4 pt-2 " >
          <Link to={{
              pathname: `/job/job-detail/${job.guid}`, // Assuming your route includes the job GUID
              state: { job }, // Pass the job object as state
            }} className="text-dark">
            <div className="d-flex key-feature align-items-center p-3 rounded shadow" style={style}>
              <img src={GetIcon(job.skill_requirements[0])} className="avatar avatar-ex-sm " alt="" />
              <div className="flex-1 ms-3">
                <h4 className="title mb-0">{job.title}</h4>
                <p className="mb-0 text-muted">
                   {job.location}
                </p>
              </div>
            </div>
          </Link>
        </Col>
      ))} */}

<React.Fragment>
        {feacturedjobsdata.map((job, key) => (
          <Col lg="4" md="6" xs="12" key={key} className="mt-4 pt-2">
            <Link to="#" className="text-dark">
              <div className="d-flex key-feature align-items-center p-3 rounded shadow">
                <img src={job.image} className="avatar avatar-ex-sm" alt="" />
                <div className="flex-1 ms-3">
                  <h4 className="title mb-0 text-dark">{job.title}</h4>
                  <p className="text-muted mb-0">
                    {job.city}, {job.country}
                  </p>
                </div>
              </div>
            </Link>
          </Col>
        ))}
      </React.Fragment>
    </>
  );
};

export default FeaturedJobBox;
