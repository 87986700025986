import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Pagination from "../../components/Shared/Pagination";
import { Col, Row } from "reactstrap";
import { Helmet } from "react-helmet";
import { jobs_icon } from "../../config/jobs/jobs_icon";
import { time_since } from "../../helpers/date";
import ReportIcon from "@mui/icons-material/Report";
import axios from "axios";

import { api_get } from "../../services/service";
import { ep } from "../../config/dev";

const JobList2 = ({
  // searchValue,
  onBigSearchChange,
  datePosted,
  oExperience,
  localSearchValue,
  onCategoriesChange,
  onMultiSelectChange,
}) => {
  const [jobList, setJobList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  // const [postedDate, setPostedDate] = useState("");
  const location = useLocation();
  const selectedCategory = location.state
    ? location.state.category.job_function
    : null;

  // console.log(selectedCategory);
  // console.log(onCategoriesChange);
  if (selectedCategory) {
    onCategoriesChange = selectedCategory;
  }
  // console.log(datePosted);
  // console.log(localSearchValue);
  // console.log(onBigSearchChange);
  // console.log(`multiselect data:${onMultiSelectChange}`);

  // console.log(currentPage);

  const jobsPerPage = 10;

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleDropdownChange = (event) => {
    const selectedValue = event.target.value;
    console.log('selectedValue : ',selectedValue);
    setSelectedOption(selectedValue);
  };

  useEffect(() => {
    const queryParams = {
      offset: currentPage * 10 - 10,
      limit: jobsPerPage,
      job_type: selectedOption === "All Jobs" ? "" : selectedOption,
      date_posted:
        datePosted !== null && datePosted !== undefined ? datePosted : "",
      overall_experience: oExperience === "fresher" ? '' : oExperience,
      search_job: localSearchValue,
      search_keyword: onBigSearchChange,
      categories: onCategoriesChange === "All Jobs" ? "" : onCategoriesChange,
      job_type_multi_select: onMultiSelectChange,
    };

    // Convert the query parameters to a string
    const queryString = Object.entries(queryParams)
      .filter(([key, value]) => value !== undefined && value !== null)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    console.log('queryString',queryString);
    // Update the URL with the query string
    
    // const apiUrl =
    //   ep.crawler +
    //   "/v1/joblisting/type/prequalified" +
    //   (queryString ? `?${queryString}` : "");

    const apiUrl =
      ep.crawler +
      "/v1/jobs/type/prequalified"+ (queryString ? `?${queryString}` : "");
    api_get(
      null,
      // ep.crawler + "/v1/joblisting/type/prequalified",
      apiUrl,
      (listerror, jobListResponse) => {
        if (!listerror) {
          console.log(jobListResponse);
          setJobList(jobListResponse.joblistings);
          setTotalPages(jobListResponse.total_data);
          // setJobList(jobListResponse.data.data.joblistings);
        }
      }
    );
    // const fetchJobList = async () => {
    //   // console.log("onCategoriesChange", datePosted);
    //   const response = await axios.get(
    //     "https://l08nloaza1.execute-api.ap-south-1.amazonaws.com/latest/v1/joblisting/type/prequalified",
    //     {
    //       params: {
    //         offset: currentPage * 10 - 10,
    //         limit: jobsPerPage,
    //         job_type: selectedOption === "All Jobs" ? "" : selectedOption,
    //         date_posted:
    //           datePosted !== null && datePosted !== undefined ? datePosted : "",
    //         overall_experience: oExperience === "fresher" ? 1 : oExperience,
    //         search_job: localSearchValue,
    //         search_keyword: onBigSearchChange,
    //         categories:
    //           onCategoriesChange === "All Jobs" ? "" : onCategoriesChange,
    //         job_type_multi_select: onMultiSelectChange,
    //       },
    //       headers: {
    //         service_ref: "02d45aaa",
    //       },
    //     }
    //   );
    //   setJobList(response.data.data.joblistings);
    //   // console.log("response", response.data);
    //   setTotalPages(response.data.data.total_data);
    // };
    // fetchJobList();
  }, [
    currentPage,
    selectedOption,
    datePosted,
    localSearchValue,
    onBigSearchChange,
    onCategoriesChange,
    oExperience,
    onMultiSelectChange,
  ]);

  useEffect(() => {
    // console.log(jobList);
  }, [jobList]);

  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Col
        lg={8}
        md={6}
        xs={12}
        style={{ paddingLeft: "20px", paddingRight: "20px" }}
      >
        <Row className="align-items-center">
          <Col lg={9} md={8}>
            <div className="section-title">
              <span className="text-muted me-3">
                Showing {indexOfFirstJob + 1} - {indexOfLastJob} out of{" "}
                {totalPages}
              </span>
            </div>
          </Col>

          <Col lg={3} md={4} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div className="form custom-form shadow-md">
              <select
                className="form-select form-control"
                aria-label="Default select example"
                id="Sortbylist-job"
                onChange={handleDropdownChange}
              >
                <option defaultValue>All Jobs</option>
                <option value='Full-time'>Full Time</option>
                <option>Part Time</option>
                <option>Remote</option>
                <option>Work From Home</option>
              </select>
            </div>
          </Col>
        </Row>

        <Row>
          {jobList.map((job, key) => (
            <Col xs={12} className="mt-4 pt-2" key={key}>
              <div className="job-box d-md-flex align-items-center border-0 shadow-md rounded p-4 position-relative">
                {job.source_thumbnail_url ? (
                  <img
                    src={job.source_thumbnail_url}
                    className="avatar avatar-md-sm"
                    alt=""
                  />
                ) : (
                  <img
                    src="https://tiq-public-347545.s3.ap-south-1.amazonaws.com/assets/img/tiq-logo-square.png"
                    className="avatar avatar-md-sm"
                    alt=""
                  />
                )}
                <div className="ms-md-4 mt-4 mt-sm-0">
                  <Link
                    to={{
                      pathname: `/jobs/${job.slug}`,
                      state: { job },
                    }}
                    className="text-dark h5"
                  >
                    {job.title}
                  </Link>

                  <ul className="list-unstyled mb-0 mt-2">
                    <li className="d-lg-inline text-muted h6 mb-0 me-lg-2">
                      <i className="uil uil-map-marker text-primary"></i>{" "}
                      <Link to="#" className="text-dark">
                        {job.location}
                      </Link>
                    </li>{" "}
                    <li className="d-lg-inline text-muted h6 mb-0 me-lg-2">
                      <i className="uil uil-clock text-primary"></i>{" "}
                      {time_since(job.list_date)} Ago
                    </li>{" "}
                  </ul>
                </div>
              </div>
            </Col>
          ))}
        </Row>
        <Pagination
          itemsPerPage={jobsPerPage}
          totalItems={totalPages}
          currentPage={currentPage}
          paginate={paginate}
        />
      </Col>
    </>
  );
};

export default JobList2;
