import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ep, common } from "../../config/dev";
import { Col } from "reactstrap";
import ReportIcon from "@mui/icons-material/Report";

const FeaturedJobBox2 = ({ jobsdata }) => {
  return (
    <>
      {/* Render data from API */}
      {jobsdata.map((job, key) => (
        <Col lg="4" md="6" xs="12" key={key} className="mt-4 pt-2">
          <Link
            to={{ pathname: `/jobs/${job.slug}`, state: { job } }}
            className="text-dark"
          >
            <div
              className="d-flex key-feature align-items-center p-3 rounded shadow"
              style={{ height: "150px", border: "0.2px solid #dbd8d0" }}
            >
              {job.source_thumbnail_url ? (
                <img
                  src={job.source_thumbnail_url}
                  //   className="avatar avatar-ex-sm"
                  style={{ width: "25px", height: "25px" }}
                  alt=""
                />
              ) : (
                <img
                  src="https://tiq-public-347545.s3.ap-south-1.amazonaws.com/assets/img/tiq-logo-square.png"
                  //   className="avatar avatar-ex-sm"
                  style={{ width: "25px", height: "25px" }}
                  alt=""
                />
              )}
              <div className="flex-1 ms-3">
                <h4 className="title mb-0 text-dark">{job.title}</h4>
                <p className="text-muted mb-0">{job.location}</p>
              </div>
            </div>
          </Link>
        </Col>
      ))}
    </>
  );
};

export default FeaturedJobBox2;
