import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import axios from "axios";
import FeaturedJobBox2 from "./FeaturedJobBox2";
import "./css/demo.css";

const FeaturedJob2 = () => {
  const [apiData, setApiData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://l08nloaza1.execute-api.ap-south-1.amazonaws.com/latest/v1/joblisting/type/featured",
          {
            params: { limit: "12" },
            headers: {
              service_ref: "02d45aaa",
            },
          }
        );

        setApiData(response.data.data.joblistings);
        // console.log(apiData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <section
        className="Featuredjobsbg border-top"
        style={{
          paddingLeft: "10px",
          paddingRight: "10px",
        }}
      >
        <Container className=" text-white">
          <Row className="justify-content-center">
            <Col className="col-8 text-center py-3 pb-1 pt-4">
              <h2 className="text-dark">Featured Jobs </h2>
              <p className="text-dark">
                Our platform ensures that candidates face a seamless and
                positive journey, enabling them to showcase their talents
                confidently using our AI enabled IT Assessment test.
              </p>
            </Col>
          </Row>
          <Row style={{ paddingLeft: "15px", paddingRight: "10px" }}>
            {/* Featured Job Box */}
            <FeaturedJobBox2 jobsdata={apiData} />

            <Col xs="12" className="text-center mt-4 pt-2 mb-3">
              <Link to="jobs" className="btn btn-primary">
                See All Jobs <i className="uil uil-angle-right-b"></i>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default FeaturedJob2;
