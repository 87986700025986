import React from 'react'
import { Row,Col,Container } from 'reactstrap'
import { Link } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react'

import error_img from "../../assets/images/404.svg";


const PageNotFound = () => {
  return (
    <>
    <div className="back-to-home rounded d-none d-sm-block pt-5">
          <Link to="/" className="btn btn-icon btn-soft-primary">
            <i>
              <FeatherIcon icon="arrow-left" className="icons" />
            </i>
          </Link>
        </div>

        <section className="bg-home d-flex align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col lg={8} md={12} className="text-center">
                <img src={error_img} className="img-fluid" alt="" />
                <div className="text-uppercase mt-4 display-3">Oh ! no</div>
                <div className="text-capitalize text-dark mb-4 error-page">
                  Page Not Found
                </div>
                <p className="text-muted para-desc mx-auto">
                  Start working with{" "}
                  <span className="text-primary fw-bold">
                    TalentIQ
                  </span>{" "}
                  that can provide everything you need to generate awareness,
                  drive traffic, connect.
                </p>
              </Col>
            </Row>

            <Row>
              <Col md="12" className="text-center">
                {/* <Link
                  to="#"
                  onClick={this.props.history.goBack}
                  className="btn btn-outline-primary mt-4"
                >
                  Go Back
                </Link>{" "} */}
                <a href="/" className="btn btn-primary mt-4 ms-2">
                  Go To Home
                </a>
              </Col>
            </Row>
          </Container>
        </section>
    </>
  )
}

export default PageNotFound