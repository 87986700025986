import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Container, Row, Col } from "reactstrap";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import SectionTitle from "../../components/Shared/SectionTile";

import image1 from "../../assets/images/client/01.jpg";
import image2 from "../../assets/images/client/02.jpg";
import image3 from "../../assets/images/client/03.jpg";
import image4 from "../../assets/images/client/04.jpg";
import image5 from "../../assets/images/client/05.jpg";
import image6 from "../../assets/images/client/06.jpg";

function Testimonial() {
  return (
    <section
      class="Featuredjobsbg section bg-light"
      style={{
        background: "../../assets/images/shapes/shape2.png",
        paddingTop: "25px",
        paddingBottom: "45px",
      }}
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-8">
            <div class="section-title pb-1 text-center">
              <SectionTitle
                className="col-lg-4 text-center"
                title="Redefining Candidate Experience"
                desc="At TalentIQ, candidate experience is paramount. Our platform ensures that candidates face a seamless and positive journey, enabling them to showcase their talents confidently using our AI enabled skills assessment test"
              />
              {/* <h4 class="title mb-4">Redefining Candidate Experience</h4>
              <p class="text-muted mx-auto mb-0 text-center" align="justify">
                At TalentIQ, candidate experience is paramount. Our platform
                ensures that candidates face a seamless and positive journey,
                enabling them to showcase their talents confidently using our AI
                enabled skills assessment test.
              </p> */}
            </div>
          </div>
        </div>

        <div class="row justify-content-center active">
          <div class="mt-4">
            <div
              class="tiny-three-item d-flex"
              style={{ paddingLeft: "10px", paddingRight: "10px" }}
            >
              <Swiper
                slidesPerView={1}
                breakpoints={{
                  576: {
                    slidesPerView: 2,
                  },
                  1020: {
                    slidesPerView: 3,
                  },
                }}
                //navigation={true}
                // pagination={true}
                className="mySwiper"
                modules={[Autoplay, Pagination, Navigation]}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                // speed={1000}
                loop={true}
                // pagination={{
                //   clickable: true,
                // }}
              >
                <SwiperSlide>
                  <div class="tiny-slide ">
                    <div class="d-flex client-testi m-1">
                      {" "}
                      <img
                        src={image1}
                        class="avatar avatar-small client-image rounded shadow"
                        alt=""
                      />
                      <div class="card flex-1 content p-3 shadow rounded position-relative">
                        <ul class="list-unstyled mb-0">
                          <li class="list-inline-item">
                            <i class="mdi mdi-star text-warning"></i>
                          </li>
                          <li class="list-inline-item">
                            <i class="mdi mdi-star text-warning"></i>
                          </li>
                          <li class="list-inline-item">
                            <i class="mdi mdi-star text-warning"></i>
                          </li>
                          <li class="list-inline-item">
                            <i class="mdi mdi-star text-warning"></i>
                          </li>
                          <li class="list-inline-item">
                            <i class="mdi mdi-star text-warning"></i>
                          </li>
                        </ul>
                        <p class="text-muted mt-2">
                          "TalentIQ has transformed my job search journey.The
                          assessment process allowed me to truly showcase my
                          skills and potential. It's not just another test it's
                          an opportunity to stand out and shine"
                        </p>
                        <br />
                        <h6 class="text-primary">
                          - Alex Johnson <br />
                          <small class="text-muted">Frontend Engineer</small>
                        </h6>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="tiny-slide ">
                    <div class="d-flex client-testi m-1">
                      {" "}
                      <img
                        src={image2}
                        class="avatar avatar-small client-image rounded shadow"
                        alt=""
                      />
                      <div class="card flex-1 content p-3 shadow rounded position-relative">
                        <ul class="list-unstyled mb-0">
                          <li class="list-inline-item">
                            <i class="mdi mdi-star text-warning"></i>
                          </li>
                          <li class="list-inline-item">
                            <i class="mdi mdi-star text-warning"></i>
                          </li>
                          <li class="list-inline-item">
                            <i class="mdi mdi-star text-warning"></i>
                          </li>
                          <li class="list-inline-item">
                            <i class="mdi mdi-star text-warning"></i>
                          </li>
                          <li class="list-inline-item">
                            <i class="mdi mdi-star-half text-warning"></i>
                          </li>
                        </ul>
                        <p class="text-muted mt-2" align="left">
                          "As a candidate, I always felt that my resume didn't
                          fully represent my capabilities. With TalentIQ, my
                          strengths were highlighted through a holistic
                          assessment.The process was seamless, insightful, and
                          gave me the confidence to approach interviews
                          differently."
                        </p>
                        <br />
                        <h6 class="text-primary">
                          - Sarah Patel <br />
                          <small class="text-muted">Full Stack Developer</small>
                        </h6>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="tiny-slide ">
                    <div class="d-flex client-testi m-1">
                      {" "}
                      <img
                        src={image3}
                        class="avatar avatar-small client-image rounded shadow"
                        alt=""
                      />
                      <div class="card flex-1 content p-3 shadow rounded position-relative">
                        <ul class="list-unstyled mb-0">
                          <li class="list-inline-item">
                            <i class="mdi mdi-star text-warning"></i>
                          </li>
                          <li class="list-inline-item">
                            <i class="mdi mdi-star text-warning"></i>
                          </li>
                          <li class="list-inline-item">
                            <i class="mdi mdi-star text-warning"></i>
                          </li>
                          <li class="list-inline-item">
                            <i class="mdi mdi-star text-warning"></i>
                          </li>
                          <li class="list-inline-item">
                            <i class="mdi mdi-star text-warning"></i>
                          </li>
                        </ul>
                        <p class="text-muted mt-2" align="left">
                          "TalentIQ doesn't just focus on technical skills; it
                          captures the essence of who you are as a candidate.
                          The video-based assessment gave me a chance to
                          demonstrate my personality and cultural fit, setting
                          me apart from the competition."
                        </p>
                        <br />
                        <h6 class="text-primary">
                          - Kevin Chen <br />
                          <small class="text-muted">Software Engineer</small>
                        </h6>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="tiny-slide ">
                    <div class="d-flex client-testi m-1">
                      {" "}
                      <img
                        src={image4}
                        class="avatar avatar-small client-image rounded shadow"
                        alt=""
                      />
                      <div class="card flex-1 content p-3 shadow rounded position-relative">
                        <ul class="list-unstyled mb-0">
                          <li class="list-inline-item">
                            <i class="mdi mdi-star text-warning"></i>
                          </li>
                          <li class="list-inline-item">
                            <i class="mdi mdi-star text-warning"></i>
                          </li>
                          <li class="list-inline-item">
                            <i class="mdi mdi-star text-warning"></i>
                          </li>
                          <li class="list-inline-item">
                            <i class="mdi mdi-star text-warning"></i>
                          </li>
                          <li class="list-inline-item">
                            <i class="mdi mdi-star text-warning"></i>
                          </li>
                        </ul>
                        <p class="text-muted mt-2">
                          "Upload your resume, take the assessment, and let your
                          journey to success begin! TalentIQ's unique approach
                          has made a significant impact on my career trajectory.
                          It's a game-changer for candidates who want to be
                          noticed for their true potential."
                        </p>
                        <br />
                        <h6 class="text-primary">
                          - Emily Rodriguez <br />
                          <small class="text-muted">UI/UX Designer</small>
                        </h6>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="tiny-slide ">
                    <div class="d-flex client-testi m-1">
                      {" "}
                      <img
                        src={image5}
                        class="avatar avatar-small client-image rounded shadow"
                        alt=""
                      />
                      <div class="card flex-1 content p-3 shadow rounded position-relative">
                        <ul class="list-unstyled mb-0">
                          <li class="list-inline-item">
                            <i class="mdi mdi-star text-warning"></i>
                          </li>
                          <li class="list-inline-item">
                            <i class="mdi mdi-star text-warning"></i>
                          </li>
                          <li class="list-inline-item">
                            <i class="mdi mdi-star text-warning"></i>
                          </li>
                          <li class="list-inline-item">
                            <i class="mdi mdi-star text-warning"></i>
                          </li>
                          <li class="list-inline-item">
                            <i class="mdi mdi-star text-warning"></i>
                          </li>
                        </ul>
                        <p class="text-muted mt-2">
                          "Gone are the days of traditional interviews that
                          barely scratch the surface. TalentIQ's hands-on
                          challenges and comprehensive assessment brought out my
                          best. Being evaluated on both theoretical and
                          practical aspects made all the difference. "
                        </p>
                        <br />
                        <h6 class="text-primary">
                          - Deepak Kumar <br />
                          <small class="text-muted">Backend Developer</small>
                        </h6>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="tiny-slide ">
                    <div class="d-flex client-testi m-1">
                      {" "}
                      <img
                        src={image6}
                        class="avatar avatar-small client-image rounded shadow"
                        alt=""
                      />
                      <div class="card flex-1 content p-3 shadow rounded position-relative">
                        <ul class="list-unstyled mb-0">
                          <li class="list-inline-item">
                            <i class="mdi mdi-star text-warning"></i>
                          </li>
                          <li class="list-inline-item">
                            <i class="mdi mdi-star text-warning"></i>
                          </li>
                          <li class="list-inline-item">
                            <i class="mdi mdi-star text-warning"></i>
                          </li>
                          <li class="list-inline-item">
                            <i class="mdi mdi-star text-warning"></i>
                          </li>
                          <li class="list-inline-item">
                            <i class="mdi mdi-star text-warning"></i>
                          </li>
                        </ul>
                        <p class="text-muted mt-2">
                          "I was tired of being just another resume in a pile.
                          TalentIQ's assessment process gave me a voice and a
                          platform to showcase my capabilities. It's more than a
                          test; it's a chance to tell your story in a way that
                          truly matters."
                        </p>
                        <br />
                        <h6 class="text-primary">
                          - Jessica Williams <br />
                          <small class="text-muted">Mobile App Developer</small>
                        </h6>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonial;
